<template>
  <div v-show="isShow" class="dash_admin_box">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <!-- 검색 Wrap -->
      <div class="option_wrap">
        <div class="search_filter">
          <form>
            <select v-model="listParam.type" class="search_option">
              <option value="shipname">선박명</option>
            </select>

            <div class="option_date w100">
              <input
                v-model="listParam.searchText"
                type="text"
                placeholder="검색어 입력"
                @keyup.enter="onClickSearch"
              />
            </div>
            <div class="search_button">
              <button type="button" @click="onClickSearch">검색</button>
            </div>
          </form>
        </div>
        <div class="right_area">
          <div class="download_button">
            <button type="button" @click="regist">유류 공급서 등록</button>
          </div>
        </div>
      </div>

      <!-- 검색 Wrap //-->

      <div class="dash_admin_box_table">
        <table>
          <thead>
            <tr>
              <th width="40">선박명</th>
              <th width="50">유종</th>
              <th width="40">주유량</th>
              <th width="40">단가</th>
              <th width="50">유류대금</th>
              <th width="50">수수료</th>
              <th width="30">용역비</th>
              <th width="30">합계</th>
              <th width="30">공급일</th>
              <th width="30">수정</th>
              <th width="30">삭제</th>
            </tr>
          </thead>

          <tbody>
            <tr v-if="pageParam.total === 0">
              <td colspan="10">검색 결과가 없습니다.</td>
            </tr>
            <template v-if="pageParam.total !== 0">
              <tr v-for="(item, index) in pageParam.content" :key="index">
                <td
                  style="cursor: pointer; color: aqua"
                  @click="viewDetail(item)"
                >
                  {{ item.shipName }}
                </td>
                <td>
                  {{ item.oilType }}
                </td>
                <td>
                  {{ item.oilAmount }}
                </td>

                <td>
                  {{ item.unitPrice }}
                </td>
                <td>
                  {{ item.oilPrice }}
                </td>
                <td>{{ item.extraPrice }}</td>
                <td>{{ item.servicePrice }}</td>
                <td>{{ item.totalPrice }}</td>
                <td>{{ item.supplyDay }}</td>
                <td>
                  <button type="button" class="btn_mody" @click="update(item)">
                    수정
                  </button>
                </td>
                <td>
                  <button type="button" class="btn_add" @click="remove(item)">
                    삭제
                  </button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <pagination
        :page-param="pageParam"
        :list-cnt="listParam.size"
        @pageCallback="pageCallback"
      />
    </div>

    <popup-create-oil-management
      ref="popupCreateOilManagement"
      @confirmCallback="confirmCallback"
    />
    <popup-update-oil-management ref="popupUpdateOilManagement" />
    <popup-detail-oil-management ref="popupDetailOilManagement" />

    <confirm ref="confirm" @deleteCallback="deleteCallback" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PopupCreateOilManagement from "./PopupCreateOilManagement.vue";
import PopupUpdateOilManagement from "./PopupUpdateOilManagement.vue";
import PopupDetailOilManagement from "./PopupDetailOilManagement.vue";
import Pagination from "../Pagination.vue";
import OilDocumentApi from "../../api/osm/OilDocumentApi";
import Confirm from "./Confirm.vue";

export default {
  name: "PopupEquipmentList",
  components: {
    Pagination,
    Confirm,
    PopupCreateOilManagement,
    PopupUpdateOilManagement,
    PopupDetailOilManagement,
  },
  props: {
    siteId: {},
  },
  data: () => {
    return {
      companyList: [],
      pageParam: { content: [] },
      listParam: {
        page: 1,
        size: 10,
        startDate: "",
        endDate: "",
        type: "shipname",
      },
      isShow: false,
      allSelected: false,
      fileIds: [],
      accessSiteList: [],
      selectSiteId: [],
      confirmParam: {
        title: "",
        msg: "",
        isShow: false,
      },
      noticeId: null,
      paramSiteId: null,
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    async init() {
      // this.getSiteList();
      this.fetchHistoryFile();
    },
    onClickSearch() {
      this.listParam.page = 1;
      this.fetchHistoryFile();
    },
    async fetchHistoryFile() {
      console.log(this.listParam);
      const result = await OilDocumentApi.oilDocList(this.listParam);
      result.size = this.listParam.size;
      this.pageParam = result;
      console.log(result);
    },

    pageCallback(page) {
      this.listParam.page = page;
      this.fetchHistoryFile();
    },
    contentSubStr(str) {
      if (str) {
        str = str.replace(/<[^>]*>?/g, "");
        if (str.length > 10) {
          str = str.substr(0, 8) + "...";
        }
      }
      return str;
    },
    imgFaults(item) {
      var url = "/img/common/";
      if (item) url += "bullet_red_icon.png";
      else url += "bullet_green_icon.png";

      return url;
      //bullet_green_icon.png
      //bullet_red_icon.png
    },
    getNumber(index) {
      this.pageParam.total -
        ((this.listParam.page - 1) * this.listParam.size + index);
    },

    sliceTime(time) {
      if (time) return time.slice(0, 5);
      return "";
    },
    enter2Br(str) {
      if (!str) return "";
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    open() {
      console.log("open");
      this.init();
      this.isShow = true;
    },
    close() {
      this.listParam.title = null;
      this.isShow = false;
    },
    selectAll() {
      this.fileIds = [];
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        this.pageParam.content.forEach((element) => {
          this.fileIds.push(element.fileId);
        });
      }
    },
    select() {
      this.allSelected = false;
    },
    async onClickDownload() {
      const fileArray = [];
      if (this.fileIds.length === 0) {
        this.$toast.info("파일을 선택하세요.");
        return;
      }

      this.fileIds.forEach((fileId) => {
        this.pageParam.content.forEach((item) => {
          if (fileId === item.fileId) {
            const file = {};
            file.fileId = item.fileId;
            file.fileNo = item.fileNo;
            fileArray.push(file);
          }
        });
      });

      const fileName = "시험계획서";
    },
    changeSite() {
      this.fetchHistoryFile();
    },
    regist() {
      this.$refs.popupCreateOilManagement.open();
    },
    async confirmCallback(param) {
      console.log(param);
      // await OilDocumentApi.deleteOilDoc(param.id);
      this.fetchHistoryFile();
      // this.init();
    },
    async viewDetail(param) {
      this.$refs.popupDetailOilManagement.open(param);
    },
    async update(param) {
      this.$refs.popupCreateOilManagement.open(param);
    },
    remove(param) {
      param.msg = "삭제 하시겠습니까?";
      this.$refs.confirm.open(param);
    },
    async deleteCallback(param) {
      await OilDocumentApi.deleteOilDoc(param.id);
      this.fetchHistoryFile();
    },
  },
};
</script>
<style scoped></style>
