export const distinctById = targetArray => {
    if (targetArray.length > 1) {
        const filterMap = new Map();
        const unique = targetArray.filter(el => {
            const item = filterMap.get(el.id);
            if (item) {
                return false;
            }
            filterMap.set(el.id, el);
            return true;
        });
        return unique;
    }
    return targetArray;
};

export const getMemberStatus = member => {
    if (member) {
        if (member.checkOutTime) {
            return 'out';
        } else if (member.checkInTime) {
            return 'in';
        }
        return '';
    }
};

export const getBatteryCls = value => {
    if (value >= 75) {
        return 'high';
    } else if (value >= 40) {
        return 'mid';
    }
    return 'low';
};
