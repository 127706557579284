import { format, parseISO } from 'date-fns';

const formatWithLocale = (date, formatStr) => {
    if (typeof date === 'undefined') {
        return '';
    }
    if (!date) return '';
    return format(parseISO(date), formatStr);
};

export const getToday = () => {
    var date = new Date();
    var year = date.getFullYear();
    var month = ('0' + (1 + date.getMonth())).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);

    return year + '-' + month + '-' + day;
};

export const dateFormat = timestamp => {
    const date = new Date(timestamp);
    var year = date.getFullYear();
    var month = ('0' + (1 + date.getMonth())).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    var hours = ('0' + date.getHours()).slice(-2);
    var min = ('0' + date.getMinutes()).slice(-2);
    var sec = ('0' + date.getSeconds()).slice(-2);

    return year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec;
};

export const toDayFormatAddDay = day => {
    const objDate = new Date();
    objDate.setDate(objDate.getDate() + day);
    return formatWithLocale(objDate.toISOString(), 'yyyy-MM-dd');
};

export const toDayFormatAddMonth = day => {
    const objDate = new Date();
    objDate.setMonth(objDate.getMonth() + day);
    return formatWithLocale(objDate.toISOString(), 'yyyy-MM-dd');
};
export const addMonth = (date, addMonth) => {
    date.setMonth(date.getMonth() + addMonth);
    return formatWithLocale(date.toISOString(), 'yyyy-MM');
};

export const getDayOfWeek = day => {
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const dayOfWeek = week[new Date(day).getDay()];
    return dayOfWeek;
};

export const formatCommute = timestamp => {
    if (!timestamp) return '-';
    return formatWithLocale(timestamp, 'HH:mm:ss'); //
};

export const formatTime = timestamp => formatWithLocale(timestamp, 'YYYY.MM.DD'); //
export const shortTime = timestamp => formatWithLocale(timestamp, 'MM.DD'); //

export const formatDate = timestamp => {
    if (timestamp) return formatWithLocale(timestamp, 'yy.MM.dd');
};

export const formatShortCommute = timestamp => {
    if (!timestamp) return '-';
    return formatWithLocale(timestamp, 'HH:mm'); //
};

export const dayFormat = timestamp => {
    if (timestamp) return formatWithLocale(timestamp.toISOString(), 'yyyy-MM-dd');
};

export const toMonthFormat = () => {
    const objDate = new Date();
    return formatWithLocale(objDate.toISOString(), 'yyyy.MM');
};
