<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    class="popup modify_site"
  >
    <div class="popup_header">
      <div class="header_title">유류 공급서 등록</div>
      <button @click="close" class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" />
      </button>
    </div>
    <div class="popup_content">
      <div class="form_wrap">
        <div class="form_item">
          <div class="form_write">
            <p class="title">공급일</p>
            <p class="input">
              <date-picker
                v-model="param.supplyDay"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
              />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">선박명</p>
            <p class="input">
              <select v-model="param.shipName">
                <option
                  v-for="(item, index) in equipList"
                  :key="index"
                  :value="item.name"
                >
                  {{ item.name }}
                </option>
              </select>
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">첨부파일</p>

            <p v-if="param.fileId" class="input">
              <a
                v-for="(file, index) in fileList"
                :key="index"
                @click="onClickDownload(file)"
                style="cursor: pointer"
              >
                <span
                  class="files"
                  @click="downloadFile(file.id)"
                  style="color: white"
                  >{{ file.ogName }}</span
                >
              </a>
              <a v-show="!param.isReadOnly" @click="removeFile">
                <span class="file-del">삭제</span>
              </a>
            </p>
            <p v-if="param.fileId === null" class="filebox">
              <input
                id="actionDocFile"
                ref="actionDocFile"
                type="file"
                :accept="DOC_ALLOW_FILE"
                class="blind"
                @change="doActionDocFileAttach"
              />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">유종</p>
            <p class="input">
              <input v-model="param.oilType" type="text" />
            </p>
            <p class="title" style="padding-left: 20px">주유량(L)</p>
            <p class="input">
              <input v-model="param.oilAmount" type="text" />
            </p>
          </div>
        </div>

        <div class="form_item">
          <div class="form_write">
            <p class="title">단가(원)</p>
            <p class="input">
              <input v-model="param.unitPrice" type="text" />
            </p>
            <p class="title" style="padding-left: 20px; width: 250px">
              유류대금(원)
            </p>
            <p class="input">
              <input v-model="param.oilPrice" type="number" />
            </p>
          </div>
        </div>

        <div class="form_item">
          <div class="form_write">
            <p class="title">수수료(원)</p>
            <p class="input">
              <input v-model="param.extraPrice" type="number" />
            </p>
            <p class="title" style="padding-left: 20px">용역비(원)</p>
            <p class="input">
              <input v-model="param.servicePrice" type="number" />
            </p>
          </div>
        </div>

        <div class="form_item">
          <div class="form_write">
            <p class="title" style="width: 80px">합계(원)</p>
            <p class="input">
              <input v-model="param.totalPrice" type="number" />
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <button type="button" class="cancel" @click="close">취소</button>
        <button type="button" class="comfirm" @click="save">확인</button>
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import { mapGetters } from "vuex";
import EquipmentApi from "../../api/osm/EquipmentApi";
import OilDocumentApi from "../../api/osm/OilDocumentApi";
import FileApi from "../../api/coco/FileApi";
import FileHelper from "../../helper/FileHelper";

export default {
  name: "PopupCreateEquipment",
  components: { DatePicker },
  props: {},
  data: () => {
    return {
      isShow: false,
      param: {
        fileId: null,
      },
      day: [],
      equipList: [],
      fileList: [],
      DOC_ALLOW_FILE: FileHelper.allowFileExt(),
    };
  },
  computed: { ...mapGetters(["getLang"]) },
  watch: {},
  created() {},
  async mounted() {
    const result = await EquipmentApi.equipList();
    result.content.forEach((element) => {
      this.equipList.push(element);
    });
  },
  beforeDestroy() {},
  methods: {
    async open(param) {
      if (param) {
        this.param = { ...param };
        if (param.fileId) {
          this.fileList = await FileApi.list(this.param.fileId);
        }
      }

      this.isShow = true;
    },
    close() {
      this.day = [];
      this.param = { fileId: null };
      this.param.fileId = null;
      this.isShow = false;
    },
    async save() {
      this.equipList.forEach((item) => {
        if (item.name === this.param.shipName) this.param.shipId = item.id;
      });
      if (!this.param.supplyDay) {
        this.$toast.error("공급일을 입력해주세요.");
        return;
      }
      if (!this.param.shipName) {
        this.$toast.error("선박명을 입력해주세요.");
        return;
      }
      if (this.param.id) {
        await OilDocumentApi.updateOilDoc(this.param.id, this.param);
      } else {
        await OilDocumentApi.saveOilDoc(this.param);
      }
      //   this.param.startDate = "";
      //   this.param.endDate = "";
      //   this.param.content = "";
      //   this.param.title = "";
      this.param = { fileId: null };
      this.param.fileId = null;
      this.isShow = false;
    },
    changePriceInput() {
      const servicePrice = parseFloat(this.param.servicePrice) || 0;
      const extraPrice = parseFloat(this.param.extraPrice) || 0;
      const oilPrice = parseFloat(this.param.oilPrice) || 0;
      this.param.totalPrice = servicePrice + extraPrice + oilPrice;
    },
    async onClickDownload(file) {
      FileApi.download(file.fileId, file.fileNo);
    },
    removeFile() {
      this.param.fileId = null;
    },
    async doActionDocFileAttach() {
      const fileObject = this.$refs.actionDocFile.files[0];

      const docFileForm = FileHelper.createFileFormData(
        fileObject,
        this.param.fileId,
        0
      );
      const fileRes = await FileApi.upload(docFileForm, "DOCUMENT");
      if (fileRes.content) {
        this.param.fileId = fileRes.content.fileId;
        this.fileList = await FileApi.list(this.param.fileId);
      }

      const ocrData = fileRes.content.ocrData;
      const newData = {};
      ocrData.forEach((data) => {
        const fieldName = data.name;
        newData[fieldName] = data.inferText;
      });
      this.param.oilType = newData["oil_type"];
      this.param.oilAmount = newData["oil_amount"].replace(/,/g, "");
      this.param.unitPrice = newData["unit_price"].replace(/,/g, "");

      this.param.oilPrice =
        Number(newData["oil_price"].replace(/,/g, "")) +
        Number(newData["oil_vat"].replace(/,/g, ""));
      this.param.extraPrice =
        Number(newData["extra_price"].replace(/,/g, "")) +
        Number(newData["extra_vat"].replace(/,/g, ""));
      this.param.servicePrice =
        Number(newData["service_addition_price"].replace(/,/g, "")) +
        Number(newData["service_basic_price"].replace(/,/g, "")) +
        Number(newData["service_vat"].replace(/,/g, ""));
      this.param.totalPrice = newData["total_price"].replace(/,/g, "");
      this.param.supplyDay = newData["supplyDay"].replace(/\//g, "-");

      const ocrEquipName = newData["equipName"];
      const equipNameList = this.equipList.map((equip) => equip.name);
      if (!equipNameList.includes(ocrEquipName)) {
        alert("선박명 읽어오지 못했습니다 직접 선택해주세요");
      } else {
        this.param.shipName = ocrEquipName;
      }
    },
  },
};
</script>
<style>
.popup .form_wrap .form_write .title {
  width: 220px;
}
.v-toast {
  z-index: 10000000 !important;
}
</style>
