<template>
  <div class="contents_btns">
    <p class="content_btn">
      <button
        :class="contentsBtnsValue.worker"
        type="button"
        data-target="type_worker"
        class="type_worker"
        @click="changeValue('worker')"
      >
        인력
      </button>
    </p>
    <p class="content_btn">
      <button
        :class="contentsBtnsValue.equip"
        type="button"
        data-target="type_equip"
        class="type_equip"
        @click="changeValue('equip')"
      >
        장비
      </button>
    </p>
    <p class="content_btn">
      <button
        :class="contentsBtnsValue.coco"
        type="button"
        data-target="type_coco"
        class="type_coco"
        @click="changeValue('coco')"
      >
        작업이슈
      </button>
    </p>
    <!-- <p class="content_btn">
            <button
                :class="contentsBtnsValue.cctv"
                type="button"
                data-target="type_cctv"
                class="type_cctv"
                @click="changeValue('cctv')"
            >
                CCTV <img src="@static/img/common/map/icon_cctv.svg" alt="" />
            </button>
        </p> -->
    <!-- <p class="content_btn">
            <button type="button" data-target="type_auto" class="type_auto">
                자동화 장비 <img src="@static/img/common/map/icon_auto.svg" alt="" />
            </button>
        </p> -->
  </div>
</template>
<script>
export default {
  name: "ContentsBtns",
  components: {},
  props: {
    contentsBtnsValue: {
      type: Object,
      default: null,
    },
  },
  data: () => {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    changeValue(type) {
      this.$emit("changeContentsBtnsValue", type);
    },
  },
};
</script>
