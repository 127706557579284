<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    style="min-width: 1100px"
    class="popup modify_site"
  >
    <div class="popup_header">
      <div class="header_title">운항 일지 등록</div>
      <button @click="close" class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" />
      </button>
    </div>
    <div class="popup_content">
      <div class="form_wrap">
        <div class="form_item">
          <div class="form_write">
            <p class="title">운항일자</p>
            <p class="input">
              <date-picker
                v-model="param.operationDay"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
              />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">선박명</p>
            <p class="input">
              <select v-model="param.equipmentName">
                <option
                  v-for="(item, index) in equipList"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">첨부파일</p>
            <p v-if="param.fileId" class="input">
              <a
                v-for="(file, index) in fileList"
                :key="index"
                @click="onClickDownload(file)"
                style="cursor: pointer"
              >
                <span
                  class="files"
                  @click="downloadFile(file.id)"
                  style="color: white"
                  >{{ file.ogName }}</span
                >
              </a>
              <a v-show="!param.isReadOnly" @click="removeFile">
                <span class="file-del">삭제</span>
              </a>
            </p>
            <p v-if="param.fileId === null" class="filebox">
              <input
                id="actionDocFile"
                ref="actionDocFile"
                type="file"
                :accept="DOC_ALLOW_FILE"
                class="blind"
                @change="doActionDocFileAttach"
              />
            </p>
          </div>
        </div>
      </div>
      <div class="dash_admin_box_table" style="height: 100%">
        <table :key="tableKey">
          <thead>
            <tr>
              <th colspan="2" width="40">운항시간</th>
              <th rowspan="2" width="50">출발지</th>
              <th rowspan="2" width="40">목적지</th>
              <th rowspan="2" width="40">비고</th>
            </tr>
            <tr>
              <th width="40">시작</th>
              <th width="40">종료</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in logList" :key="index">
              <td>
                <p class="input">
                  <input v-model="item.startTime" type="time" />
                </p>
              </td>
              <td>
                <p class="input">
                  <input v-model="item.endTime" type="time" />
                </p>
              </td>
              <td>
                <p class="input">
                  <input v-model="item.departure" type="text" />
                </p>
              </td>
              <td>
                <p class="input">
                  <input v-model="item.destination" type="text" />
                </p>
              </td>
              <td>
                <p class="input">
                  <input v-model="item.remark" type="text" />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="foot_btns">
          <button type="button" class="comfirm" @click="addList">추가</button>
        </div>
      </div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <button type="button" class="cancel" @click="close">취소</button>
        <button type="button" class="comfirm" @click="save">저장</button>
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import { mapGetters } from "vuex";
import ShipLogBookApi from "../../api/osm/ShipLogBookApi";
import EquipmentApi from "../../api/osm/EquipmentApi";
import FileApi from "../../api/coco/FileApi";
import FileHelper from "../../helper/FileHelper";

export default {
  name: "PopupCreateEquipment",
  components: { DatePicker },
  props: {},
  data: () => {
    return {
      isShow: false,
      param: {
        fileId: null,
      },
      paramChild: [],
      day: [],
      logList: [],
      equipList: [],
      tableKey: 0,
      fileList: [],
      DOC_ALLOW_FILE: FileHelper.allowFileExt(),
    };
  },
  computed: { ...mapGetters(["getLang"]) },
  watch: {},
  created() {},
  async mounted() {
    const result = await EquipmentApi.equipList();
    result.content.forEach((element) => {
      this.equipList.push(element.name);
    });
  },
  beforeDestroy() {},
  methods: {
    open() {
      this.param.fileId = null;
      this.logList = [];
      this.fileList = [];
      this.isShow = true;
    },
    close() {
      this.day = [];
      // this.param = {};
      this.param.fileId = null;
      this.fileList = [];
      this.logList = [];
      this.isShow = false;
    },
    addList() {
      const paramChild = {};
      this.logList.push(paramChild);
      this.tableKey += 1;
    },
    async save() {
      this.logList.forEach((log) => {
        log.operationDay = this.param.operationDay;
        log.equipmentName = this.param.equipmentName;
      });
      const shipLogRequest = {
        shipLogBook: this.param,
        shipLogBookChild: this.logList,
      };

      const result = await ShipLogBookApi.saveShipLog(shipLogRequest);
      this.$emit("saveCallBack");

      //   this.param.startDate = "";
      //   this.param.endDate = "";
      //   this.param.content = "";
      //   this.param.title = "";
      // this.param = {};
      // this.logList = [];
      this.isShow = false;
    },
    async onClickDownload(file) {
      FileApi.download(file.fileId, file.fileNo);
    },
    removeFile() {
      this.param.fileId = null;
    },
    async doActionDocFileAttach() {
      const fileObject = this.$refs.actionDocFile.files[0];

      const docFileForm = FileHelper.createFileFormData(
        fileObject,
        this.param.fileId,
        0
      );
      const fileRes = await FileApi.upload(docFileForm, "DOCUMENT");
      if (fileRes.content) {
        this.param.fileId = fileRes.content.fileId;
        this.fileList = await FileApi.list(this.param.fileId);
      }
      const ocrData = fileRes.content.ocrData;

      const newArray = [];

      for (let i = 2; i < ocrData.length; i += 4) {
        const group = ocrData.slice(i, i + 4); // 4개의 데이터 묶음
        const splitTime = group[0].inferText.split("~");
        const newObj = {
          startTime: splitTime[0], // 첫 번째 항목
          endTime: splitTime[1],
          departure: group[1].inferText, // 두 번째 항목
          destination: group[2].inferText, // 세 번째 항목
          remark: group[3].inferText, // 네 번째 항목
        };
        if (!(splitTime[0] === "" || splitTime[0] === undefined)) {
          newArray.push(newObj); // 새로운 배열에 추가
        }
      }
      this.logList = newArray;
      alert("업로드한 파일 내용을 확인해주세요");
    },
  },
};
</script>
<style scoped>
.popup .form_wrap .form_write .title {
  width: 220px;
}
.v-toast {
  z-index: 10000000 !important;
}
.input input[type="text"] {
  width: 100%;
  height: 35px;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  letter-spacing: -0.2px;
  border: 1px solid #282c32;
  background-color: #000000;
  font-size: 1.6rem;
  text-indent: 10px;
}
.input input[type="time"] {
  background-color: #000000;
}
/* .input input[type="time"] {
  width: 500px;
  height: 35px;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  letter-spacing: -0.2px;
  border: 1px solid #282c32;
  background-color: #000000;
  font-size: 1.6rem;
  text-indent: 10px;
} */
</style>
