<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    class="popup modify_site"
  >
    <div class="popup_header">
      <div class="header_title">유류 공급서 등록</div>
      <button @click="close" class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" />
      </button>
    </div>
    <div class="popup_content">
      <div class="form_wrap">
        <div class="form_item">
          <div class="form_write">
            <p class="title">공급일</p>
            <p class="input">
              <date-picker
                v-model="param.supplyDay"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                readonly
              />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">선박명</p>
            <p class="input">
              <input :value="param.shipName" type="text" readonly />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">첨부파일</p>
            <p v-if="fileList.length === 0" class="input">
              <span style="color: white"> 첨부된 파일이 없습니다</span>
            </p>

            <p v-if="param.fileId" class="input">
              <a
                v-for="(file, index) in fileList"
                :key="index"
                @click="onClickDownload(file)"
              >
                <span
                  class="files"
                  @click="downloadFile(file.id)"
                  style="color: white"
                  >{{ file.ogName }}</span
                >
              </a>
              <!-- <a v-show="!param.isReadOnly" @click="removeFile">
                <span class="file-del">삭제</span>
              </a> -->
            </p>
            <!-- <p v-if="param.fileId === null" class="filebox">
              <input
                id="actionDocFile"
                ref="actionDocFile"
                type="file"
                :accept="DOC_ALLOW_FILE"
                class="blind"
                @change="doActionDocFileAttach"
              />
            </p> -->
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">유종</p>
            <p class="input">
              <input :value="param.oilType" type="text" readonly />
            </p>
            <p class="title" style="padding-left: 20px">주유량</p>
            <p class="input">
              <input :value="param.oilAmount" type="text" readonly />
            </p>
          </div>
        </div>

        <div class="form_item">
          <div class="form_write">
            <p class="title">단가</p>
            <p class="input">
              <input :value="param.unitPrice" type="text" readonly />
            </p>
            <p class="title" style="padding-left: 20px">유류대금</p>
            <p class="input">
              <input :value="param.oilPrice" type="text" readonly />
            </p>
          </div>
        </div>

        <div class="form_item">
          <div class="form_write">
            <p class="title">수수료</p>
            <p class="input">
              <input :value="param.extraPrice" type="text" readonly />
            </p>
            <p class="title" style="padding-left: 20px">용역비</p>
            <p class="input">
              <input :value="param.servicePrice" type="text" readonly />
            </p>
          </div>
        </div>

        <div class="form_item">
          <div class="form_write">
            <p class="title" style="width: 80px">합계</p>
            <p class="input">
              <input :value="param.totalPrice" type="text" readonly />
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <button type="button" class="comfirm" @click="save">확인</button>
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import { mapGetters } from "vuex";
import EquipmentApi from "../../api/osm/EquipmentApi";
import FileApi from "../../api/coco/FileApi";
import FileHelper from "../../helper/FileHelper";
export default {
  name: "PopupCreateEquipment",
  components: { DatePicker },
  props: {},
  data: () => {
    return {
      isShow: false,
      param: {
        startDate: "2024-07-11",
      },
      day: [],
      fileList: [],
    };
  },
  computed: { ...mapGetters(["getLang"]) },
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    async open(param) {
      this.param = param;
      if (this.param.fileId) {
        this.fileList = await FileApi.list(this.param.fileId);
      }
      this.isShow = true;
      console.log("createOpen");
    },
    async onClickDownload(file) {
      FileApi.download(file.fileId, file.fileNo);
    },
    close() {
      this.day = [];
      this.param = {};
      this.isShow = false;
    },
    async save() {
      //   this.param.startDate = "";
      //   this.param.endDate = "";
      //   this.param.content = "";
      //   this.param.title = "";
      this.param = {};
      this.isShow = false;
    },
  },
};
</script>
<style>
.popup .form_wrap .form_write .title {
  width: 220px;
}
.v-toast {
  z-index: 10000000 !important;
}
</style>
