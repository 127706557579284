<template>
  <body>
    <div class="sea_wrap">
      <!-- 범례 추가 -->
      <!-- <div class="legend_btns" style="z-index: 1">
        <p class="title">인력 범례</p>
        <p class="legend comp3"><span>(주)대우건설</span></p>
        <p class="legend comp1"><span>토성토건(주)</span></p>
        <p class="legend comp2"><span>(주)큐텍이씨에스</span></p>
      </div> -->
      <!-- 범례 추가 -->
      <div class="map_wrap">
        <div id="map" />
      </div>
      <!-- <div class="lcd_wrap">
        <div class="lcd_text_wrap">
          <p class="lcd_txt">
            {{ billboardStr }}
          </p>
        </div>
        <button type="button" class="lcd_add_btn" @click="openPopup">
          <img src="/img/sea/icon_writepen.svg" alt="글 추가하기" />
        </button>
      </div> -->
      <div class="box-infomation">
        <div class="box-item item-percent">
          <div class="box-head">
            <p>공정률 &middot; 공사일</p>
          </div>
          <div class="box-content">
            <div class="content-box box-01">
              <vue-flip v-model="cardFlip" width="100%" height="100%">
                <template v-slot:front>
                  <div class="side side1">
                    <div class="gauge_item_chart chart_01">
                      <svg
                        class="radial-progress"
                        :data-percentage="site.progressRate"
                        viewBox="0 0 80 80"
                      >
                        <defs>
                          <linearGradient
                            id="gradient"
                            x1="0%"
                            y1="0%"
                            x2="0%"
                            y2="100%"
                          >
                            <stop offset="0%" stop-color="#56850f" />
                            <stop offset="100%" stop-color="#85c621" />
                          </linearGradient>
                        </defs>
                        <circle class="incomplete" cx="40" cy="40" r="35" />
                        <circle
                          class="complete"
                          stroke="url(#gradient)"
                          cx="40"
                          cy="40"
                          r="35"
                          style="stroke-dashoffset: 39.58406743523136"
                        />
                      </svg>
                      <div class="center_text">
                        <p class="number">{{ site.progressRate }}<em>%</em></p>
                        <p class="description">공정률</p>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:back>
                  <div class="side side2">
                    <div class="gauge_item_chart chart_02">
                      <svg
                        class="radial-progress"
                        :data-rercentage="site.progress"
                        viewBox="0 0 80 80"
                      >
                        <defs>
                          <linearGradient
                            id="gradient2"
                            x1="0%"
                            y1="0%"
                            x2="0%"
                            y2="100%"
                          >
                            <stop offset="0%" stop-color="#dfaa0b" />
                            <stop offset="100%" stop-color="#f9e222" />
                          </linearGradient>
                        </defs>
                        <circle
                          class="incomplete"
                          cx="40"
                          cy="40"
                          r="35"
                        ></circle>
                        <circle
                          class="complete"
                          stroke="url(#gradient2)"
                          cx="40"
                          cy="40"
                          r="35"
                          style="stroke-dashoffset: 39.58406743523136"
                        ></circle>
                      </svg>
                      <div class="center_text">
                        <p class="number">{{ site.progressDay }}<em>일</em></p>
                        <p class="description">공사일</p>
                      </div>
                    </div>
                  </div>
                </template>
              </vue-flip>
            </div>
          </div>
        </div>

        <!-- <div class="box-item item-peopple">
          <div class="box-head">
            <p>공사 투입 현황</p>
          </div>
          <div class="box-content">
            <div class="content-box box-01">
              <div class="item">
                <vue-flip v-model="cardFlip" width="100%" height="100%">
                  <template v-slot:front>
                    <div class="side side1">
                      <div class="peopple_box">
                        <p class="image">
                          <img src="/img/sea/worker.png" alt="worker" />
                        </p>
                        <p class="title">인력</p>
                      </div>
                    </div>
                  </template>
                  <template v-slot:back>
                    <div class="side side2">
                      <div class="peopple_box">
                        <p class="num">{{ commuteCount.worker }}</p>
                        <p class="title">인력(명)</p>
                      </div>
                    </div>
                  </template>
                </vue-flip>
              </div>
            </div>
            <div class="content-box box-02">
              <div class="item">
                <vue-flip v-model="cardFlip" width="100%" height="100%">
                  <template v-slot:front>
                    <div class="side side1">
                      <div class="peopple_box">
                        <p class="image">
                          <img src="/img/sea/car.png" alt="car" />
                        </p>
                        <p class="title">장비</p>
                      </div>
                    </div>
                  </template>
                  <template v-slot:back>
                    <div class="side side2">
                      <div class="peopple_box">
                        <p class="num">{{ commuteCount.car }}</p>
                        <p class="title">장비(대)</p>
                      </div>
                    </div>
                  </template>
                </vue-flip>
              </div>
            </div>
            <div class="content-box box-03">
              <div class="item">
                <vue-flip v-model="cardFlip" width="100%" height="100%">
                  <template v-slot:front>
                    <div class="side side1">
                      <div class="peopple_box">
                        <p class="image">
                          <img src="/img/sea/ship.png" alt="ship" />
                        </p>
                        <p class="title">선박</p>
                      </div>
                    </div>
                  </template>
                  <template v-slot:back>
                    <div class="side side2">
                      <div class="peopple_box">
                        <p class="num">{{ commuteCount.boat }}</p>
                        <p class="title">선박(척)</p>
                      </div>
                    </div>
                  </template>
                </vue-flip>
              </div>
            </div>
          </div>
        </div> -->

        <div class="box-item item-weather">
          <div class="box-head">
            <p>해양 날씨</p>
          </div>
          <div class="box-content">
            <div class="content-box box-01">
              <div class="item">
                <vue-flip v-model="cardFlip" width="100%" height="100%">
                  <template v-slot:front>
                    <div class="side side1">
                      <div class="item">
                        <div class="weather_content content_01">
                          <div class="number">
                            {{
                              oceanInfo.waveHeight ? oceanInfo.waveHeight : 0
                            }}
                          </div>
                          <p class="title">평균파고(m)</p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:back>
                    <div class="side side2">
                      <div class="item">
                        <div class="weather_content content_03">
                          <div class="number">
                            {{ oceanInfo.airTemp ? oceanInfo.airTemp : 0 }}
                          </div>
                          <p class="title">대기온도</p>
                        </div>
                      </div>
                    </div>
                  </template>
                </vue-flip>
              </div>
            </div>
            <div class="content-box box-02">
              <div class="item">
                <vue-flip v-model="cardFlip" width="100%" height="100%">
                  <template v-slot:front>
                    <div class="side side1">
                      <div class="item">
                        <div class="weather_content content_05">
                          <div class="number ch">
                            {{
                              oceanInfo.currentDir ? oceanInfo.currentDir : ""
                            }}
                          </div>
                          <p class="title">파향</p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:back>
                    <div class="side side2">
                      <div class="item">
                        <div class="weather_content content_04">
                          <div class="number">
                            {{ oceanInfo.windSpeed ? oceanInfo.windSpeed : 0 }}
                          </div>
                          <p class="title">풍속</p>
                        </div>
                      </div>
                    </div>
                  </template>
                </vue-flip>
              </div>
            </div>
          </div>
        </div>

        <div
          v-show="warningTitle.length > 0"
          class="box-item item-alert"
          style="display: block"
        >
          <div class="box-head">
            <p class="fa-times">
              {{ warningTitle }}
            </p>
          </div>

          <div class="box-content">
            <div class="content-box box-01">
              <div class="alert-card">
                <p class="cont">
                  {{ warningContent }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="sec_work_list" :class="isListShow ? 'open' : ''">
        <button type="button" class="toggle_work" @click="toggleListClick">
          <img
            src="/img/common/icon_work_btn.png"
            alt="작업자 목록 열기/닫기"
          />
        </button>
        <div class="work_list_inner">
          <div class="work_title">
            <p class="title_text">작업자 목록</p>
          </div>
          <div class="work_list_box">
            <div
              v-for="item in memberList.filter(
                (item) => item.authType === 'WORKER'
              )"
              :key="item.id"
              class="work_item"
            >
              <p v-if="item.company.includes('토성')" class="work_comp comp_1">
                토
              </p>
              <p v-if="item.company.includes('큐텍')" class="work_comp comp_2">
                큐
              </p>
              <p class="work_info">
                <span>{{ item.name }}</span>
                <i v-if="item.carNo">|</i>
                <span>{{ DRIVER_TYPE[item.driverType] }}</span>
                <i v-if="item.carNo">|</i>
                <span>{{ item.carNo }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="sec_admin_list" :class="isAdminShow ? 'open' : ''">
        <button
          type="button"
          class="toggle_admin"
          @click="toggleListClickAdmin"
        >
          <img
            src="/img/common/icon_work_btn.png"
            alt="작업자 목록 열기/닫기"
          />
        </button>
        <div class="admin_list_inner">
          <div class="admin_title">
            <p class="title_text">관리자 목록</p>
          </div>
          <div class="admin_list_box">
            <div
              v-for="item in memberList.filter(
                (item) =>
                  item.authType === 'MANAGER' || item.authType === 'ADMIN'
              )"
              :key="item.id"
              class="admin_item"
            >
              <p v-if="item.company.includes('토성')" class="admin_comp comp_1">
                토
              </p>
              <p v-if="item.company.includes('큐텍')" class="admin_comp comp_2">
                큐
              </p>
              <p v-if="item.company.includes('대우')" class="admin_comp comp_3">
                대
              </p>
              <p class="admin_info">
                <span>{{ item.name }}</span>
                <i v-if="item.carNo">|</i>
                <span>{{ DRIVER_TYPE[item.driverType] }}</span>
                <i v-if="item.carNo">|</i>
                <span>{{ item.carNo }}</span>
              </p>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div v-show="showPopup" class="popup lcd_popup" style="display: block">
      <div class="popup_head">
        <p class="title">공지판 등록</p>
        <button class="popup_close" @click="closePopup">
          <img src="/img/common/popup_close.svg" alt="닫기" />
        </button>
      </div>
      <div class="popup_content">
        <div class="lcd_write">
          <div class="write_item">
            <p class="tlte">내용1</p>
            <div class="process_input">
              <input
                v-model="billboard[0].title"
                type="text"
                placeholder="공정 등 구분자 입력"
              />
            </div>
            <div class="content_input">
              <input
                v-model="billboard[0].content"
                type="text"
                placeholder="내용 입력"
              />
            </div>
            <div class="write_btn">
              <button @click="removeBillboard(0)" type="button">
                <img src="/img/sea/icon_eraser.svg" alt="지우개" />
              </button>
            </div>
          </div>
          <div class="write_item">
            <p class="tlte">내용2</p>
            <div class="process_input">
              <input
                v-model="billboard[1].title"
                type="text"
                placeholder="공정 등 구분자 입력"
              />
            </div>
            <div class="content_input">
              <input
                v-model="billboard[1].content"
                type="text"
                placeholder="내용 입력"
              />
            </div>
            <div class="write_btn">
              <button @click="removeBillboard(1)" type="button">
                <img src="/img/sea/icon_eraser.svg" alt="지우개" />
              </button>
            </div>
          </div>
          <div class="write_item">
            <p class="tlte">내용3</p>
            <div class="process_input">
              <input
                v-model="billboard[2].title"
                type="text"
                placeholder="공정 등 구분자 입력"
              />
            </div>
            <div class="content_input">
              <input
                v-model="billboard[2].content"
                type="text"
                placeholder="내용 입력"
              />
            </div>
            <div class="write_btn">
              <button @click="removeBillboard(2)" type="button">
                <img src="/img/sea/icon_eraser.svg" alt="지우개" />
              </button>
            </div>
          </div>
          <div class="write_item">
            <p class="tlte">내용4</p>
            <div class="process_input">
              <input
                v-model="billboard[3].title"
                type="text"
                placeholder="공정 등 구분자 입력"
              />
            </div>
            <div class="content_input">
              <input
                v-model="billboard[3].content"
                type="text"
                placeholder="내용 입력"
              />
            </div>
            <div class="write_btn">
              <button @click="removeBillboard(3)" type="button">
                <img src="/img/sea/icon_eraser.svg" alt="지우개" />
              </button>
            </div>
          </div>
          <div class="write_item">
            <p class="tlte">내용5</p>
            <div class="process_input">
              <input
                v-model="billboard[4].title"
                type="text"
                placeholder="공정 등 구분자 입력"
              />
            </div>
            <div class="content_input">
              <input
                v-model="billboard[4].content"
                type="text"
                placeholder="내용 입력"
              />
            </div>
            <div class="write_btn">
              <button @click="removeBillboard(4)" type="button">
                <img src="/img/sea/icon_eraser.svg" alt="지우개" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="popup_footer">
        <div class="btn_wrap">
          <button class="btn_cancle" @click="closePopup">취소</button>
          <button class="btn_confime" @click="savePopup">확인</button>
        </div>
      </div>
    </div>
  </body>
</template>
<script>
import SiteCommonApi from "../../api/osm/SiteCommonApi";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  MAPBOX_ACCESS_TOKEN,
  MAPBOX_MAP_STYLE,
  MAPBOX_MAP_STYLE_SATELLITE,
  DEV_LEVEL,
  DRIVER_TYPE,
} from "../../const";
import { Threebox } from "threebox-plugin";
import {} from "../../../public/js/lib/jquery.simplemarquee";
import BillboardApi from "../../api/osm/BillboardApi";
import WeatherApi from "../../api/coco/WeatherApi";
import CommuteApi from "../../api/coco/CommuteApi";
import CustomObjectApi from "../../api/osm/CustomObjectApi";
import InfraApi from "../../api/coco/InfraApi";
import VueFlip from "vue-flip";
import { format } from "date-fns";
import { mapState, mapActions } from "vuex";
import { distinctById } from "../../utils/objectUtil";
import { generateDriverTypeIconElement } from "../../helper/MapObjectHelper";
import { tr } from "date-fns/locale";
export default {
  name: "B",
  components: { "vue-flip": VueFlip },
  data: () => {
    return {
      DRIVER_TYPE: DRIVER_TYPE,
      accessToken: MAPBOX_ACCESS_TOKEN,
      mapStyle: MAPBOX_MAP_STYLE,
      mapStyleSatellite: MAPBOX_MAP_STYLE_SATELLITE,
      correctionAltitude: 6,
      map: null,
      site: { progress: 0, progressRate: 0 },
      placeBox: {
        start: {
          lng: 126.32708491720638,
          lat: 34.813301281295224,
        },
        end: {
          lng: 126.33437258145517,
          lat: 34.81977426145754,
        },
      },
      oceanInfo: {},
      billboard: [
        {
          id: null,
          title: "",
          content: "",
          siteId: 2,
          areaType: "B",
          orderId: 1,
        },
        {
          id: null,
          title: "",
          content: "",
          siteId: 2,
          areaType: "B",
          orderId: 2,
        },
        {
          id: null,
          title: "",
          content: "",
          siteId: 2,
          areaType: "B",
          orderId: 3,
        },
        {
          id: null,
          title: "",
          content: "",
          siteId: 2,
          areaType: "B",
          orderId: 4,
        },
        {
          id: null,
          title: "",
          content: "",
          siteId: 2,
          areaType: "B",
          orderId: 5,
        },
      ],
      billboardStr: "",
      mapInitInfo: {
        center: [126.33246893569668, 34.817263623934366], // 카메라 위도,경도
        // center: [126.9595757, 37.3935764],
        zoom: 17.6, // 줌 크기
        // pitch: 0, // 카메라 각도
        // bearing: 270, // 카메라 위치
        pitch: 75, // 카메라 각도
        bearing: 260, // 카메라 위치
        antialias: true,
      },
      model3dArray: [
        {
          //달리율도교
          objSrc: "/model/2/osm_model4.gltf",
          scale: 1,
          rotation: { x: 90, y: -2, z: 0 },
          lng: 126.3307,
          lat: 34.8169,
          altitude: -36,
        },
        // {
        //   //바지선크레인
        //   objSrc: "/model/2/mapbox_barge_final.gltf",
        //   scale: 2,
        //   rotation: { x: 90, y: 230, z: 0 },
        //   lng: 126.329237,
        //   lat: 34.816012,
        //   altitude: 0,
        // },
        // {
        //   //BP
        //   objSrc: "/model/2/mapbox_bp_final5.gltf",
        //   scale: 1.5,
        //   rotation: { x: 90, y: 230, z: 0 },
        //   lng: 126.328621,
        //   lat: 34.816503,
        //   altitude: 0,
        // },
      ],
      markerMap: {},
      intervals: [],
      commuteCount: {
        worker: 0,
        car: 0,
        boat: 0,
      },
      cardFlip: false,
      showPopup: false,
      companyList: [],
      warningTitle: "",
      warningContent: "",
      memberList: [],
      memberComapnyCount: {
        "토성토건(주)": {
          worker: 0,
          car: 0,
          boat: 0,
        },
      }, //TODO업체별 카운트 목록
      isListShow: true,
      isAdminShow: true,
      devLevel: localStorage.getItem(DEV_LEVEL)
        ? localStorage.getItem(DEV_LEVEL)
        : 0,
    };
  },
  computed: {
    ...mapState({
      workerList: (state) => state.common.workerList,
      carList: (state) => state.common.carList,
    }),
  },
  created() {},
  async mounted() {
    for (var i = 0; i < 100; i++) {
      clearInterval(i);
    }
    document.title = "OSM::On Site Monitoring";
    this.site = await SiteCommonApi.detail();

    this.mapinit(this.mapStyleSatellite);
    await this.getCommute();
    if (this.devLevel < 2)
      this.intervals.push(setInterval(this.getCommute, 1000 * 60 * 5)); //재조회 5분
    this.companyList = await InfraApi.companyList();
    setInterval(() => {
      this.cardFlip = !this.cardFlip;
    }, 1000 * 5);

    this.setBillboard();
    this.getOceanInfo();
    this.getSpecialReport();
  },
  beforeDestroy() {},
  methods: {
    ...mapActions(["setWorkerList", "setCarList"]),
    dashboardInterval() {
      if (this.devLevel < 2) {
        this.intervals.push(
          setInterval(() => {
            this.getDashboard();
          }, 1000 * 5)
        );
      }
    },
    refreshInterval() {
      if (this.devLevel < 2) {
        this.intervals.push(
          setInterval(() => {
            this.$router.go(this.$router.currentRoute);
          }, 1000 * 60 * 60)
        );
      }
    },
    toggleListClick() {
      this.isListShow = !this.isListShow;
    },
    toggleListClickAdmin() {
      this.isAdminShow = !this.isAdminShow;
    },
    async getSpecialReport() {
      this.reportResult = await WeatherApi.specialReport();

      this.warningTitle = "";
      this.warningContent = "";
      this.warningTitle = this.reportResult
        .map((report) => report.warnVar + report.warnStress)
        .join();
      this.warningContent = this.reportResult
        .map(
          (report) =>
            `${report.areaName}  ${report.time.substr(
              0,
              2
            )}:${report.time.substr(2, 2)}경 ${report.warnVar}  ${
              report.warnStress
            }  ${report.command}`
        )
        .join();
    },
    async getOceanInfo() {
      this.oceanInfo = await WeatherApi.oceanInfo();
    },
    async getCommute() {
      const todayList = await CommuteApi.today();

      let workerList = [];
      if (todayList.content.worker)
        workerList = distinctById(JSON.parse(todayList.content.worker));
      let carList = [];
      if (todayList.content.car)
        carList = distinctById(JSON.parse(todayList.content.car));

      const lastPointReq = [];
      let lastPoints = [];
      this.commuteListInit(workerList, lastPointReq);
      this.commuteListInit(carList, lastPointReq);

      if (lastPointReq.length > 0)
        lastPoints = await CustomObjectApi.lastPoints(lastPointReq);
      if (workerList) {
        workerList.map((item) => {
          const point = lastPoints.find((point) => {
            return point.memberId === item.id && point.siteId === item.siteId;
          });
          if (point) {
            item.isMissing = point.isMissing;
            item.lastTimestamp = point.createdAt;
            if (point.createdAt) {
              item.lastDateTime = format(
                new Date(point.createdAt),
                "yyyy-mm-dd HH:mm:ss"
              );
            } else {
              item.lastDateTime = "이력없음";
            }
            item.lat = point.lat;

            item.lng = point.lng;
          }
        });
        workerList = workerList.filter(
          (point) =>
            point.lng > this.placeBox.start.lng &&
            point.lng < this.placeBox.end.lng &&
            point.lat > this.placeBox.start.lat &&
            point.lat < this.placeBox.end.lat
        );
        this.setWorkerList(workerList);
      } else {
        this.setWorkerList([]);
      }

      if (carList) {
        carList.map((item) => {
          const point = lastPoints.find((point) => {
            return point.memberId === item.id && point.siteId === item.siteId;
          });
          if (point) {
            item.isMissing = point.isMissing;
            item.lastTimestamp = point.createdAt;
            if (point.createdAt) {
              item.lastDateTime = format(
                new Date(point.createdAt),
                "yyyy-mm-dd HH:mm:ss"
              );
            } else {
              item.lastDateTime = "이력없음";
            }
            item.lat = point.lat;

            item.lng = point.lng;
          }
        });
        carList = carList.filter(
          (point) =>
            point.lng > this.placeBox.start.lng &&
            point.lng < this.placeBox.end.lng &&
            point.lat > this.placeBox.start.lat &&
            point.lat < this.placeBox.end.lat
        );
        this.setCarList(carList);
      } else {
        this.setCarList([]);
      }

      Object.keys(this.memberComapnyCount).map((company) => {
        this.memberComapnyCount[company] = { worker: 0, car: 0, boat: 0 };
      });
      this.memberList = workerList.concat(carList);
      this.memberList.map((member) => {
        if (!this.memberComapnyCount[member.company]) {
          this.memberComapnyCount[member.company] = {
            worker: 0,
            car: 0,
            boat: 0,
          };
        }
        switch (member.driverType) {
          case null:
            this.memberComapnyCount[member.company].worker =
              this.memberComapnyCount[member.company].worker + 1;
            break;
          case "BARGE":
          case "BOAT":
            this.memberComapnyCount[member.company].boat =
              this.memberComapnyCount[member.company].boat + 1;
            break;
          default:
            this.memberComapnyCount[member.company].car =
              this.memberComapnyCount[member.company].car + 1;
            break;
        }
      });

      const boatList = carList.filter(
        (car) => car.driverType === "BOAT" || car.driverType === "BARGE"
      );
      this.commuteCount.worker = workerList.length;
      this.commuteCount.boat = boatList.length;
      this.commuteCount.car = carList.length - this.commuteCount.boat;
    },
    commuteListInit(list, lastPointReq) {
      list.map((item) => {
        item.checkInTime = "";
        item.checkOutTime = "";
        item.totalHour = "";
        item.lastTimestamp = "";
        if (item.id && item.siteId) {
          lastPointReq.push({
            memberId: item.id,
            siteId: item.siteId,
          });
        }
      });
    },
    removeBillboard(index) {
      this.billboard[index].title = "";
      this.billboard[index].content = "";
    },
    async setBillboard() {
      const result = await BillboardApi.list("B");
      for (let index = 0; index < this.billboard.length; index++) {
        const findItem = result.find((item) => item.orderId === index + 1);
        if (findItem) this.billboard[index] = findItem;
      }

      this.billboardStr = this.billboard
        .filter((item) => item.title.length > 0 || item.content.length)
        .map((item) => `●${item.title} : ${item.content}    `)
        .join(" ");
      if (this.billboardStr.length === 0)
        this.billboardStr = "●등록된 내용이 없습니다";
      while (this.billboardStr.length <= 100) {
        this.billboardStr += this.billboardStr;
      }

      $(".lcd_txt").simplemarquee("destroy");

      setTimeout(() => {
        $(".lcd_txt").simplemarquee({
          speed: 100,
          cycles: Infinity,
          space: 100,
          delayBetweenCycles: 100,
          handleHover: false,
          handleResize: false,
        });
      }, 500);
    },
    addLabel(lnglataltitude, el) {
      var label = window.tb.label({
        position: lnglataltitude,
        htmlElement: el,
        alwaysVisible: true,
        bottomMargin: 0,
        feature: null,
      });
      label.setCoords(lnglataltitude);
      window.tb.add(label);

      return label;
    },
    openPopup() {
      this.showPopup = true;
    },
    savePopup() {
      BillboardApi.save("B", this.billboard).then(() => {
        this.setBillboard();
      });
      this.showPopup = false;
    },
    closePopup() {
      this.showPopup = false;
    },
    mapinit(style) {
      if (!style) {
        style = this.mapStyle;
      }
      mapboxgl.accessToken = this.accessToken;
      this.map = new mapboxgl.Map({
        container: "map", // container ID
        style: style, // style URL
        center: this.mapInitInfo.center,
        zoom: this.mapInitInfo.zoom,
        pitch: this.mapInitInfo.pitch,
        bearing: this.mapInitInfo.bearing,
      });

      this.map.on("load", () => {
        this.getDashboard();
        this.dashboardInterval();
        this.refreshInterval();
        // dem 고도적용된 맵
        this.map.addSource("mapbox-dem", {
          type: "raster-dem",
          url: "mapbox://mapbox.mapbox-terrain-dem-v1",
          tileSize: 512,
          maxzoom: 14,
        });
        this.map.setTerrain({ source: "mapbox-dem", exaggeration: 1 });
      });
      this.map.on("style.load", () => {
        window.tb = new Threebox(
          this.map,
          this.map.getCanvas().getContext("webgl"),
          {
            defaultLights: true,
          }
        );
        this.model3d();
        // this.makeLable();
        this.getDashboard();
      });

      this.map.on("click", (e) => {
        console.log(`A click event has occurred at ${e.lngLat}`);
      });
    },
    async getDashboard() {
      const points = await CustomObjectApi.dashboard();
      if (points) {
        points.forEach((value) => {
          const el = generateDriverTypeIconElement(
            value,
            this.workerList,
            this.carList,
            this.companyList
          );

          if (el) {
            const key = `${value.driverType !== null ? "EQUIP" : "WORKER"}-${
              value.id.memberId
            }`;
            if (this.markerMap[key]) {
              this.markerMap[key].removeLabel();
            }

            this.markerMap[key] = this.addLabel(
              [value.lng, value.lat, this.correctionAltitude],
              el
            );
          }
        });
      }
    },
    model3d() {
      this.map.addLayer({
        id: "custom_layer",
        type: "custom",
        renderingMode: "3d",

        onAdd: async () => {
          this.model3dArray.map((item) => {
            window.tb.loadObj(
              {
                type: "gltf",
                obj: item.objSrc,
                units: "meters",
                scale: item.scale,
                rotation: item.rotation,
                anchor: "center",
              },
              (model) => {
                model.setCoords([item.lng, item.lat, item.altitude]);
                window.tb.add(model);
              }
            );
          });
        },
        render: () => window.tb.update(),
      });
    },
  },
};
</script>
