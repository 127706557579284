import { httpClient } from "../../network/httpclient/HttpClientFactory";

const getDaySample = (memberId, date) =>
  httpClient
    .get(`/map/object/collect/day-sample/${memberId}/${date}`)
    .then((res) => res.data);
const lastPoints = (params) =>
  httpClient.post(`/map/object/last-point`, params).then((res) => res.data);
const dashboard = () =>
  httpClient.get(`/map/object/dashboard`).then((res) => res.data);
const bookmarks = () =>
  httpClient.get(`/map/object/bookmarks`).then((res) => res.data);
const polyLine = (id) =>
  httpClient.get(`/map/object/poly-line/${id}`).then((res) => res.data);
const mapGisinfo = () =>
  httpClient.get(`/map/object/gis-info`).then((res) => res.data);
const mapPenel = () =>
  httpClient.get(`/map/object/panel`).then((res) => res.data);
const mapLine = () =>
  httpClient.get(`/map/object/line`).then((res) => res.data);
const mapThreeModel = () =>
  httpClient.get(`/map/object/three-model`).then((res) => res.data);
const mapBookmarks = () =>
  httpClient.get(`/map/object/bookmarks`).then((res) => res.data);

const workPlace = (params) =>
  httpClient.post(`/map/object/work-place`, params).then((res) => res.data);

const historyDate = (id, date, endDate) =>
  httpClient
    .get(`/map/object/history/${id}/${date}/${endDate}`)
    .then((res) => res.data);

const sse = () => httpClient.get(`/map/object/sse`).then((res) => res.data);
export default {
  getDaySample,
  lastPoints,
  dashboard,
  bookmarks,
  polyLine,
  mapGisinfo,
  mapPenel,
  mapLine,
  mapThreeModel,
  mapBookmarks,
  workPlace,
  sse,
  historyDate,
};
