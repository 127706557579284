<template>
  <div v-show="isShow" class="dash_admin_box">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <!-- 검색 Wrap -->
      <div class="option_wrap">
        <div class="search_filter">
          <form>
            <select v-model="listParam.word" class="search_option">
              <option value="name">장비명</option>
              <option value="carNo">장비번호</option>
            </select>

            <div class="option_date w100">
              <input
                v-model="listParam.name"
                type="text"
                placeholder="검색어 입력"
                @keyup.enter="onClickSearch"
              />
            </div>
            <div class="search_button">
              <button type="button" @click="onClickSearch">검색</button>
            </div>
          </form>
        </div>
        <div class="right_area">
          <div class="download_button">
            <button type="button" @click="regist">장비 등록</button>
          </div>
        </div>
      </div>

      <!-- 검색 Wrap //-->

      <div class="dash_admin_box_table">
        <table>
          <thead>
            <tr>
              <th width="40">장비명</th>
              <th width="50">장비번호</th>
              <th width="40">제조사</th>
              <th width="40">소유주</th>
              <th width="50">연락처</th>
              <th width="50">임대기간</th>
              <th width="30">수정</th>
              <th width="30">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="pageParam.total === 0">
              <td colspan="8">검색 결과가 없습니다.</td>
            </tr>
            <template v-if="pageParam.total !== 0">
              <tr v-for="(item, index) in pageParam.content" :key="index">
                <td>
                  {{ item.name }}
                </td>
                <td
                  style="cursor: pointer; color: aqua"
                  @click="viewDetail(item.id)"
                >
                  {{ item.carNo }}
                </td>
                <td>
                  {{ item.manufacturingCompany }}
                </td>

                <td>
                  {{ item.owner }}
                </td>
                <td>
                  {{ item.contactNo }}
                </td>
                <td>{{ item.startDate }} ~ {{ item.endDate }}</td>
                <td>
                  <button
                    type="button"
                    class="btn_mody"
                    @click="update(item.id)"
                  >
                    수정
                  </button>
                </td>
                <td>
                  <button type="button" class="btn_add" @click="remove(item)">
                    삭제
                  </button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <pagination
        :page-param="pageParam"
        :list-cnt="listParam.size"
        @pageCallback="pageCallback"
      />
    </div>

    <popup-create-equipment ref="popupCreateEquipment" />
    <popup-update-equipment ref="popupUpdateEquipment" />
    <confirm ref="confirm" @confirmCallback="confirmCallback" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PopupCreateEquipment from "../popup/PopupCreateEquipment.vue";
import PopupUpdateEquipment from "./PopupUpdateEquipment.vue";
import Pagination from "../Pagination.vue";
import EquipmentApi from "../../api/osm/EquipmentApi";
import Confirm from "../popup/Confirm.vue";
export default {
  name: "PopupEquipmentList",
  components: {
    PopupCreateEquipment,
    Pagination,
    Confirm,
    PopupUpdateEquipment,
  },
  props: {
    siteId: {},
  },
  data: () => {
    return {
      companyList: [],
      pageParam: { content: [] },
      listParam: {
        page: 1,
        size: 10,
        word: "name",
      },
      isShow: false,
      allSelected: false,
      fileIds: [],
      accessSiteList: [],
      selectSiteId: [],
      confirmParam: {
        title: "",
        msg: "",
        isShow: false,
      },
      noticeId: null,
      paramSiteId: null,
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    async init() {
      // this.getSiteList();
      this.fetchHistoryFile();
    },
    onClickSearch() {
      this.listParam.page = 1;
      this.fetchHistoryFile();
    },
    async fetchHistoryFile() {
      const result = await EquipmentApi.equipList(this.listParam);
      result.size = this.listParam.size;
      this.pageParam = result;
      console.log(result);
    },

    pageCallback(page) {
      this.listParam.page = page;
      this.fetchHistoryFile();
    },
    contentSubStr(str) {
      if (str) {
        str = str.replace(/<[^>]*>?/g, "");
        if (str.length > 10) {
          str = str.substr(0, 8) + "...";
        }
      }
      return str;
    },
    imgFaults(item) {
      var url = "/img/common/";
      if (item) url += "bullet_red_icon.png";
      else url += "bullet_green_icon.png";

      return url;
      //bullet_green_icon.png
      //bullet_red_icon.png
    },
    getNumber(index) {
      this.pageParam.total -
        ((this.listParam.page - 1) * this.listParam.size + index);
    },

    sliceTime(time) {
      if (time) return time.slice(0, 5);
      return "";
    },
    enter2Br(str) {
      if (!str) return "";
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    open() {
      console.log("open");
      this.init();
      this.isShow = true;
    },
    close() {
      this.listParam.title = null;
      this.isShow = false;
    },
    selectAll() {
      this.fileIds = [];
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        this.pageParam.content.forEach((element) => {
          this.fileIds.push(element.fileId);
        });
      }
    },
    select() {
      this.allSelected = false;
    },
    async onClickDownload() {
      const fileArray = [];
      if (this.fileIds.length === 0) {
        this.$toast.info("파일을 선택하세요.");
        return;
      }

      this.fileIds.forEach((fileId) => {
        this.pageParam.content.forEach((item) => {
          if (fileId === item.fileId) {
            const file = {};
            file.fileId = item.fileId;
            file.fileNo = item.fileNo;
            fileArray.push(file);
          }
        });
      });

      const fileName = "시험계획서";
    },
    changeSite() {
      this.fetchHistoryFile();
    },
    regist() {
      this.$refs.popupCreateEquipment.open();
    },
    async confirmCallback(param) {
      console.log(param);
      await EquipmentApi.deleteEquip(param.id);
      this.fetchHistoryFile();
      // this.init();
    },
    async viewDetail(id) {
      // const response = await BoardApi.detail(id);
      // response.content.selectSiteId = this.selectSiteId;
      // response.content.accessSiteList = this.accessSiteList;
      // this.$refs.popupDetailNotice.open(response.content);
    },
    async update(id) {
      const response = await EquipmentApi.getDetail(id);
      this.$refs.popupUpdateEquipment.open(response);
    },
    remove(param) {
      param.msg = "삭제 하시겠습니까?";
      this.$refs.confirm.open(param);
    },
    deleteCallback() {
      // BoardApi.remove(this.noticeId).then(() => {
      //   this.init();
      //   this.$toast.success("삭제 되었습니다.");
      // });
    },
  },
};
</script>
<style scoped></style>
