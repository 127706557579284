<template>
  <div v-if="isShow" class="popup" style="display: block">
    <div class="popup_header">
      <div class="header_title">{{ param.title }}</div>
      <button class="close_popup" @click="close()">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" />
      </button>
    </div>
    <div class="popup_content">
      <div class="text_info_popup">
        {{ param.msg }}
      </div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <button type="button" class="cancel" @click="close()">취소</button>
        <button type="button" class="comfirm" @click="ok(param.callback)">
          확인
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirm",
  components: {},

  data: () => {
    return {
      isShow: false,
      param: {
        msg: "삭제 하시겠습니까?",
        title: "확인",
      },
    };
  },
  computed: {},
  watch: {},

  beforeCreate() {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    open(param) {
      this.isShow = true;
      this.param = param;
      console.log(param);
    },
    close() {
      this.isShow = false;
    },
    ok() {
      this.isShow = false;
      this.param.callbackParam = {};
      console.log(this.param);
      this.param.callbackParam.id = this.param.id;
      this.$emit("confirmCallback", this.param.callbackParam);
      this.$emit("deleteCallback", this.param.callbackParam);
    },
  },
};
</script>
