const DOC_ALLOW_FILE = 'image/*,.pdf,.doc,.xls,.ppt,.hwp,.docx,.xlsx,.pptx,.zip,.txt,.me.mp4,.mov';

const MAX_SIZE = 20971520;
const MAX_MOVE_SIZE = 50971520;
const MAX_COUNT = 3;
const ALLOW_FILE_DOC = '.pdf,.doc,.xls,.ppt,.hwp,.docx,.xlsx,.pptx,.zip,.txt,.me.mp4,.mov';
const ALLOW_FILE_IMG = '.jpg,.jpeg,.png,.bmp,.ai,.gif,tif';

const isNewSize = (size, fileName) => {
    const ext = fileName.split('.').pop() || '';
    const fileExt = 'mp4,mov';
    if (fileExt.indexOf(ext.toLowerCase()) >= 0) {
        if (MAX_MOVE_SIZE < size) {
            alert('동영상은 50MB이내로 첨부가 가능합니다.');
            return false;
        }
    } else {
        if (MAX_SIZE < size) {
            alert('20MB이내로 첨부가 가능합니다.');
            return false;
        }
    }
    return true;
};

const isAllowFile = fileName => {
    const fileExt = ALLOW_FILE_DOC + ',' + ALLOW_FILE_IMG;
    const ext = fileName.split('.').pop() || '';
    if (fileExt.indexOf(ext) < 0) {
        alert('첨부가능한 파일이 아닙니다.');
        return false;
    }
    return true;
};

const checkFile = fileObject => {
    if (fileObject) {
        const size = fileObject.size;
        if (isNewSize(size, fileObject.name) && isAllowFile(fileObject.name)) {
            return true;
        }
    }

    return false;
};

export default {
    createFileFormData: (fileObject, fileId = null, fileNo = null) => {
        if (fileObject) {
            const size = fileObject.size;
            if (isNewSize(size, fileObject.name) && isAllowFile(fileObject.name)) {
                const formData = new FormData();
                if (fileId) formData.append('fileId', fileId);
                if (fileNo) formData.append('fileNo', fileNo);
                formData.append('fileName', fileObject.name);
                formData.append('file', fileObject);
                return formData;
            }
        }
        return null;
    },
    allowFileExt: () => {
        return DOC_ALLOW_FILE;
    },
    isCount: count => {
        if (MAX_COUNT <= count) {
            alert('최대 파일 허용개수는 ' + MAX_COUNT + ' 입니다.');
            return false;
        }
        return true;
    },
    isSize: size => {
        if (MAX_SIZE < size) {
            alert('20MB이내로 첨부가 가능합니다.');
            return false;
        }
        return true;
    },
    isNewSize,
    isAllowFile,
    checkFile,
    appFileDownload: fileData => {
        const blob = fileData.blob;
        const filename = fileData.fileName;
        let storageLocation = '';
        // externalDataDirectory에 쓰기 권한이 왜 없지?
        // externalDataDirectory -> externalRootDirectory 로 변경
        // https://stackoverflow.com/questions/70110433/cordova-file-externalrootdirectory-not-working-in-android-11-devices
        switch (window.device.platform) {
            case 'Android':
                //storageLocation = window.cordova.file.externalRootDirectory + 'Download/';
                storageLocation = window.cordova.file.externalDataDirectory;
                break;

            case 'iOS':
                storageLocation = window.cordova.file.documentsDirectory;
                break;
        }

        console.log('file download location : ' + storageLocation);

        const folderPath = storageLocation;
        window.resolveLocalFileSystemURL(
            folderPath,
            function(dir) {
                dir.getFile(
                    filename,
                    {
                        create: true
                    },
                    function(file) {
                        file.createWriter(
                            function(fileWriter) {
                                fileWriter.write(blob);

                                fileWriter.onwriteend = function() {
                                    var url = file.nativeURL;
                                    window.cordova.plugins.fileOpener2.open(url, blob.type, {
                                        error: function error(err) {
                                            console.error(err);
                                            alert('Unable to download');
                                        },
                                        success: function success() {
                                            console.log('success with opening the file');
                                        }
                                    });
                                };
                                fileWriter.onerror = function(err) {
                                    alert('Unable to download');
                                    console.error('error1', err);
                                };
                            },
                            function(err) {
                                // failed
                                alert('Unable to download');
                                console.error('error2', err);
                            }
                        );
                    },
                    function(err) {
                        alert('Unable to download');
                        console.error('error3', err);
                    }
                );
            },
            function(err) {
                alert('Unable to download');
                console.error('error4', err);
            }
        );
    }
};
