import { httpClient } from "../../network/httpclient/HttpClientFactory";

const equipList = (params) =>
  httpClient.get(`/equipment`, { params }).then((res) => res.data);

const getDetail = (id) =>
  httpClient.get(`/equipment/${id}`).then((res) => res.data);

const deleteEquip = (id) =>
  httpClient.delete(`/equipment/${id}`).then((res) => res.data);
const saveEquip = (param) =>
  httpClient.post(`/equipment`, param).then((res) => res.data);
const updateEquip = (id, param) =>
  httpClient.put(`/equipment/${id}`, param).then((res) => res.data);

export default {
  saveEquip,
  equipList,
  getDetail,
  updateEquip,
  deleteEquip,
};
