import { httpClient } from "../../network/httpclient/HttpClientFactory";

const getBimProgress = (bimId) =>
  httpClient.get(`/site/bim/progress/${bimId}`).then((res) => res.data);

const getBimProgressCounts = () =>
  httpClient.get(`/site/bim/progress`).then((res) => res.data);

const save = (param) =>
  httpClient.post(`/site/bim/progress`, param).then((res) => res.data);

const detail = () => httpClient.get(`/site`).then((res) => res.data);

/* [2023/12/14] added by yhj */
const getBimType = (bimId) =>
  httpClient.get(`/site/bim/type/${bimId}`).then((res) => res.data);

export default {
  getBimProgress,
  save,
  detail,
  getBimProgressCounts,

  /* [2023/12/14] added by yhj */
  getBimType,
};
