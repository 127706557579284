<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    class="calander_popup site"
  >
    <div class="calander_header">
      <p class="header_title">{{ siteName }} 일정 캘린더</p>
      <p class="header_today">{{ year }}. {{ month }}</p>
      <nav class="navbar">
        <button class="button is-rounded prev" @click="movePrevMonth">
          <img alt="prev" src="/img/common/arrow_prev.svg" />
        </button>
        <span class="navbar--today">{{ year }}. {{ month }}</span>
        <button class="button is-rounded next" @click="moveNextMonth">
          <img alt="prev" src="/img/common/arrow_fowd.svg" />
        </button>
      </nav>
      <div class="admin_head_button">
        <button type="button" class="type_blue calendar" @click="openPopup">
          일정 등록
        </button>
      </div>
      <!-- <div class="admin_head_button">
        <button
          type="button"
          class="type_blue calendar"
          @click="openPopupShipLog"
        >
          운항 일지 등록
        </button>
      </div> -->
      <div class="admin_head_button">
        <button type="button" class="type_blue calendar" @click="openPopupOil">
          유류 공급서 등록
        </button>
      </div>
    </div>
    <div class="calander_content">
      <div id="app" />
    </div>

    <popup-modify-site
      :detail-item="detailItem"
      :is-show="detailIsShow"
      @detailCallback="detailCallback"
    />
    <popup-create-ship-log
      ref="popupCreateShipLog"
      @saveCallBack="saveCallBack"
    />
    <popup-create-oil-management
      ref="popupCreateOilManagement"
      @confirmCallback="confirmCallback"
    />
    <popup-update-ship-log
      ref="popupUpdateShipLog"
      @updateCallBack="updateCallBack"
    />
  </div>
</template>
<script>
import Calendar from "@toast-ui/calendar";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import { POPUP_NAME, SITE_NAME } from "../../const";
import { dayFormat } from "../../utils/date";
import DailyLogApi from "../../api/osm/DailyLogApi";
import OilDocumentApi from "../../api/osm/OilDocumentApi";
import ShipLogBookApi from "../../api/osm/ShipLogBookApi";
import WeatherApi from "../../api/coco/WeatherApi";
import PopupModifySite from "./PopupModifySite.vue";
import PopupCreateShipLog from "../popup/PopupCreateShipLog.vue";
import PopupCreateOilManagement from "../popup/PopupCreateOilManagement.vue";
import PopupUpdateShipLog from "./PopupUpdateShipLog.vue";

export default {
  name: "PopupSiteLogCalendar",
  components: {
    PopupModifySite,
    PopupCreateShipLog,
    PopupCreateOilManagement,
    PopupUpdateShipLog,
  },
  props: {},
  data: () => {
    return {
      isShow: true,
      POPUP_NAME: POPUP_NAME,
      content: [],
      listParam: { page: 1, size: 10 },
      shipLogParam: {
        page: 1,
        size: 100,
      },
      oilLogParam: {
        page: 1,
        size: 100,
      },
      pageParam: [],
      shipLogList: [],
      oilLogList: [],
      weather: [],
      calendar: null,
      calendarId: null,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      detailItem: null,
      detailIsShow: false,
      siteName: localStorage.getItem(SITE_NAME),
    };
  },
  computed: {},
  created() {
    // this.weatherList();
    // this.list();
  },
  async mounted() {
    this.$nextTick(() => {
      this.initializeCalendar(); // 캘린더를 DOM이 준비된 후 초기화
    });
    await this.list();
  },
  beforeDestroy() {},
  methods: {
    async open() {
      // this.list();
      this.isShow = true;
      // this.$nextTick(() => {
      //   this.initializeCalendar(); // 캘린더를 DOM이 준비된 후 초기화
      // });

      // await this.list();

      console.log("this.content", this.content);
    },
    close() {
      this.isShow = false;
    },
    detailCallback() {
      this.detailIsShow = false;
      this.list();
    },
    saveCallBack() {
      this.list();
    },
    confirmCallback() {
      this.list();
    },
    updateCallBack() {
      this.list();
    },
    initializeCalendar() {
      var MOCK_CALENDARS = [
        {
          id: "1",
          name: "My Calendar",
          color: "#ffffff",
          borderColor: "#41321c",
          backgroundColor: "#886226",
          dragBackgroundColor: "#886226",
        },
        {
          id: "2",
          name: "Work",
          color: "#ffffff",
          borderColor: "#0c6356",
          backgroundColor: "#0f3230",
          dragBackgroundColor: "#0f3230",
        },
        {
          id: "3",
          name: "Family",
          color: "#ffffff",
          borderColor: "#53338b",
          backgroundColor: "#2c1f45",
          dragBackgroundColor: "#2c1f45",
        },
        {
          id: "4",
          name: "Friends",
          color: "#ffffff",
          borderColor: "##09588b",
          backgroundColor: "#0e2e45",
          dragBackgroundColor: "#0e2e45",
        },
        {
          id: "5",
          name: "Travel",
          color: "#ffffff",
          borderColor: "#62740c",
          backgroundColor: "#323912",
          dragBackgroundColor: "#323912",
        },
      ];
      const that = this;
      this.calendar = new Calendar("#app", {
        usageStatistics: false,
        defaultView: "month",
        calendars: MOCK_CALENDARS,
        useCreationPopup: false,
        useDetailPopup: false,
        isReadOnly: true,
        month: {
          startDayOfWeek: 1,
          isAlways6Weeks: false,
          visibleEventCount: 6,
        },
        template: {
          monthGridHeader(model) {
            let imgPath = "";
            //다른 달의 날짜는 나타내지 않음
            let date = new Date(model.date).getDate();
            if (model.isOtherMonth === true) {
              date = `<span style="color: grey;">${date}</span>`;
            }

            if (that.weather !== undefined) {
              that.weather.forEach((element) => {
                if (element.baseDate === model.date) {
                  if (element.displayType === "맑음") {
                    imgPath =
                      '<img alt="prev" src="/img/common/weather/weather_01.svg" />';
                  } else if (element.displayType === "흐림") {
                    imgPath =
                      '<img alt="prev" src="/img/common/weather/weather_03.svg" />';
                  } else if (element.displayType === "눈") {
                    imgPath =
                      '<img alt="prev" src="/img/common/weather/weather_10.svg" />';
                  } else if (element.displayType === "비") {
                    imgPath =
                      '<img alt="prev" src="/img/common/weather/weather_06.svg" />';
                  }
                }
              });
            }

            // return `<span id='cell-${dayFormat(new Date(model.date))}'>${date}  ${imgPath}  </span>`;
            if (model.isToday === true) {
              return `<span id='cell-${dayFormat(
                new Date(model.date)
              )}-today'>${date}  ${imgPath}  </span>`;
            } else {
              return `<span id='cell-${dayFormat(
                new Date(model.date)
              )}'>${date}  ${imgPath}  </span>`;
            }
          },
        },
      });

      setTimeout(() => {
        console.log($(`#cell-${dayFormat(new Date())}`));
        $(`#cell-${dayFormat(new Date())}-today`)
          .parents(".toastui-calendar-daygrid-cell")
          .addClass("today");
      }, 500);

      this.calendar.on("clickEvent", ({ event }) => {
        console.log(event);
        this.popupModify(event);
      });
    },
    async list() {
      await this.setContent();
      if (this.calendar) {
        this.calendar.clear();
        this.calendar.createEvents(this.content);
        this.calendar.render();
      }
    },
    weatherList() {
      WeatherApi.list({
        startDate: dayFormat(new Date(this.year, this.month - 1, 1)).replaceAll(
          "-",
          ""
        ),
        endDate: dayFormat(new Date(this.year, this.month, 0)).replaceAll(
          "-",
          ""
        ),
      }).then((result) => {
        this.weather = result;
        this.testItem = result;
      });
    },
    async setContent() {
      if (this.calendar) this.calendar.clear();

      this.content = [];
      const result = await DailyLogApi.list(this.listParam);
      result.size = this.listParam.size;
      this.pageParam = result;

      const shipLogResult = await ShipLogBookApi.shipLogList(this.shipLogParam);
      this.shipLogList = shipLogResult;

      shipLogResult.content.forEach((log) => {
        this.content.push({
          id: log.id,
          calendarId: "1",
          title: "운행일지 - " + log.equipmentName,
          start: log.operationDay,
          end: log.operationDay,
          category: "allday",
          isReadOnly: true,
          raw: {
            type: "shipLog",
          },
        });
      });

      const oilLogResult = await OilDocumentApi.oilDocList(this.oilLogParam);
      this.oilLogList = oilLogResult;

      oilLogResult.content.forEach((log) => {
        this.content.push({
          id: log.id,
          calendarId: "2",
          title: "유류 공급서 - " + log.shipName,
          start: log.supplyDay,
          end: log.supplyDay,
          category: "allday",
          isReadOnly: true,
          raw: {
            type: "oilLog",
          },
        });
      });

      this.pageParam.content.forEach((element) => {
        if (element.labelClass === "orange") {
          this.calendarId = "1";
        } else if (element.labelClass === "green") {
          this.calendarId = "2";
        } else if (element.labelClass === "purple") {
          this.calendarId = "3";
        } else if (element.labelClass === "blue") {
          this.calendarId = "4";
        } else if (element.labelClass === "yellow") {
          this.calendarId = "5";
        }
        this.content.push({
          id: element.id,
          calendarId: "3",
          title: element.title,
          start: element.startDate,
          end: element.endDate,
          category: "allday",
          isReadOnly: true,
        });
      });
      // this.calendar.render();
    },
    movePrevMonth() {
      this.month = this.month - 1;
      this.calendar.move(-1);
      if (this.month === 0) {
        this.year = this.year - 1;
        this.month = 12;
      }
      // this.weatherList();
      this.list();
      $(`.toastui-calendar-daygrid-cell.today`).removeClass("today");

      if ($(`#cell-${dayFormat(new Date())}-today`).length === 1) {
        $(`#cell-${dayFormat(new Date())}-today`)
          .parents(".toastui-calendar-daygrid-cell")
          .addClass("today");
      }
    },
    moveNextMonth() {
      this.month = this.month + 1;
      this.calendar.move(+1);
      if (this.month > 12) {
        this.year = this.year + 1;
        this.month = 1;
      }
      // this.weatherList();
      this.list();
      $(`.toastui-calendar-daygrid-cell.today`).removeClass("today");

      if ($(`#cell-${dayFormat(new Date())}-today`).length === 1) {
        $(`#cell-${dayFormat(new Date())}-today`)
          .parents(".toastui-calendar-daygrid-cell")
          .addClass("today");
      }
    },
    popupModify(event) {
      if (event.raw) {
        if (event.raw.type === "oilLog") {
          const targetItem = this.oilLogList.content.find(
            (item) => item.id === event.id
          );
          this.$refs.popupCreateOilManagement.open(targetItem);
        } else if (event.raw.type === "shipLog") {
          const targetItem = this.shipLogList.content.find(
            (item) => item.id === event.id
          );
          this.$refs.popupUpdateShipLog.open(targetItem);
        }
      } else {
        const targetItem = this.pageParam.content.find(
          (item) => item.id === event.id
        );

        this.detailIsShow = true;
        this.detailItem = targetItem;
      }
    },
    openPopup() {
      this.detailIsShow = true;
      const detailItem = {
        labelClass: "blue",
        startDate: "",
        endDate: "",
        content: "",
        title: "",
      };
      this.detailItem = detailItem;
    },
    openPopupShipLog() {
      this.$refs.popupCreateShipLog.open();
    },
    openPopupOil() {
      this.$refs.popupCreateOilManagement.open();
    },
  },
};
</script>
<style scoped>
.calander_popup.site {
  position: fixed;
  top: 60px;

  width: calc(100% - 200px);

  height: calc(100vh - 60px);
  background-color: #1e1e23;
  overflow: auto;
  z-index: 2;
}
</style>
