<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    class="popup modify_notice"
  >
    <div class="popup_header">
      <div class="header_title">공지 사항</div>
      <button class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" @click="close" />
      </button>
    </div>

    <div class="popup_content">
      <div class="form_wrap">
        <div class="form_item">
          <div class="form_write">
            <p class="title">공지 구분</p>
            <p class="input">
              <select
                v-model="param.noticeType"
                :disabled="param.isReadOnly || isUpdate"
                @change="changeType(param.noticeType)"
              >
                <option value="NORMAL">일반 안내</option>
                <option value="WARING">긴급/위험 공지</option>
              </select>
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">공지 제목</p>
            <p class="input">
              <input
                v-model="param.title"
                type="text"
                placeholder="20자 이내로 작성하세요."
                :readonly="param.isReadOnly"
              />
            </p>
          </div>
        </div>

        <div v-show="isNew" class="form_item">
          <div class="form_write">
            <p class="title">대상 업체</p>
            <multiselect
              v-model="value"
              tag-placeholder="Add this as new tag"
              placeholder="대상 업체를 선택하세요"
              label="name"
              track-by="id"
              :options="options"
              :multiple="true"
              :taggable="true"
              :disabled="param.isReadOnly || isUpdate"
            />
          </div>
        </div>

        <div class="form_item">
          <div class="form_write">
            <p class="title">첨부파일</p>
            <p v-if="param.fileId" class="input">
              <a
                v-for="(file, index) in fileList"
                :key="index"
                @click="onClickDownload(file)"
              >
                <span
                  class="files"
                  @click="downloadFile(file.id)"
                  style="color: white"
                  >{{ file.ogName }}</span
                >
              </a>
              <a v-show="!param.isReadOnly" @click="removeFile">
                <span class="file-del">삭제</span>
              </a>
            </p>
            <p
              v-if="param.fileId === null && !param.isReadOnly"
              class="filebox"
            >
              <input
                id="actionDocFile"
                ref="actionDocFile"
                type="file"
                :accept="DOC_ALLOW_FILE"
                class="blind"
                @change="doActionDocFileAttach"
              />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="edit_tool">
            <vue-editor
              id="noticeEditor"
              v-model="param.content"
              :editor-toolbar="customToolbar"
              :disabled="param.isReadOnly"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <button type="button" class="cancel" @click="close">닫기</button>
        <button
          v-show="!param.isReadOnly"
          type="button"
          class="comfirm"
          @click="save"
        >
          확인
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import BoardApi from "../../api/coco/BoardApi";
import FileApi from "../../api/coco/FileApi";
import InfraApi from "../../api/coco/InfraApi";
import FileHelper from "../../helper/FileHelper";
import Multiselect from "vue-multiselect";
export default {
  name: "PopupModifyNotice",
  components: { Multiselect },
  props: {
    isShow: {
      type: Boolean,
    },
    detailItem: {
      type: Object,
      default: null,
    },
    isUpdate: {
      type: Boolean,
    },
    isNew: {
      type: Boolean,
    },
  },
  data: () => {
    return {
      param: {
        type: "NOTICE",
        noticeType: "NORMAL",
        title: "",
        content: " ",
        fileId: null,
        companyIds: null,
      },
      customToolbar: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["link", "image"],
      ],
      DOC_ALLOW_FILE: FileHelper.allowFileExt(),
      fileList: [],
      value: [],
      options: [],
      test: [],
    };
  },
  computed: {},
  watch: {
    detailItem: async function () {
      if (this.detailItem) {
        this.param = this.detailItem;
        if (this.param.fileId)
          this.fileList = await FileApi.list(this.param.fileId);
      } else {
        this.param.type = "NOTICE";
        this.param.noticeType = "NORMAL";
        this.param.title = "";
        this.param.content = " ";
        this.param.id = null;
        this.param.fileId = null;
        this.fileList = [];
        this.param.companyIds = null;
      }
    },
  },
  created() {},
  async mounted() {
    this.options = await InfraApi.companyList();
  },
  beforeDestroy() {},
  methods: {
    close() {
      this.value = [];
      this.isUsed = true;
      this.$emit("popupCallback");
    },
    save() {
      if (this.param.id) {
        BoardApi.update(this.param.id, this.param).then(() => {
          this.$toast.success("저장 되었습니다.");
          this.$emit("popupCallback");
        });
      } else {
        const companyIds = [];
        if (this.value !== null) {
          this.value.forEach((element) => {
            companyIds.push(element.id);
          });
        }
        this.param.companyIds = companyIds;
        BoardApi.save(this.param).then(() => {
          this.value = [];
          this.$toast.success("저장 되었습니다.");
          this.$emit("popupCallback");
        });
      }
    },
    async doActionDocFileAttach() {
      const fileObject = this.$refs.actionDocFile.files[0];

      const docFileForm = FileHelper.createFileFormData(
        fileObject,
        this.param.fileId,
        0
      );
      const fileRes = await FileApi.upload(docFileForm, "DOCUMENT");
      if (fileRes.content) {
        this.param.fileId = fileRes.content.fileId;
        this.fileList = await FileApi.list(this.param.fileId);
      }
    },
    async onClickDownload(file) {
      FileApi.download(file.fileId, file.fileNo);
    },
    removeFile() {
      this.param.fileId = null;
    },
    changeType(type) {
      if (type === "NORMAL") {
        this.isNew = true;
      }
      if (type === "WARING" || this.param.noticeType === "WARING") {
        this.value = null;
        this.isNew = false;
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
