<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    style="min-width: 1100px"
    class="popup modify_site"
  >
    <div class="popup_header">
      <div class="header_title">운항 일지 등록</div>
      <button @click="close" class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" />
      </button>
    </div>
    <div class="popup_content">
      <div class="form_wrap">
        <div class="form_item">
          <div class="form_write">
            <p class="title">운항일자</p>
            <p class="input">
              <date-picker
                v-model="param.operationDay"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
              />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">선박명</p>
            <p class="input">
              <select v-model="param.equipmentName">
                <option
                  v-for="(item, index) in equipList"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">첨부파일</p>
            <p v-if="param.fileId" class="input">
              <a
                v-for="(file, index) in fileList"
                :key="index"
                @click="onClickDownload(file)"
              >
                <span
                  class="files"
                  @click="downloadFile(file.id)"
                  style="color: white"
                  >{{ file.ogName }}</span
                >
              </a>
              <a v-show="!param.isReadOnly" @click="removeFile">
                <span class="file-del">삭제</span>
              </a>
            </p>
            <p v-if="param.fileId === null" class="filebox">
              <input
                id="actionDocFile"
                ref="actionDocFile"
                type="file"
                class="blind"
              />
            </p>
          </div>
        </div>
      </div>
      <div class="dash_admin_box_table" style="height: 100%">
        <table :key="tableKey">
          <thead>
            <tr>
              <th colspan="2" width="40">운항시간</th>
              <th rowspan="2" width="50">출발지</th>
              <th rowspan="2" width="40">목적지</th>
              <th rowspan="2" width="40">비고</th>
            </tr>
            <tr>
              <th width="40">시작</th>
              <th width="40">종료</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in logList" :key="index">
              <td>
                <p class="input">
                  <input v-model="item.startTime" type="time" />
                </p>
              </td>
              <td>
                <p class="input">
                  <input v-model="item.endTime" type="time" />
                </p>
              </td>
              <td>
                <p class="input">
                  <input v-model="item.departure" type="text" />
                </p>
              </td>
              <td>
                <p class="input">
                  <input v-model="item.destination" type="text" />
                </p>
              </td>
              <td>
                <p class="input">
                  <input v-model="item.remark" type="text" />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="foot_btns">
          <button type="button" class="comfirm" @click="addList">추가</button>
        </div>
      </div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <button type="button" class="cancel" @click="close">취소</button>
        <button type="button" class="comfirm" @click="save">저장</button>
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import { mapGetters } from "vuex";
import EquipmentApi from "../../api/osm/EquipmentApi";
import ShipLogBookApi from "../../api/osm/ShipLogBookApi";
import FileApi from "../../api/coco/FileApi";
import FileHelper from "../../helper/FileHelper";
export default {
  name: "PopupCreateEquipment",
  components: { DatePicker },
  props: {},
  data: () => {
    return {
      isShow: false,
      equipList: [],
      param: {},
      data1: {
        time: "09 : 11 ~ 10:00",
        destination: "장감리",
        Departure: "맥도",
        remark: "오일교환",
      },
      data2: {
        time: "11 : 11 ~ 12:00",
        destination: "맥도",
        Departure: "장감리",
        remark: "",
      },
      day: [],
      fileList: [],
      logList: [],
      tableKey: 0,
    };
  },
  computed: { ...mapGetters(["getLang"]) },
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    async open(param) {
      this.param = { ...param };

      if (this.param.fileId) {
        this.fileList = await FileApi.list(this.param.fileId);
      }

      const result = await EquipmentApi.equipList();
      result.content.forEach((element) => {
        this.equipList.push(element.name);
      });
      this.getDetailLog(this.param.id);
      this.isShow = true;
      console.log("createOpen");
    },
    close() {
      this.day = [];
      this.logList = [1];
      this.isShow = false;
    },
    addList() {
      const paramChild = {};
      this.logList.push(paramChild);
      this.tableKey += 1;
      console.log(this.logList);
    },
    async getDetailLog(id) {
      const result = await ShipLogBookApi.getDetailShipLog(id);
      this.logList = result;
      console.log(result);
    },
    async onClickDownload(file) {
      FileApi.download(file.fileId, file.fileNo);
    },
    removeFile() {
      this.param.fileId = null;
    },
    async doActionDocFileAttach() {
      console.log(this.fileList);
      const fileObject = this.$refs.actionDocFile.files[0];
      console.log("fileObject", fileObject);

      const docFileForm = FileHelper.createFileFormData(
        fileObject,
        this.param.fileId,
        0
      );
      const fileRes = await FileApi.upload(docFileForm, "DOCUMENT");
      console.log("fileRes", fileRes);
      if (fileRes.content) {
        this.param.fileId = fileRes.content.fileId;
        this.fileList = await FileApi.list(this.param.fileId);
        console.log("this.fileList", this.fileList);
      }
    },
    async save() {
      this.logList.forEach((log) => {
        log.operationDay = this.param.operationDay;
        log.equipmentName = this.param.equipmentName;
      });
      const shipLogRequest = {
        shipLogBook: this.param,
        shipLogBookChild: this.logList,
      };

      const result = await ShipLogBookApi.updateShipLog(shipLogRequest);
      this.$emit("updateCallBack");

      //   this.param.startDate = "";
      //   this.param.endDate = "";
      //   this.param.content = "";
      //   this.param.title = "";
      // this.param = {};
      // this.logList = [];
      this.isShow = false;
    },
  },
};
</script>
<style>
.popup .form_wrap .form_write .title {
  width: 220px;
}
.v-toast {
  z-index: 10000000 !important;
}
/* .popup .form_wrap .form_write .input input[type="text"] {
  width: 50% !important;
} */
.input input[type="text"] {
  width: 100%;
  height: 35px;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  letter-spacing: -0.2px;
  border: 1px solid #282c32;
  background-color: #000000;
  font-size: 1.6rem;
  text-indent: 10px;
}
</style>
