<template>
  <div class="sea_wrap bim">
    <div class="header">
      <div class="header_inner">
        <div class="logo_wrap">
          <h1 class="logo">
            <img src="/img/sea/bim_logo.svg" alt="daewoo" />
          </h1>
          <p class="logo_info">압해-화원 도로건설공사 제2공구</p>
        </div>

        <!-- 콤보추가를 위한 admin 추가 등 아래 내용 그대로 -->
        <div class="header_info admin">
          <div class="header_info_inner">
            <div class="fixed-combo-box">
              <p>작업 대상 선택하기</p>
              <div class="select">
                <select v-model="bimId" @change="onMove">
                  <option value="1">달리율도교</option>
                  <option value="2">율도압해교</option>
                  <option value="3">율도교</option>
                  <option value="4">달리교</option>
                </select>
              </div>
            </div>
          </div>

          <a href="javascript:;" @click="onDashBoard">
            <p class="btn">대시보드로 보기</p>
          </a>
        </div>
      </div>
    </div>

    <!-- dashboard admin 정리 -->
    <div class="dashboard_wrap admin">
      <!-- bridge dashboard -->
      <div class="dashboard">
        <div class="column_box">
          <div class="column admin1">
            <div class="title">전체공정</div>
            <div id="spanTotalCount" class="progress">
              {{ bimProgressResponse.totalCount || 0 }}
            </div>
          </div>
        </div>
        <div class="column_box">
          <div class="column admin2">
            <div class="title">작업중</div>
            <div id="spanIngCount" class="progress">
              {{ bimProgressResponse.ingCount || 0 }}
            </div>
          </div>
        </div>
        <div class="column_box">
          <div class="column admin3">
            <div class="title">작업완료</div>
            <div id="spanFinishCount" class="progress">
              {{ bimProgressResponse.finishCount || 0 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- legend 범례 추가 -->

    <!-- dashboard_wrap asmin//-->

    <div class="canvas_wrap admin">
      <div id="bim-type-data" class="canvas_info">
        <div
          v-for="(bim, index) in bimTypeProgressResponse"
          :key="index"
          class="info_btns"
        >
          <p class="title">{{ bim.name }}</p>
          <div class="legend">
            <div class="info comp1">{{ bim.totalCount }}</div>
            <div class="info comp2">{{ bim.ingCount }}</div>
            <div class="info comp3">{{ bim.finishCount }}</div>
          </div>
        </div>
      </div>

      <div class="canvas_graph">
        <div v-show="bimOn == true" class="canvas_inner">
          <VueUnity
            :unity="unityContext"
            width="100%"
            height="100%"
            ref="unityContainer"
          />
        </div>
        <div id="map" class="map_wrap" />
        <choice-map-style
          v-show="mapOn == true"
          @changeMapStyle="changeMapStyle"
          @moveBookmarks="moveBookmarks"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/Hello.vue'
import UnityWebgl from "unity-webgl";
import VueUnity from "unity-webgl/vue";
import SiteCommonApi from "../api/osm/SiteCommonApi";
import CustomObjectApi from "../api/osm/CustomObjectApi";

import ChoiceMapStyle from "../components/mapObject/ChoiceMapStyle.vue";
import ContentsBtns from "../components/mapObject/ContentsBtns.vue";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Threebox } from "threebox-plugin";

import {
  MAPBOX_ACCESS_TOKEN,
  MAPBOX_MAP_STYLE,
  MAPBOX_MAP_STYLE_SATELLITE,
  DEV_LEVEL,
  SITE_ID,
} from "../const";

import { mapActions } from "vuex";

import {
  cstrLine,
  officePolygon,
  bridgePanel,
  tunnelPanel,
  setGisInfo,
  generateDriverTypeIconElement,
  generate3dIconElement,
} from "../helper/MapObjectHelper";

var Unity = new UnityWebgl({
  loaderUrl: "/bim/osmBimProgress.loader.js",
  dataUrl: "/bim/osmBimProgress.data",
  frameworkUrl: "/bim/osmBimProgress.framework.js",
  codeUrl: "/bim/osmBimProgress.wasm",
});

//Unity.on("device", () => alert("click device ..."));
//var originalConsoleLog = console.log;
let originalConsoleLog = null;
export default {
  components: {
    // HelloWorld,
    ChoiceMapStyle,
    VueUnity,
    ContentsBtns,
  },
  data() {
    return {
      interval: 3000,
      unityContext: Unity,
      bimProgressResponse: {},
      bimTypeProgressResponse: [],
      bimId: 0,
      accessToken: MAPBOX_ACCESS_TOKEN,
      mapStyle: MAPBOX_MAP_STYLE,
      mapStyleSatellite: MAPBOX_MAP_STYLE_SATELLITE,
      map: null,
      mapInitInfo: {},
      site: { name: "" },
      intervals: [],
      markerMap: {},
      siteId: localStorage.getItem(SITE_ID),
      devLevel: localStorage.getItem(DEV_LEVEL)
        ? localStorage.getItem(DEV_LEVEL)
        : 0,
      mapOn: false,
      bimOn: true,
    };
  },
  computed: {},
  watch: {
    // 'watchedProperty' 값이 변경될 때마다 호출되는 함수
    isChange(newVal, oldVal) {
      console.log(`값이 ${oldVal}에서 ${newVal}로 변경되었습니다.`);
    },
  },
  beforeCreate() {
    // 오버라이드하기 전에 원래의 console.log가 이미 저장되어 있는지 확인
    console.log("beforeCreate");
    //파라미터 받아지나 created 될 때 data 값이 reset 되는 것 같음
    this.bimId = this.$route.params.id || 1;
    const buildUrl = "/bim/" + this.bimId;
    Unity = new UnityWebgl({
      loaderUrl: buildUrl + "/osmBimProgressAdmin.loader.js",
      dataUrl: buildUrl + "/osmBimProgressAdmin.data",
      frameworkUrl: buildUrl + "/osmBimProgressAdmin.framework.js",
      codeUrl: buildUrl + "/osmBimProgressAdmin.wasm",
    });
  },
  created() {
    if (!this.isConsoleDefine) {
      originalConsoleLog = console.log;
    }
    //created 될 때 data값이 초기화 되어 보임
    this.bimId = this.$route.params.id || 1;
  },
  mounted() {
    const bimId = this.bimId;
    if (!this.isConsoleDefine) {
      console.log = function (...args) {
        const targetLog = "osm_progress@";
        // 원래의 console.log 함수를 호출 (로깅을 그대로 수행)
        originalConsoleLog.apply(console, args);

        args.forEach((arg) => {
          if (typeof arg === "string" && arg.startsWith(targetLog)) {
            // 특정 패턴이 있는 경우 원하는 동작 수행
            const values = arg.split("@");
            console.warn("특정 패턴 감지됨:", values[1], values[2]);
            SiteCommonApi.save({
              bimId,
              partName: values[1],
              status: values[2],
            }).then(() => {
              //문제는 vue data 변수에 접근할 수 없음
              //id 로 접근해서 dom을 직접 변경하자
              SiteCommonApi.getBimProgress(bimId).then((res) => {
                var inputElement = document.getElementById("spanTotalCount");
                inputElement.textContent = res.totalCount;
                inputElement = document.getElementById("spanIngCount");
                inputElement.textContent = res.ingCount;
                inputElement = document.getElementById("spanFinishCount");
                inputElement.textContent = res.finishCount;
              });

              SiteCommonApi.getBimType(bimId).then((res) => {
                var inputElement = document.getElementById("bim-type-data");
                var appendHtml = ``;
                res.forEach((item) => {
                  appendHtml += `
                  <div class="info_btns">
                    <p class="title">${item.name}</p>
                    <div class="legend">
                      <div class="info comp1">${item.totalCount}</div>
                      <div class="info comp2">${item.ingCount}</div>
                      <div class="info comp3">${item.finishCount}</div>
                    </div>
                  </div>`;
                });

                inputElement.innerHTML = appendHtml;
              });
            });
          }
        });
      };
    }
    SiteCommonApi.detail().then((res) => {
      this.site = res;
      this.mapInitInfo = JSON.parse(this.site.mapInitInfo);
      this.mapinit(this.mapStyleSatellite);
    });
    this.isConsoleDefine = true;
    this.onBimProgress();
    this.onBimTypeProgress();
  },
  beforeDestroy() {
    console.log("beforeDestroy");
    console.log = originalConsoleLog;
  },
  destroy() {
    console.log("destroy!!!!");
  },
  methods: {
    ...mapActions(["setMapBounds"]),
    async onBimProgress() {
      this.bimProgressResponse = await SiteCommonApi.getBimProgress(this.bimId);
      this.unityContext.on("mounted", () => {
        setTimeout(() => {
          this.bimProgressResponse.partList.forEach((item, index) => {
            setTimeout(() => {
              console.log("sending ");
              Unity.send("GameObject", "Sendnum", item.partName);
              Unity.send("GameObject", "sendType", "" + item.status);
              // 여기에 각 요소에 대해 수행하고 싶은 작업을 추가할 수 있습니다.
            }, index * 100);
          });
        }, this.interval);
      });
    },
    async onBimTypeProgress() {
      this.bimTypeProgressResponse = await SiteCommonApi.getBimType(this.bimId);
    },
    delay(ms) {
      // setTimeout을 프로미스로 감싸는 함수
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    onMove(event) {
      console.log = originalConsoleLog;
      const bimId = event.target.value;
      this.$router.push({ path: `/bim/${bimId}` });
    },
    changeMap() {
      (this.mapOn = true), (this.bimOn = false);
    },
    changeBim() {
      (this.mapOn = false), (this.bimOn = true);
    },
    onDashBoard() {
      const win = window.open(`/#/dashboard/${this.bimId}`, "_blank");
      win.focus();
    },
    changeMapStyle(type) {
      // window.tb.dispose();
      this.map.remove();
      this.markerMap = {};

      if (type === "default") {
        this.mapinit();
      } else {
        this.mapinit(this.mapStyleSatellite);
      }

      // this.contentsBtnsValue.worker = "on";
      // this.contentsBtnsValue.coco = "on";
      // this.contentsBtnsValue.equip = "on";
      // this.contentsBtnsValue.cctv = "on";
    },
    async moveBookmarks(item) {
      if (!item) {
        this.map.setCenter(this.mapInitInfo.center);
        this.map.setZoom(this.mapInitInfo.zoom);
        return false;
      }

      if (item.zoomLevel) this.map.setZoom(item.zoomLevel);
      this.map.setCenter([item.lng, item.lat]);
      this.map.setPitch(item.pitch);
      this.map.setBearing(item.bearing);
      this.$toast.success(`${item.name}로 이동 했습니다`);
    },
    dashboardInterval() {
      if (this.devLevel < 2) {
        this.intervals.push(
          setInterval(() => {
            this.getDashboard();
          }, 1000 * 30)
        );
      }
    },
    async makePanel() {
      const bridgeList = [];
      const tunnelList = [];

      const panelList = await CustomObjectApi.mapPenel();

      panelList.map((item) => {
        item.lnglat = [item.lng, item.lat];
        if (item.panelType === "BRIDGE") {
          bridgeList.push(item);
          return false;
        }
        if (item.panelType === "TUNNEL") {
          tunnelList.push(item);
          return false;
        }
      });

      bridgeList.map((res) => {
        bridgePanel(res, this.map, mapboxgl);
      });

      tunnelList.map((res) => {
        tunnelPanel(res, this.map, mapboxgl);
      });

      const lineList = await CustomObjectApi.mapLine();
      officePolygon(this.site, this.map, mapboxgl);
      cstrLine(lineList, this.site, this.map, mapboxgl);
    },
    model3d() {
      this.map.addLayer({
        id: "custom_layer",
        type: "custom",
        renderingMode: "3d",
        onAdd: async () => {
          const models = await CustomObjectApi.mapThreeModel();

          // models.map((item) => {
          //   window.tb.loadObj(
          //     {
          //       type: "gltf",
          //       obj: item.objSrc,
          //       units: "meters",
          //       scale: item.scale,
          //       rotation: JSON.parse(item.rotation),
          //       anchor: "center",
          //     },
          //     (model) => {
          //       model.setCoords([item.lng, item.lat]);
          //       window.tb.add(model);
          //     }
          //   );
          // });
        },
        render: () => window.tb.update(),
      });
    },
    async getDashboard() {
      const points = await CustomObjectApi.dashboard();
      if (points) {
        points.forEach((value) => {
          const el = generateDriverTypeIconElement(
            value,
            this.workerList,
            this.carList,
            this.companyList
          );

          if (el) {
            const key = `${value.driverType !== null ? "EQUIP" : "WORKER"}-${
              value.id.memberId
            }`;
            if (this.markerMap[key]) {
              this.markerMap[key].removeLabel();
            }

            if (
              key.includes("WORKER") &&
              this.contentsBtnsValue.worker !== "on"
            ) {
              return;
            }

            if (
              key.includes("EQUIP") &&
              this.contentsBtnsValue.equip !== "on"
            ) {
              return;
            }

            this.markerMap[key] = this.addLabel([value.lng, value.lat], el);
          }
        });
      }
      if (this.siteId === "2") {
        new mapboxgl.Marker(generate3dIconElement("a"))
          .setLngLat([126.31522640151309, 34.79012200212962])
          .setPitchAlignment("map")
          .addTo(this.map);

        new mapboxgl.Marker(generate3dIconElement("b"))
          .setLngLat([126.32905996829146, 34.81714314918486])
          .setPitchAlignment("map")
          .addTo(this.map);

        new mapboxgl.Marker(generate3dIconElement("c"))
          .setLngLat([126.2265121701526, 34.84912753408052])
          .setPitchAlignment("map")
          .addTo(this.map);
      }
    },
    getDashboardWork() {
      if (this.workList && window.tb) {
        this.workList.forEach((value) => {
          const key = `COCO-${value.id}`;
          const el = document.createElement("p");
          const imgEl = document.createElement("img");
          el.className = "marker marker type_coco";
          imgEl.className = "coco";
          imgEl.dataset.id = value.id;
          // el.dataset.id = value.id;

          imgEl.style = "top:12rem;right:19.4rem";
          imgEl.src = "../img/common/map/pin_coco.svg";
          el.append(imgEl);
          el.addEventListener("click", () => {
            this.openWorkView(
              this.workList.find((work) => work.id === value.id)
            );
          });

          if (this.markerMap[key]) {
            this.markerMap[key].removeLabel();
          }
          if (this.contentsBtnsValue.coco === "on")
            this.markerMap[key] = this.addLabel(
              [value.longitude, value.latitude],
              el
            );
        });
      }
    },
    mapinit(style) {
      console.log("mapinit");
      if (!style) {
        style = this.mapStyle;
      }
      mapboxgl.accessToken = this.accessToken;
      console.log(this.mapInitInfo);
      this.map = new mapboxgl.Map({
        container: "map", // container ID
        style: style, // style URL
        center: this.mapInitInfo.center,
        // center: [126.74233538655949, 34.71839126770078],
        // zoom: 13.5,
        zoom: this.mapInitInfo.zoom,
        pitch: this.mapInitInfo.pitch,
        bearing: this.mapInitInfo.bearing,
      });
      console.log(this.map);

      this.map.on("load", () => {
        this.dashboardInterval();

        this.setMapBounds(this.map.getBounds());

        this.makePanel();

        if (this.devLevel < 1)
          CustomObjectApi.mapGisinfo().then((res) => setGisInfo(this.map, res));
        // dem 고도적용된 맵
        // this.map.addSource('mapbox-dem', {
        //     type: 'raster-dem',
        //     url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
        //     tileSize: 512,
        //     maxzoom: 14
        // });
        // this.map.setTerrain({ source: 'mapbox-dem', exaggeration: 1 });
      });
      this.map.on("style.load", () => {
        window.tb = new Threebox(
          this.map,
          this.map.getCanvas().getContext("webgl"),
          {
            defaultLights: true,
          }
        );
        this.model3d();
        this.getDashboardWork();
        this.getDashboard();

        // this.map.addSource('maine', {
        //     type: 'geojson',
        //     data: {
        //         type: 'Feature',
        //         geometry: {
        //             type: 'Polygon',
        //             // These coordinates outline Maine.
        //             coordinates: [
        //                 [
        //                     [126.74489726607163, 34.68617181460196],
        //                     [126.74489726607163, 34.7493789946832],
        //                     [126.75650718239933, 34.7493789946832],
        //                     [126.75650718239933, 34.68617181460196],
        //                     [126.74489726607163, 34.68617181460196]
        //                 ]
        //             ]
        //         }
        //     }
        // });

        // // Add a new layer to visualize the polygon.
        // this.map.addLayer({
        //     id: 'maine',
        //     type: 'fill',
        //     source: 'maine', // reference the data source
        //     layout: {},
        //     paint: {
        //         'fill-color': '#0080ff', // blue color fill
        //         'fill-opacity': 0.5
        //     }
        // });
        // // Add a black outline around the polygon.
        // this.map.addLayer({
        //     id: 'outline',
        //     type: 'line',
        //     source: 'maine',
        //     layout: {},
        //     paint: {
        //         'line-color': '#000',
        //         'line-width': 3
        //     }
        // });
      });

      this.map.on("moveend", () => {
        this.setMapBounds(this.map.getBounds());
      });

      this.map.on("click", (e) => {
        console.log(`A click event has occurred at ${e.lngLat}`);

        if (!(e.originalEvent.target instanceof HTMLElement)) return;
        if (e.originalEvent.target.className.includes("coco")) {
          this.openWorkView(
            this.workList.find(
              (work) => work.id === Number(e.originalEvent.target.dataset.id)
            )
          );
        }
      });

      if (this.devLevel > 2) {
        // MapboxDraw 폴리곤 그리기 / 속성 가져오기
        const draw = new MapboxDraw({
          displayControlsDefault: false,
          // Select which mapbox-gl-draw control buttons to add to the map.
          controls: {
            polygon: true,
            trash: true,
          },
          // Set mapbox-gl-draw to draw by default.
          // The user does not have to click the polygon control button first.
          defaultMode: "draw_polygon",
        });
        this.map.addControl(draw);

        this.map.on("draw.create", this.updateArea);
        this.map.on("draw.delete", this.updateArea);
        this.map.on("draw.update", this.updateArea);
      }
    },
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  display: none;
}
</style>
