<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    class="popup modify_site"
  >
    <div class="popup_header">
      <div class="header_title">장비 등록</div>
      <button @click="close" class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" />
      </button>
    </div>
    <div class="popup_content">
      <div class="form_wrap">
        <div class="form_item">
          <div class="form_write">
            <p class="title">제조사</p>
            <p class="input">
              <input
                v-model="param.manufacturingCompany"
                type="text"
                placeholder="30자 이내로 작성하세요."
              />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">장비명</p>
            <p class="input">
              <input
                v-model="param.name"
                type="text"
                placeholder="30자 이내로 작성하세요."
              />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">장비명번호</p>
            <p class="input">
              <input
                v-model="param.carNo"
                type="text"
                placeholder="30자 이내로 작성하세요."
              />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">소유주</p>
            <p class="input">
              <input
                v-model="param.owner"
                type="text"
                placeholder="30자 이내로 작성하세요."
              />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">연락처</p>
            <p class="input">
              <input
                v-model="param.contactNo"
                type="text"
                placeholder="(-) 포함해서 작성하세요"
              />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">임대기간 (시작일/종료일)</p>
            <p class="input">
              <date-picker
                v-model="param.startDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
              />
              <date-picker
                v-model="param.endDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <button type="button" class="cancel" @click="close">취소</button>
        <button type="button" class="comfirm" @click="save">확인</button>
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import { mapGetters } from "vuex";
import EquipmentApi from "../../api/osm/EquipmentApi";

export default {
  name: "PopupCreateEquipment",
  components: { DatePicker },
  props: {},
  data: () => {
    return {
      isShow: false,
      param: {},
      day: [],
    };
  },
  computed: { ...mapGetters(["getLang"]) },
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    open(param) {
      console.log(param);
      this.param = param;
      this.isShow = true;
      console.log("createOpen");
    },
    close() {
      this.day = [];
      this.param = {};
      this.isShow = false;
    },
    async save() {
      await EquipmentApi.updateEquip(this.param.id, this.param);
      //   this.param.startDate = "";
      //   this.param.endDate = "";
      //   this.param.content = "";
      //   this.param.title = "";
      this.param = {};
      this.isShow = false;
    },
  },
};
</script>
<style>
.popup .form_wrap .form_write .title {
  width: 220px;
}
.v-toast {
  z-index: 10000000 !important;
}
</style>
