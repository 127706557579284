import { cocoHttpClient } from '../../network/httpclient/HttpClientFactory';

const list = params => cocoHttpClient.get(`/weather/list`, { params }).then(res => res.data);

const day = baseDate => cocoHttpClient.get(`/weather/${baseDate}`).then(res => res.data);
const air = baseDate => cocoHttpClient.get(`/air/${baseDate}`).then(res => res.data);
const specialReport = () => cocoHttpClient.get('/special-report').then(res => res.data);
const oceanInfo = () => cocoHttpClient.get('/ocean-info').then(res => res.data);
export default {
    day,
    air,
    list,
    specialReport,
    oceanInfo
};
