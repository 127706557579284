import { cocoHttpClient } from "../../network/httpclient/HttpClientFactory";
import { COMPANY_LIST } from "../../const";
const getSite = (siteId) =>
  cocoHttpClient.get(`/infra/site/${siteId}`).then((res) => res.data);
const equipList = (siteId) =>
  cocoHttpClient.get(`/infra/site/${siteId}/equips`).then((res) => res.data);
const getCompanyList = () => {
  return cocoHttpClient.get(`/infra/company/site`).then((res) => {
    localStorage.setItem(COMPANY_LIST, JSON.stringify(res.data.content));
    return res.data.content;
  });
};
const companyList = () => {
  if (localStorage.getItem(COMPANY_LIST)) {
    return new Promise(function (resolve) {
      resolve(JSON.parse(localStorage.getItem(COMPANY_LIST)));
    });
  } else {
    return getCompanyList().then(() =>
      JSON.parse(localStorage.getItem(COMPANY_LIST))
    );
  }
};
const updateTime = (params) =>
  cocoHttpClient.put(`/infra/site/time/`, params).then((res) => res.data);
const getCompanyListDetail = () =>
  cocoHttpClient.get(`/infra/company/site/detail`).then((res) => res.data);
const addCompany = (params) =>
  cocoHttpClient.post(`/infra/company/site`, params).then((res) => res.data);
const editCompany = (params, id) =>
  cocoHttpClient
    .put(`/infra/company/site/${id}`, params)
    .then((res) => res.data);
const updateSiteEquipUse = (params) =>
  cocoHttpClient.post(`/infra/site/equip`, params).then((res) => res.data);
const equipListAll = (siteId) =>
  cocoHttpClient
    .get(`/infra/site/${siteId}/equips/all`)
    .then((res) => res.data);
export default {
  getSite,
  companyList,
  updateTime,
  equipList,
  getCompanyListDetail,
  addCompany,
  editCompany,
  updateSiteEquipUse,
  equipListAll,
};
