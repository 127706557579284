<template>
  <div>
    <div class="choice_map">
      <button
        :class="currentBtn === 1 ? 'on' : ''"
        class="map_btn"
        @click="detail(1)"
      >
        위성
      </button>
      <button
        :class="currentBtn === 2 ? 'on' : ''"
        class="map_btn"
        @click="detail(2)"
      >
        일반
      </button>
    </div>
    <div class="choice_map second">
      <button
        v-for="(item, index) in bookmarks"
        :key="index"
        class="map_btn"
        @click="move(item)"
      >
        <span v-if="item.orderId !== 0">{{ item.orderId }}</span>
        {{ item.name }}
      </button>
    </div>
    <div class="choice_map third">
      <date-picker
        v-model="param.startDate"
        :lang="getLang"
        value-type="format"
        format="YYYY-MM-DD"
        type="date"
        @change="changeDate"
      />

      <button class="map_btn">운행장비</button>
      <button v-for="(item, index) in shipList" :key="index" class="map_btn">
        <!-- <span v-if="item.id !== 0">{{ item.id }}</span> -->

        {{ item.name }}
        <span> {{ item.distance }} / {{ item.totalTime }}</span>
      </button>
    </div>
    <popup-calendar ref="calendar" @calendarCallBack="calendarCallBack" />
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import CustomObjectApi from "../../api/osm/CustomObjectApi";
import PopupCalendar from "../popup/PopupCalendar.vue";
import EquipmentApi from "../../api/osm/EquipmentApi";
import { getToday } from "../../utils/date";
import { mapGetters } from "vuex";

export default {
  name: "ChoiceMap",
  components: { PopupCalendar, DatePicker },
  props: {
    deviceInfo: { type: Object },
  },
  data: () => {
    return {
      param: {
        startDate: getToday(),
      },
      currentBtn: 1,
      bookmarks: [],
      shipList: [],
      realTime: true,
      logType: "실시간",
    };
  },
  watch: {
    deviceInfo: {
      async handler(newVal) {
        console.log("newVal", newVal);
        const result = await EquipmentApi.equipList();
        this.shipList = result.content;
        this.shipList.forEach((element) => {
          if (newVal[element.id]) {
            element.totalTime = newVal[element.id].totalTime;
            element.distance =
              Math.round(newVal[element.id].distance * 10) / 10 + "Km";
          }
        });
      },
      immediate: true,
      deep: true,
    },
  },

  computed: { ...mapGetters(["getLang"]) },
  created() {},
  async mounted() {
    this.bookmarks = await CustomObjectApi.bookmarks();
    const result = await EquipmentApi.equipList();
    this.shipList = result.content;

    this.shipList.forEach((element) => {
      element.totalTime = this.deviceInfo[element.id].totalTime;
      element.distance =
        Math.round(this.deviceInfo[element.id].distance * 10) / 10 + "Km";
    });
  },
  beforeDestroy() {},
  methods: {
    detail(item) {
      if (item === 1) {
        if (this.currentBtn !== 1) {
          this.currentBtn = item;
          this.$emit("changeMapStyle", "satellite-v9");
        }
      } else {
        if (this.currentBtn !== 2) {
          this.currentBtn = item;
          this.$emit("changeMapStyle", "default");
        }
      }
    },
    move(item) {
      this.$emit("moveBookmarks", item);
    },
    changeDate() {
      console.log(this.param.startDate);
      this.$emit("changeDate", this.param.startDate);
    },
    openCalendar(item) {
      console.log("item", item);
      this.$refs.calendar.open(item);
    },
    calendarCallBack(param) {
      this.logType = param.logDay;
      this.$emit("calendarCallBack", param);
    },
    logRefresh() {
      this.logType = "실시간";
      this.$emit("refreshCallBack");
    },
  },
};
</script>
<style scoped>
.choice_map ::v-deep .mx-datepicker {
  margin-left: 5px !important;
  background-color: white !important;
  width: 150px !important;
}

/* Datepicker 입력 필드 배경 */
.choice_map ::v-deep .mx-input-wrapper {
  background-color: white !important;
}

.choice_map ::v-deep .mx-input {
  background-color: white !important;
}
.choice_map ::v-deep .mx-icon-calendar {
  color: black !important;
}
</style>
