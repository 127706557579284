import { httpClient } from "../../network/httpclient/HttpClientFactory";

const shipLogList = (params) =>
  httpClient.get(`/shiplog`, { params }).then((res) => res.data);

const getDetailShipLog = (id) =>
  httpClient.get(`/shiplog/${id}`).then((res) => res.data);

const deleteShipLog = (id) =>
  httpClient.delete(`/shiplog/${id}`).then((res) => res.data);
const saveShipLog = (param) =>
  httpClient.post(`/shiplog`, param).then((res) => res.data);
const updateShipLog = (param) =>
  httpClient.put(`/shiplog`, param).then((res) => res.data);

const statisticData = (id, date, endDate) =>
  httpClient.get(`/shiplog/${id}/${date}/${endDate}`).then((res) => res.data);

export default {
  shipLogList,
  deleteShipLog,
  getDetailShipLog,
  saveShipLog,
  updateShipLog,
  statisticData,
};
