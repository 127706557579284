<template>
  <div
    style="display: block; left: 70px"
    :style="{ display: isShow ? 'block' : 'none' }"
    class="calander_popup"
  >
    <div class="calander_header">
      <p class="header_title">{{ siteName }} 운행달력</p>
      <p class="header_today">{{ year }}. {{ month }} - {{ equipName }}</p>
      <nav class="navbar">
        <button class="button is-rounded prev" @click="movePrevMonth">
          <img alt="prev" src="/img/common/arrow_prev.svg" />
        </button>
        <span class="navbar--today">{{ year }}. {{ month }}</span>
        <button class="button is-rounded next" @click="moveNextMonth">
          <img alt="prev" src="/img/common/arrow_fowd.svg" />
        </button>
      </nav>

      <button @click="close" class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" />
      </button>
    </div>

    <div class="calander_content">
      <div id="app" />
    </div>

    <popup-modify-site
      :detail-item="detailItem"
      :is-show="detailIsShow"
      @detailCallback="detailCallback"
    />
    <popup-calendar-ship
      :is-show="detailShipLogIsShow"
      :detail-item="detailShipLog"
      @detailCallback="detailCallback"
    />
  </div>
</template>
<script>
import Calendar from "@toast-ui/calendar";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import { POPUP_NAME, SITE_NAME } from "../../const";
import { dayFormat } from "../../utils/date";
import DailyLogApi from "../../api/osm/DailyLogApi";
import WeatherApi from "../../api/coco/WeatherApi";
import PopupModifySite from "./PopupModifyHoliday.vue";

import CustomObjectApi from "../../api/osm/CustomObjectApi";
import PopupCalendarShip from "./PopupCalendarShip.vue";
export default {
  name: "PopupCalendar",
  components: { PopupModifySite, PopupCalendarShip },
  props: {
    currentPopup: {
      type: String,
      default: "",
    },
    isManager: {
      type: Boolean,
      required: false,
    },
  },
  data: () => {
    return {
      isShow: false,
      POPUP_NAME: POPUP_NAME,
      content: [],
      listParam: { page: 1, size: 10 },
      pageParam: [],
      weather: [],
      calendar: null,
      calendarId: null,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      detailItem: null,
      detailIsShow: false,
      detailShipLogIsShow: false,
      siteName: localStorage.getItem(SITE_NAME),
      equipMent: null,
      dayLogList: [],
      equipName: "",
      logDay: "",
      detailShipLog: null,
    };
  },
  computed: {},
  created() {
    this.weatherList();
    // this.list();
  },
  mounted() {
    // this.list();
  },
  beforeDestroy() {},
  methods: {
    open(item) {
      console.log("open");
      this.isShow = true;
      this.equipMent = item;
      console.log("equipMent", this.equipMent);
      //   this.init();
      // this.calendar = null;

      this.list();

      setTimeout(() => {
        const icons = document.querySelectorAll(".ship-icon");
        icons.forEach((icon) => {
          icon.addEventListener("click", (event) => {
            const date = event.target.getAttribute("data-date");
            this.handleIconClick(date);
          });
        });
        const buttons = document.querySelectorAll(".square-button");
        buttons.forEach((button) => {
          button.addEventListener("click", (event) => {
            const date = event.target.getAttribute("data-date-button");
            this.handleButtonClick(date);
          });
        });
      }, 500);
    },
    close() {
      this.listParam.title = null;
      this.isShow = false;
      // this.calendar = null;
      // this.calendar.clear();
    },
    detailCallback() {
      this.detailIsShow = false;
      this.detailShipLogIsShow = false;
      // this.list();
    },
    async list() {
      await this.setContent();
      if (this.calendar) {
        this.calendar.render();
      } else {
        var MOCK_CALENDARS = [
          {
            id: "1",
            name: "My Calendar",
            color: "#ffffff",
            borderColor: "#41321c",
            backgroundColor: "#886226",
            dragBackgroundColor: "#886226",
          },
          {
            id: "2",
            name: "Work",
            color: "#ffffff",
            borderColor: "#0c6356",
            backgroundColor: "#0f3230",
            dragBackgroundColor: "#0f3230",
          },
          {
            id: "3",
            name: "Family",
            color: "#ffffff",
            borderColor: "#53338b",
            backgroundColor: "#2c1f45",
            dragBackgroundColor: "#2c1f45",
          },
          {
            id: "4",
            name: "Friends",
            color: "#ffffff",
            borderColor: "##09588b",
            backgroundColor: "#0e2e45",
            dragBackgroundColor: "#0e2e45",
          },
          {
            id: "5",
            name: "Travel",
            color: "#ffffff",
            borderColor: "#62740c",
            backgroundColor: "#323912",
            dragBackgroundColor: "#323912",
          },
        ];
        const that = this;
        this.calendar = new Calendar("#app", {
          usageStatistics: false,
          defaultView: "month",
          calendars: MOCK_CALENDARS,
          useCreationPopup: false,
          useDetailPopup: false,
          isReadOnly: true,
          month: {
            startDayOfWeek: 1,
            isAlways6Weeks: false,
            visibleEventCount: 3,
          },
          template: {
            monthGridHeader(model) {
              let imgPath = "";
              //다른 달의 날짜는 나타내지 않음
              let date = new Date(model.date).getDate();
              if (model.isOtherMonth === true) {
                date = `<span style="color: grey;">${date}</span>`;
              }

              that.dayLogList.forEach((element) => {
                if (element === model.date) {
                  imgPath = `
                  <img class="ship-icon" style="cursor: pointer;" alt="prev" src="/img/sea/ship.png" data-date="${model.date}" />
                  <button class="square-button" data-date-button="${model.date}" >경로보기</button>
                  `;
                }
              });

              if (model.isToday === true) {
                return `<span id='cell-${dayFormat(
                  new Date(model.date)
                )}-today'>${date}  ${imgPath}  </span>`;
              } else {
                return `<span id='cell-${dayFormat(
                  new Date(model.date)
                )}'>${date}  ${imgPath}  </span>`;
              }
            },
          },
        });

        setTimeout(() => {
          console.log($(`#cell-${dayFormat(new Date())}`));
          $(`#cell-${dayFormat(new Date())}-today`)
            .parents(".toastui-calendar-daygrid-cell")
            .addClass("today");
          const icons = document.querySelectorAll(".ship-icon");
          icons.forEach((icon) => {
            icon.addEventListener("click", (event) => {
              const date = event.target.getAttribute("data-date");
              this.handleIconClick(date);
            });
          });
          const buttons = document.querySelectorAll(".square-button");
          buttons.forEach((button) => {
            button.addEventListener("click", (event) => {
              const date = event.target.getAttribute("data-date-button");
              this.handleButtonClick(date);
            });
          });
        }, 500);

        this.calendar.on("clickEvent", ({ event }) => {
          console.log(event);
          this.popupModify(event.id);
        });
      }
    },
    handleIconClick(date) {
      this.popupDetailLog(date);
      console.log("Ship icon clicked for date:", date);
      // 원하는 동작 추가
    },
    handleButtonClick(date) {
      // this.popupDetailLog(date);
      console.log("경로보기 clicked for date:", date);
      const param = {
        id: this.equipMent.id,
        name: this.equipMent.name,
        logDay: date,
      };
      this.$emit("calendarCallBack", param);
      this.close();
      // 원하는 동작 추가
    },
    weatherList() {
      WeatherApi.list({
        startDate: dayFormat(new Date(this.year, this.month - 1, 1)).replaceAll(
          "-",
          ""
        ),
        endDate: dayFormat(new Date(this.year, this.month, 0)).replaceAll(
          "-",
          ""
        ),
      }).then((result) => {
        this.weather = result;
        this.testItem = result;
      });
    },
    async setContent() {
      if (this.calendar) this.calendar.clear();
      this.content = [];
      this.dayLogList = [];
      this.equipName = this.equipMent.name;
      const today = new Date();
      const year = this.year;
      const month = String(this.month).padStart(2, "0");
      const day = String(1).padStart(2, "0");
      const lastDayOfMonth = new Date(year, this.month, 0).getDate();

      const startDay = `${year}-${month}-${day}`;
      const endDay = `${year}-${month}-${lastDayOfMonth}`;
      const historyList = await CustomObjectApi.historyDate(
        this.equipMent.id,
        startDay,
        endDay
      );
      for (let i = 0; i < historyList.length; i++) {
        const date = new Date(historyList[i][2]);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;
        if (!this.dayLogList.includes(formattedDate)) {
          this.dayLogList.push(formattedDate);
        }
      }
      console.log("dayLogList", this.dayLogList);
      console.log("period historyList", historyList);
      DailyLogApi.list(this.listParam).then((result) => {
        result.size = this.listParam.size;
        this.pageParam = result;
        // this.pageParam.content.forEach((element) => {
        //   if (element.labelClass === "orange") {
        //     this.calendarId = "1";
        //   } else if (element.labelClass === "green") {
        //     this.calendarId = "2";
        //   } else if (element.labelClass === "purple") {
        //     this.calendarId = "3";
        //   } else if (element.labelClass === "blue") {
        //     this.calendarId = "4";
        //   } else if (element.labelClass === "yellow") {
        //     this.calendarId = "5";
        //   }
        //   console.log(this.calendarId);
        //   //   this.content.push({
        //   //     id: element.id,
        //   //     calendarId: this.calendarId,
        //   //     title: element.title,
        //   //     start: element.startDate,
        //   //     end: element.endDate,
        //   //     category: "allday",
        //   //     isReadOnly: true,
        //   //   });
        // });

        this.calendar.createEvents(this.content);
        // this.calendar.render();
      });
    },
    movePrevMonth() {
      this.month = this.month - 1;
      this.calendar.move(-1);
      if (this.month === 0) {
        this.year = this.year - 1;
        this.month = 12;
      }
      //   this.weatherList();
      this.list();
      $(`.toastui-calendar-daygrid-cell.today`).removeClass("today");

      if ($(`#cell-${dayFormat(new Date())}-today`).length === 1) {
        $(`#cell-${dayFormat(new Date())}-today`)
          .parents(".toastui-calendar-daygrid-cell")
          .addClass("today");
      }

      setTimeout(() => {
        const icons = document.querySelectorAll(".ship-icon");
        icons.forEach((icon) => {
          icon.addEventListener("click", (event) => {
            const date = event.target.getAttribute("data-date");
            this.handleIconClick(date);
          });
        });
        const buttons = document.querySelectorAll(".square-button");
        buttons.forEach((button) => {
          button.addEventListener("click", (event) => {
            const date = event.target.getAttribute("data-date-button");
            this.handleButtonClick(date);
          });
        });
      }, 500);
    },
    moveNextMonth() {
      this.month = this.month + 1;
      this.calendar.move(+1);
      if (this.month > 12) {
        this.year = this.year + 1;
        this.month = 1;
      }
      //   this.weatherList();
      this.list();
      $(`.toastui-calendar-daygrid-cell.today`).removeClass("today");

      if ($(`#cell-${dayFormat(new Date())}-today`).length === 1) {
        $(`#cell-${dayFormat(new Date())}-today`)
          .parents(".toastui-calendar-daygrid-cell")
          .addClass("today");
      }

      setTimeout(() => {
        const icons = document.querySelectorAll(".ship-icon");
        icons.forEach((icon) => {
          icon.addEventListener("click", (event) => {
            const date = event.target.getAttribute("data-date");
            this.handleIconClick(date);
          });
        });
        const buttons = document.querySelectorAll(".square-button");
        buttons.forEach((button) => {
          button.addEventListener("click", (event) => {
            const date = event.target.getAttribute("data-date-button");
            this.handleButtonClick(date);
          });
        });
      }, 500);
    },
    popupModify(dailyLogId) {
      const targetItem = this.pageParam.content.find(
        (item) => item.id === dailyLogId
      );

      this.detailIsShow = true;
      this.detailItem = targetItem;
    },
    popupDetailLog(day) {
      this.detailShipLog = { id: this.equipMent.id, logDay: day };
      this.detailShipLogIsShow = true;
    },
    openPopup() {
      this.detailIsShow = true;
      const detailItem = {
        labelClass: "blue",
        startDate: "",
        endDate: "",
        content: "",
        title: "",
      };
      this.detailItem = detailItem;
    },
  },
};
</script>
<style>
/* .calander_popup {
  width: 100% !important;
} */
.calander_popup .calander_content {
  padding: 40px 30px 30px 30px;
  height: calc(100vh - 120px);
}
.icon-button-wrapper {
  display: flex;
  justify-content: space-between !important; /* 요소들을 좌우 끝에 배치 */
  align-items: center; /* 세로 정렬을 중앙으로 맞춤 */
  width: 14.2857%; /* 부모 요소의 너비를 100%로 설정 */
}
.ship-icon {
  margin-left: 10px; /* 왼쪽 여백 */
}
.square-button {
  /* float: right; */
  margin-left: 20px;
  margin-right: 10px;
  background: #0064ff;
  color: #fff;
  display: inline;
  align-items: center;
  justify-content: center;
  height: 3rem;
  border-radius: 0.4rem;
  font-size: 1.3rem;
  padding: 0 1rem;
}
</style>
