import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
    workerList: [],
    carList: [],
    workList: [],
    mapBounds: null,
    lang: {
        days: ['일', '월', '화', '수', '목', '금', '토'],
        months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        yearFormat: 'YYYY년',
        monthFormat: 'MM월'
    },
    startTime: [
        { id: 'AM7', time: '7' },
        { id: 'AM8', time: '8' },
        { id: 'AM9', time: '9' },
        { id: 'AM10', time: '10' }
    ],
    endTime: [
        { id: 'PM4', time: '16' },
        { id: 'PM5', time: '17' },
        { id: 'PM6', time: '18' },
        { id: 'PM7', time: '19' }
    ],
    commuteDay: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
    position: [
        { name: '사원', code: '002008' },
        { name: '대리', code: '002010' },
        { name: '과장', code: '002012' },
        { name: '팀장', code: '002014' },
        { name: '차장', code: '002015' },
        { name: '부장', code: '002016' },
        { name: '이사', code: '002017' }
    ]
};

export default {
    state,
    getters,
    actions,
    mutations
};
