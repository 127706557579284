import { httpClient } from "../../network/httpclient/HttpClientFactory";

const list = (params) =>
  httpClient.get(`/daily-log`, { params }).then((res) => res.data);
const save = (params) =>
  httpClient.post(`/daily-log`, params).then((res) => res.data);
const update = (id, params) =>
  httpClient.put(`/daily-log/${id}`, params).then((res) => res.data);
const remove = (id) =>
  httpClient.delete(`/daily-log/${id}`).then((res) => res.data);

export default {
  list,
  save,
  update,
  remove,
};
