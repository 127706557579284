import Vue from "vue";
import Router from "vue-router";
import BimProgressAdmin from "../components/BimProgressAdmin";
import BimProgressDashBoard from "../components/BimProgressDashBoard";
import Login from "../components/Login";
import SSO from "../components/SSO";
import A from "../components/place/A";
import B from "../components/place/B";
import C from "../components/place/C";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      name: "Main",
      alias: "/main",
      component: BimProgressAdmin,
    },
    {
      path: "/login",
      name: "Login",
      alias: "/login",
      component: Login,
    },
    {
      path: "/bim/:id",
      name: "BimProgressAdmin",
      component: BimProgressAdmin,
    },
    {
      path: "/sso/:token",
      name: "SSO",
      component: SSO,
    },
    {
      path: "/dashboard/:id",
      name: "BimProgressDashBoard",
      component: BimProgressDashBoard,
    },
    {
      path: "/a",
      name: "A",
      component: A,
    },
    {
      path: "/b",
      name: "B",
      component: B,
    },
    {
      path: "/c",
      name: "C",
      component: C,
    },
  ],
});

export default router;
