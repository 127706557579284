<template>
  <div v-show="isShow" class="dash_admin_box dash_admin_01">
    <div class="dash_admin_box_head">
      <p class="admin_box_title">현장 기본 정보</p>
      <div class="admin_head_button">
        <button type="button" class="type_blue" @click="save">저장</button>
      </div>
    </div>
    <div class="dash_admin_form">
      <div class="form_item">
        <div class="form_write">
          <p class="title">현장명</p>
          <p class="input">
            <input
              v-model="param.name"
              type="text"
              placeholder="20자 이내로 작성하세요."
            />
          </p>
          <p class="info_text">· 상단 타이틀에 표시될 현장명을 입력하세요.</p>
        </div>
      </div>
      <div class="form_item">
        <div class="form_write">
          <p class="title">공사기간</p>
          <p class="info_text">{{ param.startDate }} ~ {{ param.endDate }}</p>
        </div>
      </div>
      <div class="form_item">
        <div class="form_write">
          <p class="title">무재해 달성</p>
          <p class="input_calendar">
            <date-picker
              v-model="param.accidentDate"
              :lang="getLang"
              value-type="format"
              format="YYYY-MM-DD"
              type="date"
            />
          </p>
          <p class="info_text">· 무재해 달성 시작일을 지정하세요.</p>
        </div>
      </div>
      <div class="form_item">
        <div class="form_write">
          <p class="title">공정률(%)</p>
          <p class="input">
            <input
              v-model="param.progressRate"
              type="text"
              placeholder="20자 이내로 작성하세요."
            />
          </p>
          <p class="info_text">
            · 공정률을 백분율 기준 숫자(소숫점 1자리)로만 입력하세요.
          </p>
        </div>
      </div>
    </div>
    <br />
    <br />

    <div class="dash_admin_box_head">
      <p class="admin_box_title">현장 업무 시간</p>
      <div class="admin_head_button">
        <button type="button" class="type_blue" @click="saveWorkTime">
          저장
        </button>
      </div>
    </div>
    <div class="dash_admin_form">
      <div class="form_item">
        <div class="form_write">
          <p class="title_sub">출근 시간</p>

          <select v-model="siteParam.startTime" class="option">
            <option :value="null">선택</option>
            <option
              v-for="(item, index) in getStartTime"
              :key="index"
              :value="item.time"
            >
              {{ item.id }}
            </option>
          </select>

          <p class="title_sub">퇴근 시간</p>

          <select v-model="siteParam.endTime" class="option">
            <option :value="null">선택</option>
            <option
              v-for="(item, index) in getEndTime"
              :key="index"
              :value="item.time"
            >
              {{ item.id }}
            </option>
          </select>

          <p class="title_sub">출근 요일</p>
          <label
            v-for="(item, index) in getCommuteDay"
            :key="index"
            class="custom_checkbox"
            :for="item"
          >
            <input :id="item" v-model="workDay" :value="item" type="checkbox" />
            <span>{{ item }} &nbsp </span>
          </label>
        </div>
      </div>
    </div>
    <br />
    <br />
    <!-- <div class="dash_admin_box_head">
      <p class="admin_box_title">현장 운영 장비</p>
      <div class="admin_head_button">
        <button type="button" class="type_blue" @click="saveEquip">저장</button>
      </div>
    </div>
    <div class="dash_admin_form">
      <div class="form_item">
        <div class="form_write">
          <p class="title_sub">장비</p>
          <label
            v-for="(item, index) in equipAllList"
            :key="index"
            class="custom_checkbox"
            :for="item"
          >
            <input
              :id="item"
              v-model="equipList[index].isUse"
              type="checkbox"
              :checked="item.isUse"
            />
            <span>{{ item.name }} &nbsp </span>
          </label>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import InfraApi from "../../api/coco/InfraApi";
import SiteCommonApi from "../../api/osm/SiteCommonApi";
import { SITE_NAME } from "../../const";
import { mapGetters } from "vuex";
export default {
  name: "DashAdmin01",
  components: { DatePicker },
  props: {
    tabId: {
      type: Number,
      default: 1,
    },
  },
  data: () => {
    return {
      isShow: false,
      param: {
        name: "",
        progressRate: 0,
        accidentDate: "",
      },
      workDay: [],
      siteParam: {
        startTime: "",
        endTime: "",
        workDay: "",
      },
      equipAllList: [],
      equipList: [],
    };
  },
  computed: {
    ...mapGetters(["getStartTime", "getEndTime", "getCommuteDay", "getLang"]),
  },
  watch: {
    tabId: function () {
      if (this.tabId === 1) {
        this.detail();
        this.workTimeInfo();
        this.getEquip();
      }
    },
  },
  created() {},
  mounted() {
    this.workTimeInfo();
    this.detail();
    this.getEquip();
  },
  beforeDestroy() {},
  methods: {
    open() {
      console.log("open");
      // this.init();
      this.isShow = true;
    },
    close() {
      // this.listParam.title = null;
      this.isShow = false;
    },
    save() {
      SiteCommonApi.save(this.param).then(() => {
        localStorage.setItem(SITE_NAME, this.param.name);
        this.$toast.success("저장 되었습니다.");
        location.reload();
      });
    },
    detail() {
      SiteCommonApi.detail().then((res) => {
        this.param = res;
      });
    },
    workTimeInfo() {
      const id = localStorage.getItem("siteId");
      InfraApi.getSite(id).then((res) => {
        this.siteParam.startTime = res.content.startTime;
        this.siteParam.endTime = res.content.endTime;
        this.siteParam.workDay = res.content.workDay;
        this.workDay = res.content.workDay.split(",");
      });
    },
    getEquip() {
      InfraApi.equipListAll(localStorage.getItem("siteId")).then((res) => {
        this.equipAllList = res.content;
        this.equipList = res.content;
        console.log(this.equipAllList);
      });
    },
    saveWorkTime() {
      this.siteParam.workDay = this.workDay.join();
      InfraApi.updateTime(this.siteParam).then(() => {
        this.workTimeInfo();
        this.$toast.success("저장 되었습니다.");
      });
    },
    saveEquip() {
      InfraApi.updateSiteEquipUse(this.equipList).then(() => {
        this.getEquip();
        this.$toast.success("저장 되었습니다.");
      });
      console.log(this.equipList);
    },
  },
};
</script>
