const getLang = state => {
    return state.lang;
};
const getStartTime = state => {
    return state.startTime;
};
const getEndTime = state => {
    return state.endTime;
};
const getCommuteDay = state => {
    return state.commuteDay;
};
const getPosition = state => {
    return state.position;
};
export default { getLang, getStartTime, getEndTime, getCommuteDay, getPosition };
