import { mskName } from "../utils/string";
import { LABEL_COLOR } from "../const";

export const cstrLine = (lineList, site, map, mapboxgl) => {
  const fDiv = document.createElement("div");
  fDiv.className = "cstr-line-top";
  const sDiv = document.createElement("div");
  sDiv.className = "cstr-line-bottom";

  const textDivP2 = document.createElement("p");

  const textDivPSpan = document.createElement("span");
  textDivPSpan.textContent = site.name;
  textDivPSpan.className =
    "font-family:'Noto Sans CJK KR Medium', 'Noto Sans CJK KR Regular', 'Noto Sans CJK KR', sans-serif;font-weight:500;";
  textDivP2.append(textDivPSpan);
  const textDivPSpan2 = document.createElement("span");
  textDivPSpan2.textContent = site.infoText;
  textDivPSpan2.className =
    "font-family:'Open Sans Semibold', 'Open Sans Regular', 'Open Sans', sans-serif;font-weight:600;";
  textDivP2.append(textDivPSpan2);

  sDiv.append(textDivP2);
  const mainDiv = document.createElement("div");
  mainDiv.className = "cstr-line-main";
  mainDiv.append(fDiv);
  mainDiv.append(sDiv);

  new mapboxgl.Marker(mainDiv)
    .setLngLat([site.titleLng, site.titleLat])
    .setPitchAlignment("map")
    .addTo(map);

  const fDivS = document.createElement("div");
  fDivS.className = "cstr-line-s-top";
  const sDivS = document.createElement("div");
  sDivS.className = "cstr-line-s-bottom";

  const lineFeatures = lineList.map((item) => {
    return {
      type: "Feature",
      properties: {
        color: "#0b00bf",
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [item.beginLng, item.beginLat],
          [item.endLng, item.endLat],
        ],
      },
    };
  });
  map.addSource("lines", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: lineFeatures,
    },
  });
  map.addLayer({
    id: "lines",
    type: "line",
    source: "lines",
    paint: {
      "line-width": 2,
      "line-color": ["get", "color"],
    },
  });
};

export const officePolygon = (site, map, mapboxgl) => {
  const fDiv = document.createElement("div");
  fDiv.className = "office-line";
  const sDiv = document.createElement("div");
  sDiv.className = "office-label";

  const textDivP2 = document.createElement("p");

  const numberSpan = document.createElement("span");
  numberSpan.textContent = 1;
  numberSpan.className = "num";

  const textDivPSpan2 = document.createElement("span");
  textDivPSpan2.textContent = "현장사무소";
  textDivP2.append(numberSpan);
  textDivP2.append(textDivPSpan2);
  sDiv.append(textDivP2);
  const mainDiv = document.createElement("div");
  mainDiv.append(fDiv);
  mainDiv.append(sDiv);

  new mapboxgl.Marker(mainDiv)
    .setLngLat([site.officeLng, site.officeLat])
    .setPitchAlignment("map")
    .addTo(map);
};

export const tunnelPanel = (item, map, mapboxgl) => {
  const fDiv = document.createElement("div");
  fDiv.className = "tunnel-top";
  const imgEl = document.createElement("img");
  imgEl.src = "../img/common/axshare/p_tunnel_top.svg";
  fDiv.append(imgEl);

  const textDiv = document.createElement("div");
  textDiv.className = "text detail";

  const textDivP = document.createElement("p");

  const numberSpan = document.createElement("span");
  numberSpan.textContent = item.orderId;
  numberSpan.className = "num";
  const textDivPSpan = document.createElement("span");
  textDivPSpan.textContent = item.name;
  if (item.orderId && item.orderId !== 0) textDivP.append(numberSpan);
  textDivP.append(textDivPSpan);
  textDiv.append(textDivP);
  fDiv.append(textDiv);

  //

  const sDiv = document.createElement("div");
  sDiv.className = "tunnel-bottom";

  const textDiv2 = document.createElement("div");
  textDiv2.className = "text detail";

  const backDiv = document.createElement("div");
  backDiv.className = "inner-div";

  const textDivP2 = document.createElement("p");

  const textDivPSpan2 = document.createElement("span");
  textDivPSpan2.textContent = item.info;

  textDivP2.append(textDivPSpan2);
  textDiv2.append(textDivP2);
  sDiv.append(backDiv);
  sDiv.append(textDiv2);

  const mainDiv = document.createElement("div");
  mainDiv.append(fDiv);
  mainDiv.append(sDiv);

  new mapboxgl.Marker(mainDiv)
    .setLngLat(item.lnglat)
    .setPitchAlignment("map")
    .addTo(map);
};
export const bridgePanel = (item, map, mapboxgl) => {
  const fDiv = document.createElement("div");
  fDiv.className = "bridge-bottom";
  const imgEl = document.createElement("img");
  imgEl.src = "../img/common/axshare/p_bridge_bottom.svg";
  fDiv.append(imgEl);

  const textDiv = document.createElement("div");
  textDiv.className = "text detail";

  const textDivP = document.createElement("p");

  const textDivPSpan = document.createElement("span");
  textDivPSpan.textContent = item.info;
  textDivP.append(textDivPSpan);
  textDiv.append(textDivP);
  fDiv.append(textDiv);

  //

  const sDiv = document.createElement("div");
  sDiv.className = "bridge-top";
  const imgEl2 = document.createElement("img");
  imgEl2.src = "../img/common/axshare/p_bridge_top.svg";
  sDiv.append(imgEl2);

  const textDiv2 = document.createElement("div");
  textDiv2.className = "text detail";

  const textDivP2 = document.createElement("p");

  const numberSpan = document.createElement("span");
  numberSpan.textContent = item.orderId;
  numberSpan.className = "num";
  const textDivPSpan2 = document.createElement("span");
  textDivPSpan2.textContent = item.name;
  if (item.orderId && item.orderId !== 0) textDiv2.append(numberSpan);
  textDivP2.append(textDivPSpan2);
  textDiv2.append(textDivP2);
  sDiv.append(textDiv2);

  const mainDiv = document.createElement("div");
  mainDiv.append(sDiv);
  mainDiv.append(fDiv);

  new mapboxgl.Marker(mainDiv)
    .setLngLat(item.lnglat)
    .setPitchAlignment("map")
    .addTo(map);
};

export const setGisInfo = (map, gisInfoList) => {
  gisInfoList.map((item) => {
    map.addSource(
      item.srcName,
      {
        type: "raster",
        tiles: [
          `https://gis.andthebee.net/geoserver/cite/wms?FORMAT=image/png8&SERVICE=WMS&version=1.1.1&request=GetMap&srs=EPSG:5186&transparent=true&width=512&height=512&layers=cite:${item.srcName}&SRS=EPSG:3857&BBOX={bbox-epsg-3857}`,
        ],
      },
      "custom_layer"
    );
    map.addLayer(
      {
        id: item.srcName,
        source: item.srcName,
        type: "raster",
        "source-layer": `cite:${item.srcName}`,
      },
      "custom_layer"
    );
  });
};

export const generateDriverTypeIconElement = (value, workerList, carList) => {
  const el = document.createElement("div");
  const nameEl = document.createElement("span");
  const imgEl = document.createElement("img");
  el.className = "pin-div";
  imgEl.className = "pin-img";
  let imgSrc = "../img/common/map/";

  if (value.driverType) {
    const car = carList.find((item) => item.id === value.id.memberId);
    if (car) {
      if (car.carNo) {
        nameEl.textContent = car.carNo;
        if (car.company === "(주)큐텍이씨에스") nameEl.className = "comp2";
      } else {
        nameEl.textContent = "작업차량";
      }
    } else {
      return false;
    }

    el.className = "marker type_equip pin-div car";
    switch (value.driverType) {
      case "ROLLER":
        imgSrc += "pin_roller";
        break;
      case "FORK_LIFT":
        imgSrc += "pin_folklift";
        break;
      case "BULLDOZER":
        imgSrc += "pin_bulldozer";
        break;
      case "FORK_CRANE":
        imgSrc += "pin_excavator";
        break;
      case "ETC":
        imgSrc += "pin_equip";
        break;
      case "BOAT":
      case "BARGE":
        imgSrc += "pin_ship";
        break;
      default:
        imgSrc += "pin_dumpt";
        break;
    }
  } else {
    const worker = workerList.find((item) => item.id === value.id.memberId);
    if (!worker) return false;
    if (worker.authType) {
      value.authType = worker.authType;
    }
    if (value.authType === "MANAGER" || value.authType === "ADMIN") {
      imgSrc = "../img/common/map/pin_manager";
    } else imgSrc = "../img/common/map/pin_worker";

    el.className = "marker type_worker pin-div pp";
    if (worker.company === "(주)큐텍이씨에스") {
      nameEl.className = "comp2";
    } else if (worker.company === "대우건설") {
      nameEl.className = "comp3";
    }
    nameEl.textContent = mskName(worker.name);
  }

  let isGetOff = false;
  let isMissing = false;
  //하차상태
  if (value.isBleUse & value.disconnect) {
    isGetOff = true;
  }
  var today = new Date();
  var missingTime = new Date(Date.parse(today) - 1000 * 60 * 60 * 3); //3시간 전
  var accessTime = new Date(value.id.createdAt);

  //GPS수집불가상태
  if (missingTime.getTime() > accessTime) {
    isMissing = true;
  }

  el.append(nameEl);
  imgEl.src = imgSrc + atcPinSrc(isGetOff, isMissing);
  el.append(imgEl);

  return el;
};

export const generate3dIconElement = (type) => {
  const el = document.createElement("div");
  const nameEl = document.createElement("span");
  const imgEl = document.createElement("img");
  el.className = "pin-div";
  imgEl.className = "pin-img";
  el.append(nameEl);
  imgEl.style = "top:12rem;right:25.2rem";
  imgEl.src = "../img/common/map/pin_3d.svg";
  el.append(imgEl);
  el.style.cursor = "pointer";
  el.addEventListener("click", () => {
    var win;
    win = window.open(`/#/${type}`, "_blank");
    win.focus();
  });

  return el;
};

export const generatShipIconElement = (id) => {
  const el = document.createElement("div");
  const nameEl = document.createElement("span");
  const imgEl = document.createElement("img");
  nameEl.textContent = "바지선1호";
  el.className = "pin-div";
  imgEl.className = "pin-img";
  el.append(nameEl);
  imgEl.style = "margin-bottom: 55px;padding-left: 10px;";
  imgEl.src = "../img/common/map/pin_ship_on.svg";
  imgEl.setAttribute("data-id", id);
  el.append(imgEl);

  return el;
};

const atcPinSrc = (isGetOff, isMissing) => {
  if (isMissing) {
    return "_gpsoff.svg";
  } else if (isGetOff) {
    // return '_ppoff.svg'; //일단 하차상태 표시 안함
    return ".svg";
  } else {
    return ".svg";
  }
};
