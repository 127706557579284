<template>
  <body class="login_container">
    <div class="login_wrap">
      <div class="login_form">
        <div class="login_logo">
          <p class="logo">
            <img
              src="/img/common/login_title.svg"
              alt="on site monitoring login"
            />
          </p>
        </div>
        <div class="login_info">
          <p class="login_item">
            <input v-model="login.loginId" type="text" placeholder="아이디" />
          </p>
          <p class="login_item">
            <input
              v-model="login.passwd"
              type="password"
              placeholder="비밀번호"
            />
          </p>
          <div class="login_option">
            <label class="custom_checkbox">
              <input v-model="login.isAuto" type="checkbox" />
              <span>로그인 상태 유지</span>
            </label>
          </div>
          <p class="submit_btn">
            <button @click="processLogin">로그인</button>
          </p>
        </div>
      </div>
      <div class="login_footer">
        <p>OSM is technically supported by AndTheBee Com.</p>
      </div>
    </div>
  </body>
</template>
<script>
import UserApi from "../api/coco/UserApi";
import SiteCommonApi from "../api/osm/SiteCommonApi";
import {
  JWT_TOKEN,
  JWT_REFRESH_TOKEN,
  USER_ID,
  AUTH_TYPE,
  SITE_NAME,
  COMPANY_NAME,
  HEADER_DEVICE_ID,
  SITE_ID,
} from "../const";
export default {
  name: "Main",
  components: {},
  data: () => {
    return {
      login: {
        isAuto: true,
        loginId: "",
        passwd: "",
        systemType: "OSM",
      },
    };
  },
  computed: {},
  created() {},
  mounted() {
    var interval_id = window.setInterval(() => {}, 99999);
    for (var i = 0; i < interval_id; i++) window.clearInterval(i);
  },
  beforeDestroy() {},
  methods: {
    async processLogin() {
      if (!this.login.loginId) {
        this.$toast.error("휴대전화번호를 입력하세요.");
        this.login.loginId.focus();
        return;
      }

      if (!this.login.passwd) {
        this.$toast.error("비밀번호를 입력하세요.");
        this.login.passwd.focus();
        return;
      }
      localStorage.clear();
      UserApi.login(this.login, {
        [HEADER_DEVICE_ID]: navigator.userAgent,
      }).then(
        async (value) => {
          if (value) {
            localStorage.setItem(JWT_TOKEN, value.content.token.accessToken);
            localStorage.setItem(USER_ID, value.content.memberId);
            localStorage.setItem(COMPANY_NAME, value.content.companyName);
            localStorage.setItem(AUTH_TYPE, value.content.authType);
            localStorage.setItem(SITE_ID, value.content.siteId);

            const site = await SiteCommonApi.detail();

            localStorage.setItem(SITE_NAME, site.name);

            if (this.login.isAuto)
              localStorage.setItem(
                JWT_REFRESH_TOKEN,
                value.content.token.refreshToken
              ); //로그인상태유지 하는경우만 reftoken 저장

            if (value.content.authType === "MANAGER") {
              this.$router.replace({ path: `/manage` });
              return;
            }
            if (value.content.authType === "ADMIN") {
              this.$router.replace({ path: `/main` });
              return;
            }
            this.$toast.error("로그인 권한이 없습니다.");
          }
        },
        () => {
          this.$toast.error("가입하지 않았거나 잘못된 비밀번호입니다.");
        }
      );
    },
  },
};
</script>
