<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    style="min-width: 1100px"
    class="popup modify_site"
  >
    <div class="popup_header">
      <div class="header_title">운항 일지 등록</div>
      <button @click="close" class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" />
      </button>
    </div>
    <div class="popup_content">
      <div class="form_wrap">
        <div class="form_item">
          <div class="form_write">
            <p class="title">운항일자</p>
            <p class="input">
              <date-picker
                v-model="param.operationDay"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
              />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">선박명</p>
            <p class="input">
              <input v-model="param.equipmentName" type="text" readonly />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">첨부파일</p>
            <p v-if="param.fileId" class="input">
              <a
                v-for="(file, index) in fileList"
                :key="index"
                @click="onClickDownload(file)"
              >
                <span
                  class="files"
                  @click="downloadFile(file.id)"
                  style="color: white"
                  >{{ file.ogName }}</span
                >
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="dash_admin_box_table" style="height: 100%">
        <table :key="tableKey">
          <thead>
            <tr>
              <th colspan="2" width="40">운항시간</th>
              <th rowspan="2" width="50">출발지</th>
              <th rowspan="2" width="40">목적지</th>
              <th rowspan="2" width="40">비고</th>
            </tr>
            <tr>
              <th width="40">시작</th>
              <th width="40">종료</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in logList" :key="index">
              <td>
                <p class="input">
                  <input v-model="item.startTime" type="time" readonly />
                </p>
              </td>
              <td>
                <p class="input">
                  <input v-model="item.endTime" type="time" readonly />
                </p>
              </td>
              <td>
                <p class="input">
                  <input v-model="item.departure" type="text" readonly />
                </p>
              </td>
              <td>
                <p class="input">
                  <input v-model="item.destination" type="text" readonly />
                </p>
              </td>
              <td>
                <p class="input">
                  <input v-model="item.remark" type="text" readonly />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <button type="button" class="comfirm" @click="save">확인</button>
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import { mapGetters } from "vuex";
import ShipLogBookApi from "../../api/osm/ShipLogBookApi";
import FileApi from "../../api/coco/FileApi";
export default {
  name: "PopupCreateEquipment",
  components: { DatePicker },
  props: {},
  data: () => {
    return {
      isShow: false,
      param: {},
      day: [],
      logList: [],
      tableKey: 0,
      fileList: [],
    };
  },
  computed: { ...mapGetters(["getLang"]) },
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    open(param) {
      this.param = param;
      if (param.fileId) {
        FileApi.list(this.param.fileId).then((result) => {
          this.fileList = result;
        });
      }
      this.getDetailLog(param.id);
      console.log(param);
      this.isShow = true;
      console.log("createOpen");
    },
    async onClickDownload(file) {
      FileApi.download(file.fileId, file.fileNo);
    },
    close() {
      this.day = [];
      this.logList = [1];
      this.isShow = false;
    },
    addList() {
      this.logList.push(3);
      this.tableKey += 1;
      console.log(this.logList);
    },
    async getDetailLog(id) {
      const result = await ShipLogBookApi.getDetailShipLog(id);
      this.logList = result;
      console.log(result);
    },
    async save() {
      //   this.param.startDate = "";
      //   this.param.endDate = "";
      //   this.param.content = "";
      //   this.param.title = "";
      this.logList = [1];
      this.isShow = false;
    },
  },
};
</script>
<style>
.popup .form_wrap .form_write .title {
  width: 220px;
}
.v-toast {
  z-index: 10000000 !important;
}
.input input[type="text"] {
  width: 100%;
  height: 35px;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  letter-spacing: -0.2px;
  border: 1px solid #282c32;
  background-color: #000000;
  font-size: 1.6rem;
  text-indent: 10px;
}
.input input[type="time"] {
  background-color: #000000;
}
</style>
