import { cocoHttpClient, excelHttpClient } from '../../network/httpclient/HttpClientFactory';

const start = param => cocoHttpClient.post(`/commute`, param).then(res => res.data);
const today = () => cocoHttpClient.get(`/commute/osm/today`).then(res => res.data);
const detailIds = params => cocoHttpClient.get(`/commute/osm/commute-list`, { params }).then(res => res.data);
const bookList = params => cocoHttpClient.get(`/commute/osm/book-list`, { params }).then(res => res.data);
const dailyStats = params => cocoHttpClient.get(`/commute/osm/stats/daily`, { params }).then(res => res.data);
const confirm = params => cocoHttpClient.post(`/commute/osm/confirm`, null, { params }).then(res => res.data);
const cancel = params => cocoHttpClient.post(`/commute/osm/cancel`, null, { params }).then(res => res.data);
const dailyCount = params => cocoHttpClient.get(`/commute/osm/stats/count`, { params }).then(res => res.data);

const excelDown = (url, params) =>
    excelHttpClient.get(url, { params }).then(res => {
        var disposition = res.headers['content-disposition'];
        var fileName = decodeURI(disposition.substring(disposition.indexOf('filename=') + 9, disposition.length));

        const blob = new Blob([res.data], { type: res.headers['content-type'] });

        var fileUrl = window.URL.createObjectURL(blob);
        var fileLink = document.createElement('a');

        fileLink.href = fileUrl;
        fileLink.download = fileName;
        document.body.appendChild(fileLink);

        fileLink.click();
        fileLink.remove();
    });

const modify = param => cocoHttpClient.put(`/commute/by-manager`, param).then(res => res.data);

const sumCount = async (companyId, beginDate, endDate) => {
    const param = {
        beginDate,
        endDate
    };
    if (companyId) param.companyId = companyId;
    const response = await cocoHttpClient.get(`/commute/company`, { params: param });
    return response.data.content;
};

const companyList = async (companyId, beginDate, endDate) => {
    const param = {
        beginDate,
        endDate
    };
    if (companyId) param.companyId = companyId;
    const response = await cocoHttpClient.get(`/commute/company-list`, { params: param });
    return response.data.content;
};

const totalSummary = rangeDate =>
    cocoHttpClient.get(`/commute/total-summary/${rangeDate[0]}/${rangeDate[1]}`).then(res => res.data);

const editWorkType = (param, id) => cocoHttpClient.put(`/commute/work-type/${id}`, param).then(res => res.data);
const dailyStatsCount = params => cocoHttpClient.get(`/commute/osm/stats/count`, { params }).then(res => res.data);

export default {
    start,
    today,
    detailIds,
    bookList,
    dailyStats,
    excelDown,
    modify,
    sumCount,
    companyList,
    totalSummary,
    confirm,
    cancel,
    editWorkType,
    dailyCount,
    dailyStatsCount
};
