<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    class="popup modify_site"
  >
    <div class="popup_header">
      <div class="header_title">유류 공급서 등록</div>
      <button @click="close" class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" />
      </button>
    </div>
    <div class="popup_content">
      <div class="form_wrap">
        <div class="form_item">
          <div class="form_write">
            <p class="title">공급일</p>
            <p class="input">
              <date-picker
                v-model="param.startDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
              />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">선박명</p>
            <p class="input">
              <select name="" id="">
                <option value="">혜성호</option>
                <option value="">혜승2</option>
              </select>
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">첨부파일</p>
            <p v-if="param.fileId" class="input">
              <a
                v-for="(file, index) in fileList"
                :key="index"
                @click="onClickDownload(file)"
              >
                <span
                  class="files"
                  @click="downloadFile(file.id)"
                  style="color: white"
                  >{{ file.ogName }}</span
                >
              </a>
              <a v-show="!param.isReadOnly" @click="removeFile">
                <span class="file-del">삭제</span>
              </a>
            </p>
            <p class="filebox">
              <input
                id="actionDocFile"
                ref="actionDocFile"
                type="file"
                class="blind"
              />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">유종</p>
            <p class="input">
              <input value="0.001MGO" type="text" />
            </p>
            <p class="title" style="padding-left: 20px">주유량</p>
            <p class="input">
              <input value="2000L" type="text" />
            </p>
          </div>
        </div>

        <div class="form_item">
          <div class="form_write">
            <p class="title">단가</p>
            <p class="input">
              <input value="1,426" type="text" />
            </p>
            <p class="title" style="padding-left: 20px">유류대금</p>
            <p class="input">
              <input value="2,593,055" type="text" />
            </p>
          </div>
        </div>

        <div class="form_item">
          <div class="form_write">
            <p class="title">수수료</p>
            <p class="input">
              <input value="24,200" type="text" />
            </p>
            <p class="title" style="padding-left: 20px">용역비</p>
            <p class="input">
              <input value="90,000" type="text" />
            </p>
          </div>
        </div>

        <div class="form_item">
          <div class="form_write">
            <p class="title" style="width: 80px">합계</p>
            <p class="input">
              <input value="2,707,255" type="text" />
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <button type="button" class="cancel" @click="close">취소</button>
        <button type="button" class="comfirm" @click="save">확인</button>
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import { mapGetters } from "vuex";
import EquipmentApi from "../../api/osm/EquipmentApi";

export default {
  name: "PopupCreateEquipment",
  components: { DatePicker },
  props: {},
  data: () => {
    return {
      isShow: false,
      param: {
        startDate: "2024-07-11",
      },
      day: [],
    };
  },
  computed: { ...mapGetters(["getLang"]) },
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    open() {
      this.isShow = true;
      console.log("createOpen");
    },
    close() {
      this.day = [];
      this.param = {};
      this.isShow = false;
    },
    async save() {
      if (!this.param.manufacturingCompany) {
        this.$toast.error("제조사를 입력해주세요.");
        return;
      }
      if (!this.param.name) {
        this.$toast.error("장비명을 입력해주세요.");
        return;
      }
      if (!this.param.carNo) {
        this.$toast.error("장비번호를 입력해주세요.");
        return;
      }
      await EquipmentApi.saveEquip(this.param);
      //   this.param.startDate = "";
      //   this.param.endDate = "";
      //   this.param.content = "";
      //   this.param.title = "";
      this.param = {};
      this.isShow = false;
    },
  },
};
</script>
<style>
.popup .form_wrap .form_write .title {
  width: 220px;
}
.v-toast {
  z-index: 10000000 !important;
}
</style>
