<template>
  <div v-show="isShow" class="dash_admin_box dash_admin_03">
    <div class="dash_admin_box_head">
      <p class="admin_box_title">현장 공지사항</p>
      <div class="admin_head_button">
        <button type="button" class="type_blue" @click="regist">
          공지 등록
        </button>
      </div>
    </div>
    <div class="dash_admin_box_table">
      <table>
        <thead>
          <tr>
            <th>순번</th>
            <th>공지제목</th>
            <th>공지내용</th>
            <th>등록자</th>
            <th>등록일</th>
            <th>수정</th>
            <th>삭제</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="pageParam.total === 0">
            <td colspan="7">검색 결과가 없습니다.</td>
          </tr>
          <template v-if="pageParam.total !== 0">
            <tr v-for="(item, index) in pageParam.content" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.title }}</td>
              <td>
                {{ contentSubStr(item.content) }}
              </td>
              <td>{{ item.createdBy.name }}</td>
              <td>{{ item.createdAt }}</td>
              <td>
                <button type="button" class="btn_mody" @click="update(item)">
                  수정
                </button>
              </td>
              <td>
                <button type="button" class="btn_add" @click="remove(item)">
                  삭제
                </button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <pagination
      :page-param="pageParam"
      :list-cnt="listParam.size"
      @pageCallback="pageCallback"
    />
    <popup-modify-notice
      :detail-item="detailItem"
      :is-show="isShowPopup"
      :is-update="isUpdate"
      :is-new="isNew"
      @popupCallback="popupCallback"
    />
    <confirm ref="confirm" @confirmCallback="confirmCallback" />
  </div>
</template>
<script>
import Pagination from "../../components/Pagination.vue";
import BoardApi from "../../api/coco/BoardApi";
import PopupModifyNotice from "../popup/PopupModifyNotice.vue";
import Confirm from "../../components/popup/Confirm.vue";
export default {
  name: "DashAdmin03",
  components: { Pagination, Confirm, PopupModifyNotice },
  props: {
    tabId: {
      type: Number,
      default: 1,
    },
  },
  data: () => {
    return {
      noticeId: null,
      pageParam: { content: [] },
      listParam: { page: 1, size: 10, type: "NOTICE" },
      isShow: false,
      isShowPopup: false,
      detailItem: null,
      confirmParam: {
        title: "",
        msg: "",
        isShow: false,
      },
      isUpdate: false,
      isNew: false,
    };
  },
  computed: {},
  watch: {
    tabId: function () {
      if (this.tabId === 3) {
        this.list();
      }
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    open() {
      console.log("open");
      this.list();
      //   this.init();
      this.isShow = true;
    },
    close() {
      //   this.listParam.title = null;
      this.isShow = false;
    },
    list() {
      BoardApi.list(this.listParam).then((result) => {
        result.size = this.listParam.size;
        this.pageParam = result;
      });
    },
    pageCallback(page) {
      this.listParam.page = page;
      this.list();
    },
    popupCallback() {
      this.isNew = false;
      //   this.isShow = false;
      this.isShowPopup = false;
      this.isUpdate = false;
      this.list();
    },
    remove(param) {
      param.msg = "삭제 하시겠습니까?";
      this.$refs.confirm.open(param);
      this.confirmParam.title = "공지사항";
      this.confirmParam.msg = "삭제 하시겠습니까?";
      this.confirmParam.isShow = true;
      this.noticeId = param.id;
    },
    confirmCallback() {
      BoardApi.remove(this.noticeId).then(() => {
        this.list();
        this.$toast.success("삭제 되었습니다.");
      });
    },
    update(notice) {
      this.isUpdate = true;
      this.isShow = true;
      this.detailItem = notice;
    },
    regist() {
      this.isNew = true;
      this.isShowPopup = true;
      this.detailItem = {
        type: "NOTICE",
        noticeType: "NORMAL",
        title: "",
        content: " ",
        fileId: null,
      };
    },
    contentSubStr(str) {
      str = str.replace(/<[^>]*>?/g, "");
      if (str.length > 40) {
        str = str.substr(0, 38) + "...";
      }
      return str;
    },
  },
};
</script>
