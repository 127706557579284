import { httpClient } from "../../network/httpclient/HttpClientFactory";

const oilDocList = (params) =>
  httpClient.get(`/oil`, { params }).then((res) => res.data);

const getDetail = (id) => httpClient.get(`/oil/${id}`).then((res) => res.data);

const deleteOilDoc = (id) =>
  httpClient.delete(`/oil/${id}`).then((res) => res.data);
const saveOilDoc = (param) =>
  httpClient.post(`/oil`, param).then((res) => res.data);
const updateOilDoc = (id, param) =>
  httpClient.put(`/oil/${id}`, param).then((res) => res.data);

export default {
  oilDocList,
  deleteOilDoc,
  getDetail,
  saveOilDoc,
  updateOilDoc,
};
