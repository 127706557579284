<template>
  <div>SSO</div>
</template>

<script>
import { JWT_TOKEN, JWT_REFRESH_TOKEN, APPLICATION_PHASE } from "../const";

export default {
  name: "SSO",
  components: {},
  data() {
    return {
      token: null,
      reToken: "",
    };
  },
  computed: {},
  beforeCreate() {},
  created() {},
  mounted() {
    this.token = this.$route.params.token;
    console.log(APPLICATION_PHASE);

    if (APPLICATION_PHASE === "REAL") {
      this.token = this.getCookieValue("osm_token") || this.$route.params.token;
      this.reToken = this.getCookieValue("osm_reToken");
    }

    localStorage.setItem(JWT_TOKEN, this.token);
    localStorage.setItem(JWT_REFRESH_TOKEN, this.reToken);

    this.$router.push({ path: `/bim/1` });
  },
  beforeDestroy() {},
  destroy() {},
  methods: {
    getCookieValue(cookieName) {
      const cookieArray = document.cookie.split(";"); // 쿠키를 세미콜론으로 분리
      for (let i = 0; i < cookieArray.length; i++) {
        const cookiePair = cookieArray[i].split("="); // 쿠키 이름과 값을 분리
        if (cookieName === cookiePair[0].trim()) {
          return cookiePair[1]; // 쿠키 값 반환
        }
      }
      return null; // 해당 이름의 쿠키가 없는 경우
    },
  },
};
</script>
