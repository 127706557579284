<template>
  <div
    :style="{ display: isShow === true ? 'block' : 'none' }"
    class="dash_admin_box dash_commute_05"
  >
    <div class="dash_admin_box_head">
      <p class="admin_box_title">업체별 출근통계</p>
    </div>

    <!-- 검색 Wrap -->
    <div class="option_wrap">
      <div class="search_filter">
        <form>
          <div class="option_date">
            <date-picker
              id="startedDate"
              v-model="condition.beginDate"
              :lang="getLang"
              value-type="format"
              format="YYYY-MM-DD"
              type="date"
            />
            <span>~</span>
            <date-picker
              id="endedDate"
              v-model="condition.endDate"
              :lang="getLang"
              value-type="format"
              format="YYYY-MM-DD"
              type="date"
            />
          </div>

          <select
            v-show="authType === 'ADMIN'"
            v-model="condition.equipId"
            class="search_option"
          >
            <option
              v-for="(code, index) in equipList"
              :key="index"
              :value="code.id"
            >
              {{ code.name }}
            </option>
          </select>

          <div class="search_button">
            <button type="button" @click="onClickSearch">검색</button>
          </div>
        </form>
      </div>
      <!-- <div class="right_area">
        <div class="download_button">
          <button type="button" @click="excel">엑셀 다운로드</button>
        </div>
      </div> -->
    </div>
    <!-- 검색 Wrap //-->
    <div id="container" class="graph_wrap" @click="clickChart($event)">
      <v-chart
        class="chart"
        :option="option"
        autoresize
        @click="clickChart($event)"
      />
    </div>

    <!-- 전체 통계 wrap -->
    <div class="stat_wrap">
      <div class="item">
        총 운행거리<span>{{ totalDistance }} Km</span>
      </div>
      <div class="item">
        총 주유량<span>{{ totalOil }} L</span>
      </div>
      <div class="item">
        평균연비<span>{{ averageFuel }} Km/L</span>
      </div>
    </div>
    <!-- 전체 통계 wrap //-->

    <div class="dash_admin_box_table graph-page">
      <table>
        <thead>
          <tr>
            <th class="tt1">운행일자</th>
            <th class="tt1">운행거리(Km)</th>
            <th class="tt1">주유량(L)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="tableList.length === 0">
            <td colspan="10">검색 결과가 없습니다.</td>
          </tr>
          <template v-if="tableList.length !== 0">
            <tr v-for="(item, index) in tableList" :key="index">
              <td>{{ item.logDay }}</td>
              <td>{{ item.distance }}</td>
              <td>{{ item.oilAmount | numComma }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import InfraApi from "../../api/coco/InfraApi";
import EquipmentApi from "../../api/osm/EquipmentApi";

import { getToday, toDayFormatAddMonth } from "../../utils/date";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { AUTH_TYPE } from "../../const";
import { mapGetters } from "vuex";
import ShipLogBookApi from "../../api/osm/ShipLogBookApi";
use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
]);

export default {
  name: "DashCommute05",
  components: { VChart, DatePicker },
  provide: {
    [THEME_KEY]: "dark",
  },
  props: {
    tabId: {
      type: Number,
      default: 5,
    },
  },
  data: () => {
    return {
      dailyStats: { stats: [], summary: {} },
      condition: {
        equipId: 0,
        beginDate: toDayFormatAddMonth(-1),
        endDate: getToday(),
      },
      equipList: [],
      companyList: [],
      totalDistance: 0,
      totalOil: 0,
      averageFuel: 0,
      tableList: [],
      isShow: false,
      xAxisData: [],
      xAxisDataOil: [],
      yAxisData: [],
      yDistanceData: [],
      yOilData: [],
      option: {},
      authType: localStorage.getItem(AUTH_TYPE),
    };
  },
  computed: { ...mapGetters(["getLang"]) },
  watch: {
    tabId: function () {
      if (this.tabId === 5) {
        this.list();
      }
    },
  },
  created() {},
  async mounted() {
    const result = await EquipmentApi.equipList();
    result.content.forEach((element, index) => {
      if (index === 0) this.condition.equipId = 10;
      this.equipList.push(element);
    });
    this.equipList = result.content;
  },
  beforeDestroy() {},
  methods: {
    async open() {
      const result = await ShipLogBookApi.statisticData(
        this.condition.equipId,
        this.condition.beginDate,
        this.condition.endDate
      );
      console.log(result);
      console.log("open");
      this.list();
      this.isShow = true;
    },
    close() {
      this.isShow = false;
    },
    onClickSearch() {
      this.list();
    },
    async list() {
      this.xAxisData = [];
      this.yDistanceData = [];
      this.yOilData = [];
      this.totalOil = 0;
      this.totalDistance = 0;
      this.averageFuel = 0;
      this.tableList = [];
      const result = await ShipLogBookApi.statisticData(
        this.condition.equipId,
        this.condition.beginDate,
        this.condition.endDate
      );
      console.log(result);
      console.log(typeof result);
      for (const [key, value] of Object.entries(result)) {
        const { first, second } = value;
        this.totalOil += second;
        this.totalDistance += parseFloat(first) || 0;
        this.tableList.push({
          logDay: key,
          distance: first,
          oilAmount: second,
        });
        this.xAxisData.push(key);
        this.yDistanceData.push(first);
        this.yOilData.push(second);
        if (second !== 0) this.xAxisDataOil.push(key);
      }
      this.totalDistance = Math.round(this.totalDistance * 10) / 10;
      this.averageFuel =
        Math.round((this.totalDistance / this.totalOil) * 10) / 10 || 0;
      console.log(this.xAxisDataOil);

      this.setup();
      //   }
    },
    contentSubStr(str) {
      if (str) {
        str = str.replace(/<[^>]*>?/g, "");
        if (str.length > 10) {
          str = str.substr(0, 8) + "...";
        }
      }

      return str;
    },
    displayFault(isFault) {
      return isFault ? "비정상" : "정상";
    },
    addBarSeries(name, data) {
      return {
        data: data,
        type: "bar",
        barWidth: "10%",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.15)",
        },
      };
    },
    setup() {
      this.option = {
        grid: {
          top: "10%",
          bottom: "10%",
          left: "10%",
          right: "10%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        legend: {
          data: ["운행거리", "주유량"],
        },
        xAxis: {
          boundaryGap: true,
          type: "category",
          data: this.xAxisData,
          axisLabel: {
            // X축 폰트 크기
            fontSize: 11,
            color: "rgba(255, 255, 255, 0.5)",
          },
          axisLine: {
            // X축 라인 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.2)",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            name: "운행거리",
            position: "left",
            axisLine: {
              lineStyle: {
                color: "#675bba",
              },
            },
            axisLabel: {
              formatter: "{value} Km",
            },
            // splitLine: {
            //   show: false, // 가로선 숨기기
            // },
          },
          {
            type: "value",
            name: "주유량",
            position: "right",
            axisLine: {
              lineStyle: {
                color: "#ff8c00",
              },
            },
            axisLabel: {
              formatter: "{value} L",
            },
            splitLine: {
              show: false, // 가로선 숨기기
            },
          },
        ],
        series: [
          {
            name: "운행거리",
            type: "line",
            data: this.yDistanceData,
            yAxisIndex: 0, // 왼쪽 Y축 사용
          },
          {
            name: "주유량",
            type: "bar",
            barWidth: 20, // 막대 너비 설정 (px)
            data: this.yOilData,
            yAxisIndex: 1, // 오른쪽 Y축 사용
          },
        ],
      };
    },
    async clickChart(param) {
      console.log(param);
      if (param.componentType) {
        param.event.event.stopPropagation(); //상위 태그로 클릭이벤트 전달을 막아줌
        const startDate = param.name;
        let endDate = "";
        this.xAxisDataOil.forEach((day, index) => {
          if (day === startDate) {
            if (this.xAxisDataOil[index + 1]) {
              endDate = this.xAxisDataOil[index + 1];
            } else {
              endDate = this.xAxisData[this.xAxisData.length - 1];
            }
          }
        });
        const result = await ShipLogBookApi.statisticData(
          this.condition.equipId,
          startDate,
          endDate
        );
        let totalOil = 0;
        let totalDistance = 0;
        const tableList = [];
        const xAxisData = [];
        const yDistanceData = [];
        const yOilData = [];
        for (const [key, value] of Object.entries(result)) {
          const { first, second } = value;
          totalOil += second;
          totalDistance += parseFloat(first) || 0;
          tableList.push({
            logDay: key,
            distance: first,
            oilAmount: second,
          });
          xAxisData.push(key);
          yDistanceData.push(first);
          yOilData.push(second);
        }
        this.tableList = tableList;
        this.totalOil = totalOil;
        this.totalDistance = Math.round(totalDistance * 10) / 10;
        this.averageFuel =
          Math.round((this.totalDistance / this.totalOil) * 10) / 10 || 0;
        const option = {
          grid: {
            top: "10%",
            bottom: "10%",
            left: "10%",
            right: "10%",
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
          },
          legend: {
            data: ["운행거리", "주유량"],
          },
          xAxis: {
            boundaryGap: true,
            type: "category",
            data: xAxisData,
            axisLabel: {
              // X축 폰트 크기
              fontSize: 11,
              color: "rgba(255, 255, 255, 0.5)",
            },
            axisLine: {
              // X축 라인 컬러
              lineStyle: {
                color: "rgba(255, 255, 255, 0.2)",
              },
            },
          },
          yAxis: [
            {
              type: "value",
              name: "운행거리",
              position: "left",
              axisLine: {
                lineStyle: {
                  color: "#675bba",
                },
              },
              axisLabel: {
                formatter: "{value} Km",
              },
              // splitLine: {
              //   show: false, // 가로선 숨기기
              // },
            },
            {
              type: "value",
              name: "주유량",
              position: "right",
              axisLine: {
                lineStyle: {
                  color: "#ff8c00",
                },
              },
              axisLabel: {
                formatter: "{value} L",
              },
              splitLine: {
                show: false, // 가로선 숨기기
              },
            },
          ],
          series: [
            {
              name: "운행거리",
              type: "line",
              data: yDistanceData,
              yAxisIndex: 0, // 왼쪽 Y축 사용
            },
            {
              name: "주유량",
              type: "bar",
              barWidth: 20, // 막대 너비 설정 (px)
              data: yOilData,
              yAxisIndex: 1, // 오른쪽 Y축 사용
            },
          ],
        };

        this.option = option;
      } else {
        console.log("배경클릭");
        this.list();
      }
    },
  },
};
</script>
<style scoped>
.graph_wrap {
  height: 400px !important;
}
</style>
