<template>
  <div v-if="isShow" class="popup" style="display: block">
    <div class="popup_header">
      <div class="header_title">지역 변경</div>
      <button class="close_popup" @click="close()">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" />
      </button>
    </div>
    <div class="popup_content">
      <!-- <div class="text_info_popup">
        {{ param.msg }}
      </div> -->
      <div class="option_wrap">
        <div class="search_filter">
          <form>
            <div class="option_date w100">
              <input type="text" placeholder="지역 선택" readonly />
            </div>
            <select v-model="location" class="search_option">
              <option value="압해">압해</option>
              <option value="흑산">흑산</option>
              <option value="신의">신의</option>
              <option value="암태">암태</option>
              <option value="팔금">팔금</option>
            </select>
          </form>
        </div>
      </div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <button type="button" class="cancel" @click="close()">취소</button>
        <button type="button" class="comfirm" @click="ok()">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirm",
  components: {},
  props: {},
  data: () => {
    return {
      isShow: false,
      param: {},
      location: "압해",
    };
  },
  computed: {},
  watch: {},

  beforeCreate() {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    open() {
      this.isShow = true;
      console.log("open");
    },
    close() {
      this.isShow = false;
    },
    ok() {
      this.isShow = false;
      this.param.callbackParam = {};
      this.param.callbackParam.location = this.location;
      this.$emit("weatherCallBack", this.param.callbackParam);
    },
  },
};
</script>
<style scoped>
.popup {
  z-index: 1000000;
  min-width: 300px;
}
</style>
