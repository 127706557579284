<template>
  <div
    :style="{ visibility: isShow ? 'visible' : 'hidden' }"
    class="dash_admin_wrap"
  >
    <div class="dash_admin_inner">
      <div class="dash_admin_content">
        <div class="dash_admin_left">
          <div class="dash_admin_head">
            <p class="title">현장 정보 관리</p>
          </div>
          <ul>
            <li>
              <button
                type="button"
                :class="tabId === 1 ? 'on' : ''"
                @click="onClickTab(1)"
              >
                현장 정보 관리
              </button>
            </li>
          </ul>
          <!-- <ul>
            <li>
              <button
                type="button"
                :class="tabId === 2 ? 'on' : ''"
                @click="onClickTab(2)"
              >
                현장 공지 관리
              </button>
            </li>
          </ul> -->
          <!-- <ul>
            <li>
              <button
                type="button"
                :class="tabId === 3 ? 'on' : ''"
                @click="onClickTab(3)"
              >
                현장 일정 관리
              </button>
            </li>
          </ul> -->
          <ul>
            <li>
              <button
                type="button"
                :class="tabId === 5 ? 'on' : ''"
                @click="onClickTab(5)"
              >
                현장 장비 관리
              </button>
            </li>
          </ul>
          <ul>
            <li>
              <button
                type="button"
                :class="tabId === 4 ? 'on' : ''"
                @click="onClickTab(4)"
              >
                현장 캘린더
              </button>
            </li>
          </ul>

          <!-- <ul>
            <li>
              <button
                type="button"
                :class="tabId === 6 ? 'on' : ''"
                @click="onClickTab(6)"
              >
                운행 기록 관리
              </button>
            </li>
          </ul> -->
          <!-- <ul>
            <li>
              <button
                type="button"
                :class="tabId === 7 ? 'on' : ''"
                @click="onClickTab(7)"
              >
                유류 공급서 관리
              </button>
            </li>
          </ul> -->
          <ul>
            <li>
              <button
                type="button"
                :class="tabId === 8 ? 'on' : ''"
                @click="onClickTab(8)"
              >
                장비 운행 통계
              </button>
            </li>
          </ul>
        </div>
        <div class="dash_admin_cont">
          <site-setting ref="tab-1" />
          <site-board ref="tab-2" />
          <site-schedule ref="tab-3" />
          <popup-site-log-calendar ref="tab-4" />
          <popup-equipment-list ref="tab-5" />
          <popup-ship-log-list ref="tab-6" />
          <popup-oil-management-list ref="tab-7" />
          <popup-log-graph ref="tab-8" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SiteSchedule from "../setting/SiteSchedule.vue";
import SiteBoard from "../setting/SiteBoard.vue";
import SiteSetting from "../setting/SiteSetting.vue";
import PopupEquipmentList from "./PopupEquipmentList.vue";
import PopupOilManagementList from "./PopupOilManagementList.vue";
import PopupShipLogList from "./PopupShipLogList.vue";
import PopupSiteLogCalendar from "./PopupSiteLogCalendar.vue";
import PopupLogGraph from "./PopupLogGraph.vue";

export default {
  name: "PopupDashAdmin",
  components: {
    PopupEquipmentList,
    PopupShipLogList,
    PopupOilManagementList,
    SiteSetting,
    SiteBoard,
    SiteSchedule,
    PopupSiteLogCalendar,
    PopupLogGraph,
  },
  props: {
    siteId: {
      type: Number,
      default: null,
    },
    currentPopup: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      isShow: false,
      tabId: 1,
    };
  },
  computed: {},
  created() {
    setTimeout(() => {
      this.isShow = false;
    }, 1000);
  },
  mounted() {
    console.log(process.env.PROGRESS_DOMAIN);
  },
  beforeDestroy() {},
  methods: {
    toggle() {
      console.log("toggle");
      this.isShow = !this.isShow;
      if (this.isShow) this.showTab();
    },
    onClickTab(tabId) {
      this.tabId = tabId;
      this.showTab();
    },
    showTab() {
      const popupObj = this.$refs;
      for (const key in popupObj) {
        popupObj[key].close();
      }
      // console.log(this.$refs);

      this.$refs[`tab-${this.tabId}`].open();
      // this.$refs.open();
      // this.$nextTick(() => {
      //   console.log(this.$refs.equipList); // DOM이 렌더된 후에 접근
      //   this.$refs.equipList.open();
      // });
      // this.$refs.equipList.open();
    },
  },
};
</script>
<style scoped>
.dash_admin_wrap {
  z-index: 10000000;
  /* display: block !important; */
}
</style>
