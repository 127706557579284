<template>
  <div class="sea_wrap bim">
    <!-- header 추가 -->
    <div class="header">
      <div class="header_inner">
        <div class="logo_wrap">
          <h1 class="logo">
            <img src="/img/sea/bim_logo.svg" alt="daewoo" />
          </h1>
          <p class="logo_info">압해화원 도로건설공사 제2공구</p>
        </div>

        <div class="header_info">
          <p class="info_date">{{ dayText }}</p>
          <p />
          <p class="info_week">{{ weekText }}</p>
          <p class="info_time">{{ timeText }}</p>
        </div>
        <p class="menu_btn">
          <button
            type="button"
            class="btn_menu1"
            :class="menu === true ? 'on' : ''"
            @click="onClickSetting"
          />
        </p>
      </div>
    </div>

    <!-- dashboard 추가 -->
    <div class="dashboard_wrap">
      <!-- bridge dashboard -->
      <div class="dashboard">
        <div v-for="(bim, index) in bimTotals" :key="index" class="column_box">
          <div :class="['column', { 'column-selected': bim.bimId === bimId }]">
            <div class="title" @click="onMove(bim.bimId)">{{ bim.name }}</div>
            <div class="progress">
              {{ bim.finishCount }}/{{ bim.totalCount }}
            </div>
            <div class="data_chart">
              <div
                class="chart_percent"
                :style="`width: ${calcPercentage(
                  bim.totalCount,
                  bim.finishCount
                )}%`"
              />
            </div>
            <div class="count">
              진행중
              <div class="num">{{ bim.ingCount }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- information dashboard 재정리 -->
      <div class="box-infomation">
        <div class="box-item item-percent">
          <div class="box-content">
            <div class="content-box box-01">
              <vue-flip v-model="cardFlip" width="100%" height="100%">
                <template v-slot:front>
                  <div class="side side1">
                    <div class="gauge_item_chart chart_01">
                      <svg
                        class="radial-progress"
                        :data-percentage="site.progressRate"
                        viewBox="0 0 80 80"
                      >
                        <!-- data-percentage 에 % 값 변경 하면 됩니다. -->
                        <defs>
                          <linearGradient
                            id="gradient"
                            x1="0%"
                            y1="0%"
                            x2="0%"
                            y2="100%"
                          >
                            <stop offset="0%" stop-color="#56850f" />
                            <stop offset="100%" stop-color="#85c621" />
                          </linearGradient>
                        </defs>
                        <circle class="incomplete" cx="40" cy="40" r="35" />
                        <circle
                          class="complete"
                          stroke="url(#gradient)"
                          cx="40"
                          cy="40"
                          r="35"
                          :style="'stroke-dashoffset: ' + calcProgress(0)"
                        />
                      </svg>
                      <div class="center_text">
                        <p class="number">{{ site.progressRate }}<em>%</em></p>
                        <p class="description">공정률</p>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:back>
                  <div class="side side2">
                    <div class="gauge_item_chart chart_02">
                      <svg
                        class="radial-progress"
                        :data-rercentage="site.progress"
                        viewBox="0 0 80 80"
                      >
                        <!-- data-percentage 에 % 값 변경 하면 됩니다. -->
                        <defs>
                          <linearGradient
                            id="gradient2"
                            x1="0%"
                            y1="0%"
                            x2="0%"
                            y2="100%"
                          >
                            <stop offset="0%" stop-color="#dfaa0b" />
                            <stop offset="100%" stop-color="#f9e222" />
                          </linearGradient>
                        </defs>
                        <circle class="incomplete" cx="40" cy="40" r="35" />
                        <circle
                          class="complete"
                          stroke="url(#gradient2)"
                          cx="40"
                          cy="40"
                          r="35"
                          :style="'stroke-dashoffset: ' + calcProgress(-20)"
                        />
                      </svg>
                      <div class="center_text">
                        <p class="number">{{ site.progressDay }}<em>일</em></p>
                        <p class="description">공사일</p>
                      </div>
                    </div>
                  </div>
                </template>
              </vue-flip>
            </div>
          </div>
        </div>

        <div class="box-item item-weather">
          <div class="box-content">
            <p
              class="title"
              style="color: white; font-size: 15px"
              @click="changeWeather"
            >
              {{ location }}
            </p>
            <div class="content-box box-01">
              <div class="item">
                <vue-flip v-model="cardFlip" width="100%" height="100%">
                  <template v-slot:front>
                    <div class="side side1">
                      <div class="item">
                        <div class="weather_content content_01">
                          <div class="number">
                            {{
                              oceanInfo.waveHeight ? oceanInfo.waveHeight : 0
                            }}
                          </div>
                          <p class="title">평균파고(m)</p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:back>
                    <div class="side side2">
                      <div class="item">
                        <div class="weather_content content_03">
                          <div class="number">
                            {{ oceanInfo.airTemp ? oceanInfo.airTemp : 0 }}
                          </div>
                          <p class="title">대기온도</p>
                        </div>
                      </div>
                    </div>
                  </template>
                </vue-flip>
              </div>
            </div>
            <div class="content-box box-02">
              <div class="item">
                <vue-flip v-model="cardFlip" width="100%" height="100%">
                  <template v-slot:front>
                    <div class="side side1">
                      <div class="item">
                        <div class="weather_content content_05">
                          <div class="number ch">
                            {{
                              oceanInfo.currentDir ? oceanInfo.currentDir : ""
                            }}
                          </div>
                          <p class="title">파향</p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:back>
                    <div class="side side2">
                      <div class="item">
                        <div class="weather_content content_04">
                          <div class="number">
                            {{ oceanInfo.windSpeed ? oceanInfo.windSpeed : 0 }}
                          </div>
                          <p class="title">풍속</p>
                        </div>
                      </div>
                    </div>
                  </template>
                </vue-flip>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--- 추가 alert -->
      <!-- <div class="box-infomation alert">
        <div
          v-show="warningTitle.length > 0"
          class="box-item item-alert"
          style="display: block"
        >
          <div class="box-content">
            <div class="content-box box-01">
              <div class="alert-card">
                <p class="cont">{{ warningContent }}</p>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!--- 추가 alert //-->
    </div>
    <!-- dashboard_wrap //-->

    <div class="canvas_wrap">
      <div id="bim-type-data" class="canvas_info">
        <div
          class="info_btns"
          v-show="bimOn == true"
          @click="changeMap"
          style="cursor: pointer"
        >
          <p class="title">지도 보기</p>
        </div>
        <div
          class="info_btns"
          v-show="mapOn == true"
          @click="changeBim"
          style="cursor: pointer"
        >
          <p class="title">진척도 보기</p>
        </div>
        <div
          v-for="(bim, index) in bimTypeProgressResponse"
          :key="index"
          class="info_btns"
        >
          <p class="title">{{ bim.name }}</p>
          <div class="legend">
            <div class="info comp1">{{ bim.totalCount }}</div>
            <div class="info comp2">{{ bim.ingCount }}</div>
            <div class="info comp3">{{ bim.finishCount }}</div>
          </div>
        </div>
      </div>

      <div class="canvas_graph">
        <div v-show="bimOn == true" class="canvas_inner">
          <!--<VueUnity
            :unity="unityContext"
            width="100%"
            height="100%"
            ref="unityContainer"
          />
          -->
        </div>
        <div id="map" class="map_wrap" />
        <choice-map-style
          v-if="mapOn == true && deviceInfoProp"
          :device-info="deviceInfoProp"
          @changeMapStyle="changeMapStyle"
          @moveBookmarks="moveBookmarks"
          @calendarCallBack="calendarCallBack"
          @refreshCallBack="refreshCallBack"
          @changeDate="changeDate"
        />
      </div>
    </div>
    <popup-dash-admin ref="popupDashAdmin" />
    <confim-weather ref="confirmWeather" @weatherCallBack="weatherCallBack" />
  </div>
</template>
<script>
import UnityWebgl from "unity-webgl";
import VueUnity from "unity-webgl/vue";
import SiteCommonApi from "../api/osm/SiteCommonApi";

import CustomObjectApi from "../api/osm/CustomObjectApi";

import ChoiceMapStyle from "../components/mapObject/ChoiceMapStyle.vue";
import ContentsBtns from "../components/mapObject/ContentsBtns.vue";
import PopupDashAdmin from "../components/popup/PopupDashAdmin.vue";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Threebox } from "threebox-plugin";
import ConfimWeather from "../components/popup/ConfirmWeather.vue";

import {
  MAPBOX_ACCESS_TOKEN,
  MAPBOX_MAP_STYLE,
  MAPBOX_MAP_STYLE_SATELLITE,
  DEV_LEVEL,
  SITE_ID,
} from "../const";

var Unity = new UnityWebgl({
  loaderUrl: "/bim/osmBimProgress.loader.js",
  dataUrl: "/bim/osmBimProgress.data",
  frameworkUrl: "/bim/osmBimProgress.framework.js",
  codeUrl: "/bim/osmBimProgress.wasm",
});

import WeatherApi from "../api/coco/WeatherApi";

import VueFlip from "vue-flip";

import { mapActions } from "vuex";
import EquipmentApi from "../api/osm/EquipmentApi";

import {
  cstrLine,
  officePolygon,
  bridgePanel,
  tunnelPanel,
  setGisInfo,
  generateDriverTypeIconElement,
  generate3dIconElement,
  generatShipIconElement,
} from "../helper/MapObjectHelper";

export default {
  name: "BimProgressDashBoard",
  components: {
    "vue-flip": VueFlip,
    VueUnity,
    ChoiceMapStyle,
    ContentsBtns,
    PopupDashAdmin,
    ConfimWeather,
  },
  data: () => {
    return {
      location: "압해",
      menu: false,
      site: { progress: 0, progressRate: 0 },
      oceanInfo: {},
      cardFlip: false,
      warningTitle: "",
      warningContent: "",
      interval: 3000,
      unityContext: Unity,
      bimId: 1,
      bimTotals: [],
      dayText: "0000.00.00",
      weekText: "?요일",
      timeText: "00:00:00",
      bimTypeProgressResponse: [],
      accessToken: MAPBOX_ACCESS_TOKEN,
      mapStyle: MAPBOX_MAP_STYLE,
      mapStyleSatellite: MAPBOX_MAP_STYLE_SATELLITE,
      map: null,
      mapInitInfo: {},
      site: { name: "" },
      intervals: [],
      markerMap: {},
      siteId: localStorage.getItem(SITE_ID),
      devLevel: localStorage.getItem(DEV_LEVEL)
        ? localStorage.getItem(DEV_LEVEL)
        : 0,
      mapOn: true,
      bimOn: false,
      model3dArray: [
        {
          //달리율도교
          objSrc: "/model/2/daliuldo_mini_final.gltf",
          scale: 40,
          rotation: { x: 90, y: 180, z: 0 },

          lng: 126.31593,
          lat: 34.78873,
          altitude: -22,
          id: 1,
        },
        {
          //율도압해
          objSrc: "/model/2/uldoapheaModel_mini_final.gltf",
          scale: 1,
          rotation: { x: 90, y: -2, z: 0 },
          lng: 126.3307,
          lat: 34.8169,
          altitude: -36,
          id: 2,
        },
      ],
      data: null,
      error: null,
      eventSource: null,
      deviceHistory: {},
      devicePin: {},
      deviceList: [], //TODO 관리
      deviceInfo: {},
      deviceReviewId: null,
      deviceInfoProp: null,
    };
  },
  computed: {},
  beforeCreate() {
    // 오버라이드하기 전에 원래의 console.log가 이미 저장되어 있는지 확인
    console.log("beforeCreate");
    //파라미터 받아지나 created 될 때 data 값이 reset 되는 것 같음
    this.bimId = parseInt(this.$route.params.id) || 1;
    const buildUrl = "/bim/" + this.bimId;
    Unity = new UnityWebgl({
      loaderUrl: buildUrl + "/osmBimProgress.loader.js",
      dataUrl: buildUrl + "/osmBimProgress.data",
      frameworkUrl: buildUrl + "/osmBimProgress.framework.js",
      codeUrl: buildUrl + "/osmBimProgress.wasm",
    });
  },
  created() {
    this.getTime();
    this.bimId = parseInt(this.$route.params.id) || 1;
    console.log(this.bimId);
  },
  mounted() {
    // this.setupEventSource();
    for (var i = 0; i < 100; i++) {
      clearInterval(i);
    }

    setInterval(this.getTime, 1000);

    setInterval(this.getOceanInfo, 1000 * 60 * 60);
    setInterval(this.getSpecialReport, 1000 * 60 * 20);

    setTimeout(() => {
      location.reload();
    }, 600000 * 6 * 24); // 600000밀리초 = 10분

    SiteCommonApi.detail().then((res) => {
      this.site = res;
      this.mapInitInfo = JSON.parse(this.site.mapInitInfo);
      this.mapinit(this.mapStyleSatellite);
    });
    this.onBimProgress();
    this.onBimTypeProgress();
    this.getInit();

    this.setupEventSource();
  },
  beforeDestroy() {
    if (this.eventSource) {
      this.eventSource.close(); // Clean up the EventSource connection when the component is destroyed
    }
  },
  methods: {
    ...mapActions(["setMapBounds"]),
    async setupEventSource(param) {
      this.deviceList = [];
      this.deviceInfo = {};
      this.deviceHistory = {};
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");

      const formattedToday = `${year}-${month}-${day}`;

      let formattedDate = `${year}-${month}-${day}`;
      const result = await EquipmentApi.equipList();

      this.deviceList = result.content;
      if (param) {
        // this.deviceList.push(param);
        formattedDate = param;
      } else {
        // const result = await EquipmentApi.equipList();
        // this.deviceList = result.content;
        // const today = new Date();
        // const year = today.getFullYear();
        // const month = String(today.getMonth() + 1).padStart(2, "0");
        // const day = String(today.getDate()).padStart(2, "0");
        // formattedDate = `${year}-${month}-${day}`;
      }

      const promises = this.deviceList.map(async (device) => {
        const id = device.id;
        const historyList = await CustomObjectApi.historyDate(
          id,
          formattedDate,
          formattedDate
        );

        this.deviceHistory[id] = historyList;

        this.deviceInfo[id] = {
          distance: this.calculateTotalDistance(historyList),
          totalTime: this.calculateTotalTime(historyList),
        };
      });
      await Promise.all(promises);

      this.deviceInfoProp = this.deviceInfo;
      // this.$set(this, "deviceInfoProp", { ...this.deviceInfo });
      if (param === undefined || param === formattedToday) {
        const idList = this.deviceList.map((item) => item.id);
        console.log("idList", idList);
        const queryString = idList
          .map((param) => `idList=${encodeURIComponent(param)}`)
          .join("&");
        // const baseUrl = "http://localhost:20000/api/v1/map/object/sse";
        const baseUrl = "https://progress.dscosm.com/osm/api/v1/map/object/sse";
        const urlWithParams = `${baseUrl}?${queryString}`;
        this.eventSource = new EventSource(urlWithParams);
        this.eventSource.onmessage = (event) => {
          console.log("New message from server: ", event.data);
          this.data = JSON.parse(event.data); // Update the UI with the new data
          if (this.data.length > 0) {
            this.data.forEach((item) => {
              this.updateDeviceCount(item.memberId, item.count);
              if (item.lng && item.lat) {
                this.updateDevicePin(item.memberId, [item.lng, item.lat]);
              }
            });
          }
        };
        this.eventSource.onerror = (error) => {
          console.error("Error occurred:", error);
          this.error = "Connection error. Please try again.";
          this.eventSource.close();
        };
      }
    },
    async onBimProgress() {
      this.bimProgressResponse = await SiteCommonApi.getBimProgress(this.bimId);
      console.log(this.bimProgressResponse);
      this.unityContext.on("mounted", () => {
        setTimeout(() => {
          this.bimProgressResponse.partList.forEach((item, index) => {
            setTimeout(() => {
              console.log("sending ");
              Unity.send("GameObject", "Sendnum", item.partName);
              Unity.send("GameObject", "sendType", "" + item.status);
              // 여기에 각 요소에 대해 수행하고 싶은 작업을 추가할 수 있습니다.
            }, index * 100);
          });
        }, this.interval);
      });
    },
    async onBimTypeProgress() {
      this.bimTypeProgressResponse = await SiteCommonApi.getBimType(this.bimId);
    },
    onMove(bimId) {
      this.$router.push({ path: `/dashboard/${bimId}` });
    },
    async getInit() {
      this.bimTotals = await SiteCommonApi.getBimProgressCounts();
      this.site = await SiteCommonApi.detail();
      this.getOceanInfo();
      this.getSpecialReport();
    },
    async getSpecialReport() {
      this.reportResult = await WeatherApi.specialReport();

      this.warningTitle = "";
      this.warningContent = "";

      this.warningTitle = this.reportResult
        .map((report) => report.warnVar + report.warnStress)
        .join();

      //this.warningTitle = this.warningTitle || "주의사항";
      this.warningContent = this.reportResult
        .map(
          (report) =>
            `${report.areaName}  ${report.time.substr(
              0,
              2
            )}:${report.time.substr(2, 2)}경 ${report.warnVar}  ${
              report.warnStress
            }  ${report.command}`
        )
        .join();
      //this.warningContent = this.warningContent || "현재 없음";
    },
    async getOceanInfo() {
      this.oceanInfo = await WeatherApi.oceanInfo();
    },
    calcProgress(add) {
      return 220 - this.site.progressRate * 2.2 + add;
    },
    calcPercentage(totalCount, finishCount) {
      if (totalCount === 0 || !totalCount) return 0;
      return Math.round(finishCount / totalCount);
    },
    getTime() {
      const date = new Date();
      const week = new Array(
        "일요일",
        "월요일",
        "화요일",
        "수요일",
        "목요일",
        "금요일",
        "토요일"
      );

      this.weekText = week[date.getDay()];
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      this.dayText = `${year}.${month < 10 ? `0${month}` : month}.${
        day < 10 ? `0${day}` : day
      }`;

      this.timeText = `${hours < 10 ? `0${hours}` : hours}:
            ${minutes < 10 ? `0${minutes}` : minutes}:
            ${seconds < 10 ? `0${seconds}` : seconds}`;
    },
    changeMap() {
      (this.mapOn = true), (this.bimOn = false);
    },
    changeBim() {
      (this.mapOn = false), (this.bimOn = true);
    },
    changeMapStyle(type) {
      // window.tb.dispose();
      this.map.remove();
      this.markerMap = {};

      if (type === "default") {
        this.mapinit();
      } else {
        this.mapinit(this.mapStyleSatellite);
      }

      // this.contentsBtnsValue.worker = "on";
      // this.contentsBtnsValue.coco = "on";
      // this.contentsBtnsValue.equip = "on";
      // this.contentsBtnsValue.cctv = "on";
    },
    dashboardInterval() {
      if (this.devLevel < 2) {
        this.intervals.push(
          setInterval(() => {
            this.getDashboard();
          }, 1000 * 30)
        );
      }
    },
    async moveBookmarks(item) {
      if (!item) {
        this.map.setCenter(this.mapInitInfo.center);
        this.map.setZoom(this.mapInitInfo.zoom);
        return false;
      }

      if (item.zoomLevel) this.map.setZoom(item.zoomLevel);
      this.map.setCenter([item.lng, item.lat]);
      this.map.setPitch(item.pitch);
      this.map.setBearing(item.bearing);
      this.$toast.success(`${item.name}로 이동 했습니다`);
    },
    async makePanel() {
      const bridgeList = [];
      const tunnelList = [];

      const panelList = await CustomObjectApi.mapPenel();

      panelList.map((item) => {
        item.lnglat = [item.lng, item.lat];
        if (item.panelType === "BRIDGE") {
          bridgeList.push(item);
          return false;
        }
        if (item.panelType === "TUNNEL") {
          tunnelList.push(item);
          return false;
        }
      });

      bridgeList.map((res) => {
        bridgePanel(res, this.map, mapboxgl);
      });

      tunnelList.map((res) => {
        tunnelPanel(res, this.map, mapboxgl);
      });

      const lineList = await CustomObjectApi.mapLine();
      officePolygon(this.site, this.map, mapboxgl);
      cstrLine(lineList, this.site, this.map, mapboxgl);
    },
    model3d() {
      this.map.addLayer({
        id: "custom_layer",
        type: "custom",
        renderingMode: "3d",
        onAdd: async () => {
          this.model3dArray.map((item) => {
            window.tb.loadObj(
              {
                type: "gltf",
                obj: item.objSrc,
                units: "meters",
                scale: item.scale,
                // rotation: JSON.parse(item.rotation),
                rotation: item.rotation,
                anchor: "center",
              },
              (model) => {
                model.setCoords([item.lng, item.lat]);
                model.userData = { id: item.id }; // 모델에 고유 ID 추가
                window.tb.add(model);
              }
            );
          });
        },
        render: () => window.tb.update(),
      });
      this.map.on("click", (e) => {
        // 클릭된 위치에서 3D 객체 검색
        const features = window.tb.queryRenderedFeatures(e.point, {
          layers: ["custom_layer"],
        });

        if (features.length > 0) {
          const clickedFeature = features[0];
          console.log(clickedFeature);

          // 클릭된 객체의 userData를 가져옵니다
          if (clickedFeature && clickedFeature.object) {
            let modelId = null;

            // `userData`가 직접 설정되지 않은 경우 상위 객체를 확인
            if (clickedFeature.object.userData.id) {
              modelId = clickedFeature.object.userData.id;
            } else {
              // 상위 객체 탐색
              let parent = clickedFeature.object.parent;
              while (parent) {
                if (parent.userData && parent.userData.id) {
                  modelId = parent.userData.id;
                  break;
                }
                parent = parent.parent;
              }
            }

            if (modelId) {
              console.log("클릭된 모델 ID:", modelId);
              let url = "";
              if (modelId === 1) {
                url = this.$router.resolve({ path: `/A` }).href;
              } else if (modelId === 2) {
                url = this.$router.resolve({ path: `/B` }).href;
              }
              window.open(url, "_blank");
              // 필요한 작업 수행
            } else {
              console.log("클릭된 객체에 userData가 없습니다.");
            }
          } else {
            console.log("클릭된 객체에 userData가 없습니다.");
          }
        } else {
          console.log("클릭된 위치에 3D 객체가 없습니다.");
        }
      });
    },
    async getDashboard() {
      const points = await CustomObjectApi.dashboard();
      if (points) {
        points.forEach((value) => {
          const el = generateDriverTypeIconElement(
            value,
            this.workerList,
            this.carList,
            this.companyList
          );

          if (el) {
            const key = `${value.driverType !== null ? "EQUIP" : "WORKER"}-${
              value.id.memberId
            }`;
            if (this.markerMap[key]) {
              this.markerMap[key].removeLabel();
            }

            if (
              key.includes("WORKER") &&
              this.contentsBtnsValue.worker !== "on"
            ) {
              return;
            }

            if (
              key.includes("EQUIP") &&
              this.contentsBtnsValue.equip !== "on"
            ) {
              return;
            }

            this.markerMap[key] = this.addLabel([value.lng, value.lat], el);
          }
        });
      }
      // if (this.siteId === "2") {
      //   new mapboxgl.Marker(generate3dIconElement("a"))
      //     .setLngLat([126.31392640151309, 34.79012200212962])
      //     .setPitchAlignment("map")
      //     .addTo(this.map);

      //   new mapboxgl.Marker(generate3dIconElement("b"))
      //     .setLngLat([126.32863645685262, 34.8188612343355])
      //     .setPitchAlignment("map")
      //     .addTo(this.map);

      //   new mapboxgl.Marker(generate3dIconElement("c"))
      //     .setLngLat([126.35667529906976, 34.814411994044875])
      //     .setPitchAlignment("map")
      //     .addTo(this.map);
      // }
    },
    getDashboardWork() {
      if (this.workList && window.tb) {
        this.workList.forEach((value) => {
          const key = `COCO-${value.id}`;
          const el = document.createElement("p");
          const imgEl = document.createElement("img");
          el.className = "marker marker type_coco";
          imgEl.className = "coco";
          imgEl.dataset.id = value.id;
          // el.dataset.id = value.id;

          imgEl.style = "top:12rem;right:19.4rem";
          imgEl.src = "../img/common/map/pin_coco.svg";
          el.append(imgEl);
          el.addEventListener("click", () => {
            this.openWorkView(
              this.workList.find((work) => work.id === value.id)
            );
          });

          if (this.markerMap[key]) {
            this.markerMap[key].removeLabel();
          }
          if (this.contentsBtnsValue.coco === "on")
            this.markerMap[key] = this.addLabel(
              [value.longitude, value.latitude],
              el
            );
        });
      }
    },
    addClickEvent() {
      this.map.on("click", (e) => {
        // Query features at the click point

        if (!this.map.getLayer("rectangles-layer")) return;

        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ["rectangles-layer"],
        });

        if (features.length > 0) {
          const clickedFeature = features[0];
          const rectangleId = clickedFeature.properties.id;
          const rectangleTitle = clickedFeature.properties.title;

          console.log(`Clicked Rectangle ID: ${rectangleId}`);
          console.log(`Clicked Rectangle Title: ${rectangleTitle}`);
          if (rectangleId === 1) {
            const url = this.$router.resolve({ path: `/A` }).href;

            window.open(url, "_blank");
          } else if (rectangleId === 2) {
            const url = this.$router.resolve({ path: `/B` }).href;

            window.open(url, "_blank");
          }
        }
      });
    },
    addRectangles() {
      // Define coordinates for two rectangles
      const coordinates = [
        // Rectangle 1
        [
          [126.31771095028051, 34.78453091268581],
          [126.31587457844086, 34.78421386974844],
          [126.31467429078828, 34.793161532868325],
          [126.31580715838015, 34.79313163545861],
          [126.31771095028051, 34.78453091268581],
        ],
        // Rectangle 2
        [
          [126.32773855830084, 34.81454805062508],
          [126.32718053755423, 34.815035161527476],
          [126.33325338966932, 34.819510764725635],
          [126.33410818780987, 34.81867595930483],
          [126.32773855830084, 34.81454805062508],
        ],
      ];

      // Add a source for the rectangles
      this.map.addSource("rectangles", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: coordinates.map((coords, index) => ({
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [coords],
            },
            properties: {
              id: index + 1, // Unique ID for each rectangle
              title: `Rectangle ${index + 1}`,
            },
          })),
        },
      });

      // Add a layer for the rectangles
      this.map.addLayer({
        id: "rectangles-layer",
        type: "fill",
        source: "rectangles",
        layout: {},
        paint: {
          "fill-color": "#888888", // Fill color
          "fill-opacity": 0, // Fill opacity
        },
      });

      // Optionally, add borders around the rectangles
      this.map.addLayer({
        id: "rectangles-border",
        type: "line",
        source: "rectangles",
        layout: {},
        paint: {
          "line-color": "#000000", // Border color
          "line-width": 1, // Border width
          "fill-opacity": 0, // Fill opacity
        },
      });

      // this.map.setLayoutProperty("rectangles-border", "visibility", "none");
      // this.map.setLayoutProperty("rectangles-layer", "visibility", "none");
    },
    createDevicePin() {
      console.log("this.deviceList", this.deviceList);
      this.deviceList.forEach((device) => {
        const id = device.id;
        console.log("device.id", device.id);
        console.log("this.devicePin[id]", this.devicePin[id]);
        if (this.devicePin[id]) {
          console.log("기존핀 제거");
          this.devicePin[id].remove(); // 기존 핀을 지도에서 제거
          if (this.map.getSource(`linesDev-${id}`)) {
            // 이미 소스가 있으면 삭제

            // this.map.removeLayer("rectangles-layer");
            this.map.removeLayer(`linesDev-${id}`);

            this.map.removeSource(`linesDev-${id}`);
          }
        }
        if (this.deviceHistory[id] && this.deviceHistory[id].length < 2) return;
        // if (this.devicePin[id]) {
        //   console.log("기존핀 제거");
        //   this.devicePin[id].remove(); // 기존 핀을 지도에서 제거
        // }
        this.devicePin[id] = new mapboxgl.Marker(generatShipIconElement(id))
          .setLngLat(this.deviceHistory[id][this.deviceHistory[id].length - 1])
          .setPitchAlignment("map")
          .addTo(this.map);

        console.log("this.devicePin[id]", this.devicePin[id]);
        const pinElement = this.devicePin[id]._element;
        const nameEl = pinElement.querySelector("span");
        if (nameEl) {
          nameEl.innerText = device.name;
        }

        const lineFeatures = [
          {
            type: "Feature",
            properties: {
              color: "#0b00bf",
            },
            geometry: {
              type: "LineString",
              coordinates: this.deviceHistory[id],
            },
          },
        ];

        if (this.map.getSource(`linesDev-${id}`)) {
          // 이미 소스가 있으면 삭제

          // this.map.removeLayer("rectangles-layer");
          this.map.removeLayer(`linesDev-${id}`);

          this.map.removeSource(`linesDev-${id}`);
        }
        this.map.addSource(`linesDev-${id}`, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: lineFeatures,
          },
        });
        this.map.addLayer({
          id: `linesDev-${id}`,
          type: "line",
          source: `linesDev-${id}`,
          paint: {
            "line-width": 2,
            "line-color": ["get", "color"],
          },
        });
      });
    },
    updateDevicePin(deviceId, lngLat) {
      console.log("1##################");
      console.log(this.deviceHistory);
      console.log(deviceId);
      console.log("1##################");
      if (!this.deviceHistory[deviceId]) return;
      const lastPoint =
        this.deviceHistory[deviceId][this.deviceHistory[deviceId].length - 1];

      if (lastPoint[0] === lngLat[0] && lastPoint[1] === lngLat[1]) return;
      console.log("##################");
      console.log(lastPoint);
      console.log(lngLat);

      console.log("##################");
      this.deviceHistory[deviceId].push(lngLat);

      const lastPoint2 = this.deviceHistory[deviceId].slice(-2);

      this.updateDeviceDistance(deviceId, lastPoint2);
      this.drawLine(deviceId, lastPoint2);

      if (this.deviceReviewId === null || this.deviceReviewId === deviceId) {
        //다시보기중엔 update X
        this.devicePin[deviceId].setLngLat(lngLat);
      }
    },
    updateDeviceDistance(deviceId, lastPoint2Point) {
      const distance = this.calculateTotalDistance(lastPoint2Point);
      var orgDistance = 0;

      if (this.deviceInfo[deviceId]) {
        orgDistance = this.deviceInfo[deviceId]["distance"];
      }
      this.deviceInfo[deviceId] = {
        distance: orgDistance + distance,
      };
    },
    updateDeviceCount(deviceId, count) {
      this.deviceInfo[deviceId] = {
        count: count,
      };
    },
    drawLine(deviceId, lineList) {
      const routeSource = this.map.getSource(`linesDev-${deviceId}`);
      const currentData = routeSource._data;

      currentData.features.push({
        type: "Feature",
        properties: {
          color: "#0b00bf",
        },
        geometry: {
          type: "LineString",
          coordinates: lineList,
        },
      });

      routeSource.setData(currentData);
    },
    mapinit(style) {
      if (this.map) {
        console.log("Removing existing map...");
        this.map.remove(); // 기존 map 객체를 제거
        this.map = null; // this.map을 null로 초기화
      }
      console.log("mapinit");
      if (!style) {
        style = this.mapStyle;
      }
      mapboxgl.accessToken = this.accessToken;
      console.log(this.mapInitInfo);
      this.map = new mapboxgl.Map({
        container: "map", // container ID
        style: style, // style URL
        center: this.mapInitInfo.center,
        // center: [126.74233538655949, 34.71839126770078],
        // zoom: 13.5,
        zoom: this.mapInitInfo.zoom,
        pitch: this.mapInitInfo.pitch,
        bearing: this.mapInitInfo.bearing,
      });

      console.log(this.map);

      this.map.on("load", () => {
        this.addClickEvent();
        this.addRectangles();
        this.createDevicePin();
        this.dashboardInterval();

        this.setMapBounds(this.map.getBounds());

        this.makePanel();

        if (this.devLevel < 1)
          CustomObjectApi.mapGisinfo().then((res) => setGisInfo(this.map, res));
      });
      this.map.on("style.load", () => {
        window.tb = new Threebox(
          this.map,
          this.map.getCanvas().getContext("webgl"),
          {
            defaultLights: true,
          }
        );
        this.model3d();
        this.getDashboardWork();
        this.getDashboard();
      });

      this.map.on("moveend", () => {
        this.setMapBounds(this.map.getBounds());
      });

      this.map.on("click", (e) => {
        console.log(`A click event has occurred at ${e.lngLat}`);

        if (!(e.originalEvent.target instanceof HTMLElement)) return;

        if (e.originalEvent.target.className.includes("coco")) {
          this.openWorkView(
            this.workList.find(
              (work) => work.id === Number(e.originalEvent.target.dataset.id)
            )
          );
        } else if (e.originalEvent.target.className.includes("pin-img")) {
          const deviceId = e.originalEvent.target.getAttribute("data-id");
          if (deviceId)
            this.map.setLayoutProperty(
              `linesDev-${deviceId}`,
              "visibility",
              "none"
            );

          this.deviceReviewId = deviceId;

          this.map.addSource("line-source-tmp", {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: [],
              },
            },
          });

          this.map.addLayer({
            id: "line-layer-tmp",
            type: "line",
            source: "line-source-tmp",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#ff0000",
              "line-width": 3,
            },
          });
          this.drawLineWithDelay(deviceId, 0);
        }
      });

      if (this.devLevel > 2) {
        const draw = new MapboxDraw({
          displayControlsDefault: false,
          controls: {
            polygon: true,
            trash: true,
          },
          defaultMode: "draw_polygon",
        });
        this.map.addControl(draw);

        this.map.on("draw.create", this.updateArea);
        this.map.on("draw.delete", this.updateArea);
        this.map.on("draw.update", this.updateArea);
      }
    },
    drawLineWithDelay(deviceId, index) {
      if (index < this.deviceHistory[deviceId].length) {
        const currentCoords = this.deviceHistory[deviceId].slice(0, index + 1);

        this.devicePin[deviceId].setLngLat(
          currentCoords[currentCoords.length - 1]
        );
        // 지도에 새로운 라인 데이터 설정
        this.map.getSource("line-source-tmp").setData({
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: currentCoords,
          },
        });

        // 다음 좌표를 500ms 뒤에 그리기
        setTimeout(() => {
          this.drawLineWithDelay(deviceId, index + 1); // 다음 인덱스로 재귀 호출
        }, 200);
      } else {
        // 모든 좌표를 그린 후 완료 이벤트 처리
        this.onLineDrawingComplete(deviceId);
      }
    },
    onLineDrawingComplete(deviceId) {
      console.log("Line drawing complete!");
      if (this.map.getLayer("line-layer-tmp")) {
        this.map.removeLayer("line-layer-tmp"); // 레이어 삭제
      }

      if (this.map.getSource("line-source-tmp")) {
        this.map.removeSource("line-source-tmp"); // 소스 삭제
      }

      console.log("Line and source removed");
      this.deviceReviewId = null;
      this.map.setLayoutProperty(
        `linesDev-${deviceId}`,
        "visibility",
        "visible"
      );
    },
    updateArea(e) {
      console.log(e);
    },
    async changeDate(param) {
      console.log(param);
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;
      console.log(formattedDate);

      if (param !== formattedDate) {
        if (this.eventSource) {
          this.eventSource.close();
        }
      }
      await this.setupEventSource(param);
      this.createDevicePin();
    },
    async calendarCallBack(param) {
      // this.mapinit(this.mapStyleSatellite);

      if (this.eventSource) {
        this.eventSource.close();
      }
      await this.setupEventSource(param);
      this.createDevicePin();

      console.log("calendarCallBack", param);
    },
    async refreshCallBack() {
      this.mapinit(this.mapStyleSatellite);
      await this.setupEventSource();
    },
    haversineDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // 지구 반지름 (단위: km)
      const toRad = (degree) => (degree * Math.PI) / 180;

      const dLat = toRad(lat2 - lat1);
      const dLon = toRad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) *
          Math.cos(toRad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c; // 단위: km
    },
    calculateTotalDistance(coordinates) {
      let totalDistance = 0;

      for (let i = 0; i < coordinates.length - 1; i++) {
        const [lat1, lon1] = coordinates[i];
        const [lat2, lon2] = coordinates[i + 1];
        console.log(lat1);
        console.log(lon1);

        totalDistance += this.haversineDistance(lat1, lon1, lat2, lon2);
      }

      return totalDistance;
    },
    calculateTotalTime(time) {
      const totalTimeSecond = time.length;
      const hours = Math.floor(totalTimeSecond / 3600);
      const minutes = Math.floor((totalTimeSecond % 3600) / 60);
      const seconds = totalTimeSecond % 60;
      return hours + ":" + minutes + ":" + seconds;
    },
    onClickSetting() {
      this.menu = !this.menu;
      console.log("click");
      this.$refs.popupDashAdmin.toggle();
    },
    changeWeather() {
      this.$refs.confirmWeather.open();
    },
    weatherCallBack(param) {
      this.location = param.location;
    },
  },
  pad(number) {
    return number < 10 ? "0" + number : number;
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  display: none;
}
.header .header_inner p.menu_btn {
  position: relative;
  width: 80px;
  height: 60px;
  border-left: 1px solid #30363d;
  padding: 12px;
}
</style>
