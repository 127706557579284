<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    class="popup modify_site"
  >
    <div class="popup_header">
      <div class="header_title">운항 기록</div>
      <button @click="close" class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" />
      </button>
    </div>
    <div class="popup_content">
      <div class="form_wrap">
        <div class="form_item">
          <div class="form_write">
            <p class="title">운항일자</p>
            <p class="input">
              <input type="text" :value="detailItem.logDay" readonly />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">운행거리</p>
            <p class="input">
              <input type="text" :value="totalDistance" readonly />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">운행시간</p>
            <p class="input">
              <input type="text" :value="totalTimeSecond" readonly />
            </p>
          </div>
        </div>
      </div>
      <div class="dash_admin_box_table" style="height: 100%">
        <table>
          <thead>
            <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
              <th
                v-for="(hour, index) in row"
                :key="index"
                :width="hour.width"
                :class="getClassForHour(hour)"
              >
                {{ hour.label }}
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <!-- <button type="button" class="cancel" @click="close">취소</button>
        <button type="button" class="comfirm" @click="save">확인</button> -->
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import { mapGetters } from "vuex";
import CustomObjectApi from "../../api/osm/CustomObjectApi";
import EquipmentApi from "../../api/osm/EquipmentApi";

export default {
  name: "PopupCreateEquipment",
  components: {},
  props: {
    isShow: {
      type: Boolean,
    },
    detailItem: {
      type: Object,
      default: null,
    },
  },
  data: () => {
    return {
      param: {},
      day: [],
      logList: [1],
      tableKey: 0,
      historyList: [],
      totalDistance: null,
      totalTimeSecond: null,
      timeList: [],
      rows: [
        [
          { label: "00", width: 40 },
          { label: "01", width: 50 },
          { label: "02", width: 40 },
          { label: "03", width: 40 },
          { label: "04", width: 40 },
          { label: "05", width: 40 },
        ],
        [
          { label: "06", width: 40 },
          { label: "07", width: 50 },
          { label: "08", width: 40 },
          { label: "09", width: 40 },
          { label: "10", width: 40 },
          { label: "11", width: 40 },
        ],
        [
          { label: "12", width: 40 },
          { label: "13", width: 50 },
          { label: "14", width: 40 },
          { label: "15", width: 40 },
          { label: "16", width: 40 },
          { label: "17", width: 40 },
        ],
        [
          { label: "18", width: 40 },
          { label: "19", width: 50 },
          { label: "20", width: 40 },
          { label: "21", width: 40 },
          { label: "22", width: 40 },
          { label: "23", width: 40 },
        ],
      ],
    };
  },
  computed: { ...mapGetters(["getLang"]) },
  watch: {
    detailItem: function () {
      CustomObjectApi.historyDate(
        this.detailItem.id,
        this.detailItem.logDay,
        this.detailItem.logDay
      ).then((result) => {
        this.totalDistance =
          Math.round(this.calculateTotalDistance(result) * 10) / 10 + "Km";
        this.totalTimeSecond = this.calculateTotalTime(result);
        this.historyList = result;
        this.timeList = [];
        this.historyList.forEach((element) => {
          const day = new Date(element[2]);
          const hours = String(day.getHours()).padStart(2, "0");
          this.timeList.push(hours);
        });
        console.log(this.timeList);
      });
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    // open() {
    //   this.isShow = true;
    //   console.log("createOpen");
    // },
    close() {
      this.day = [];
      this.param = {};
      this.logList = [1];
      this.$emit("detailCallback");
    },
    calculateTotalDistance(coordinates) {
      let totalDistance = 0;

      for (let i = 0; i < coordinates.length - 1; i++) {
        const [lat1, lon1] = coordinates[i];
        const [lat2, lon2] = coordinates[i + 1];

        totalDistance += this.haversineDistance(lat1, lon1, lat2, lon2);
      }

      return totalDistance;
    },
    calculateTotalTime(time) {
      const totalTimeSecond = time.length;
      const hours = Math.floor(totalTimeSecond / 3600);
      const minutes = Math.floor((totalTimeSecond % 3600) / 60);
      const seconds = totalTimeSecond % 60;
      return hours + ":" + minutes + ":" + seconds;
    },
    haversineDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // 지구 반지름 (단위: km)
      const toRad = (degree) => (degree * Math.PI) / 180;

      const dLat = toRad(lat2 - lat1);
      const dLon = toRad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) *
          Math.cos(toRad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c; // 단위: km
    },
    getClassForHour(hour) {
      return this.timeList.includes(hour.label) ? "highlighted" : "";
    },
    async save() {
      //   this.param.startDate = "";
      //   this.param.endDate = "";
      //   this.param.content = "";
      //   this.param.title = "";
      this.param = {};
      this.logList = [1];
      this.isShow = false;
    },
  },
};
</script>
<style scoped>
.popup .form_wrap .form_write .title {
  width: 220px;
}
.v-toast {
  z-index: 10000000 !important;
}
.input input[type="text"] {
  width: 100%;
  height: 35px;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  letter-spacing: -0.2px;
  border: 1px solid #282c32;
  background-color: #000000;
  font-size: 1.6rem;
  text-indent: 10px;
}
.dash_admin_box_table {
  margin-top: 10px;
  height: 485px;
  border-left: 1px solid #282c32;
  border-right: 1px solid #282c32;
  background-color: #121219;
}
.dash_admin_box_table table {
  width: 100%;
}
.dash_admin_box_table th {
  height: 35px;
  padding: 0 12px;
  border: 1px solid #282c32;
  background-color: #1e1e23;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 1.4rem;
}
.highlighted {
  background-color: cornflowerblue !important; /* 원하는 색으로 변경 */
  color: black !important;
}
</style>
